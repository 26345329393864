import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "../../Components/Shared/Buttons";
import Input from "../../Components/Shared/Input";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"

import Barcode from "react-barcode";

import Api from "../../helper/api";
import { formatTime, formatedDate, formatedDateInDigit } from "../../helper/helper";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const BarcodeCollection = () => {
  const api = new Api();
  const [rows, setRows] = useState();
  const [sample, setSample] = useState({})
  const [open, setOpen] = React.useState(false);
  const [noLabels, setNoLabels] = useState(1);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const classes = formStyles();
  const params = useParams();

  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: params.id })
    setRows(data);

    const sampleNo = params.sampleNo;

    const sample = data.sampleCollection.find((sample) => sample.sampleNo === sampleNo)
    setSample(sample)
  };

  useEffect(() => {
    fetchData();
  }, []);


  if (!('usb' in navigator)) {
    console.log('WebUSB API is not supported!');
  }

  // get permission and print
  async function requestDevice() {
    try {
      const device = await navigator.usb.requestDevice({ filters: [] });

      console.log("device", device);
      testPrint(device);

    } catch (e) {
      console.error(e);
    }
  }

  async function testPrint(device) {
    const cmds = [
      'SIZE 48 mm,25 mm',
      'CLS',
      `TEXT 30,10,"4",0,1,1,"${`${rows?.firstName}, ${rows?.age}Y/${rows?.gender.slice(0, 1).toUpperCase()}`}"`,
      `TEXT 30,50,"2",0,1,1,", "${`${formatedDateInDigit(rows?.createdAt)}, ${rows?.labPrefix?.prefix}- ${rows?.labSuffix}`}"`,
      `BARCODE 30,80,"128",70,1,0,2,2, "${sample?.sampleNo}"`,
      `PRINT ${noLabels}`,
      'END',
    ];

    await device.open();
    await device.selectConfiguration(1);
    await device.claimInterface(0);
    await device.transferOut(
      device.configuration.interfaces[0].alternate.endpoints.find(obj => obj.direction === 'out').endpointNumber,
      new Uint8Array(
        new TextEncoder().encode(cmds.join('\r\n'))
      ),
    );
    await device.close();
  }


  // get list of devics
  async function getDevices() {
    const devices = await navigator.usb.getDevices();
    devices.forEach((device) => {
      console.log(`Name: ${device.productName}, Serial: ${device.serialNumber}`);
    });
    return devices;
  }

  const handleOpen = () => {
    requestDevice();
    // getDevices();
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.collectorForm}>
          <div className={classes.formheader}>
            <div className={classes.formnameHead}>
              <div className={classes.formh2}>Bar code</div>
              <div className={classes.littlehead}>
                Get bar code details here
              </div>
            </div>
            <div>
              <Buttons
                className={classes.formButton}
                onClick={() => navigate(`/appointment-view/${params.id}`)}
              >
                &nbsp; Back to table
              </Buttons>
            </div>
          </div>

          <div>
            <div className={classes.formMain}>
              <FormControl>
                <div className={classes.formDiv1}>
                  <div className={classes.formDiv2}>
                    <div className={classes.formLable}>Bar code printer</div>
                    <Input
                      type="text"
                      placeholder="TSC TE244"
                      className={classes.formInput}
                    //   name="patId"
                    //   value={values.patId}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                    <div style={{ color: "red" }}>{errors.patId}</div>
                  )} */}
                    <br />

                  </div>
                  <div className={classes.formDiv2}>
                    <div className={classes.formLable}>Printer port</div>
                    <FormControl>
                      <Select
                        className={classes.selectInput}
                        displayEmpty
                        name="status"
                      >
                        <MenuItem value="" disabled>
                          <p>Select</p>
                        </MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="InActive">InActive</MenuItem>
                      </Select>
                    </FormControl>{" "}
                    <br />
                  </div>
                </div>
                <br />
                <hr style={{ background: 'rgba(0, 0, 0, 0.20)', height: '1px', marginTop: '15px' }} />
                <br />

                <div className={classes.formDiv1}>
                  <div className={classes.formDiv2}>
                    <div className={classes.formLable}>Sample number</div>
                    <Input
                      type="text"
                      placeholder="1234"
                      value={sample?.sampleNo}
                      className={classes.formInput}
                      disabled={true}
                    //   name="patId"
                    //   value={values.patId}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                    <div style={{ color: "red" }}>{errors.patId}</div>
                  )} */}
                    <br />
                    <div className={classes.formLable}>Patient first name</div>
                    <Input
                      type="text"
                      placeholder="Ashok"
                      className={classes.formInput}
                      disabled={true}
                      value={rows?.firstName}
                    //   name="patId"
                    //   value={values.patId}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                    <div style={{ color: "red" }}>{errors.patId}</div>
                  )} */}
                    <br />
                    <div className={classes.formLable}>Gender*</div>
                    <FormControl>
                      <Select
                        className={classes.selectInput}
                        displayEmpty
                        name="status"
                        disabled={true}
                        value={rows?.gender}
                        defaultValue={rows?.gender}
                        renderValue={(selected) => (
                          <div>
                            <span>
                              {selected}
                            </span>
                          </div>
                        )}
                      >
                        <MenuItem value="" disabled>
                          <p>Gender</p>
                        </MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                      </Select>
                    </FormControl>{" "}
                    <br />
                    <div className={classes.formLable}>Registration date</div>
                    <Input
                      type="text"
                      placeholder="06-06-2023"
                      disabled={true}
                      value={rows?.createdAt ? formatedDate(rows?.createdAt) : ""}
                      className={classes.formInput}
                    //   name="patId"
                    //   value={values.patId}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                      <div style={{ color: "red" }}>{errors.patId}</div>
                    )} */}
                    <br />
                    <div className={classes.formLable}>No of Labels</div>
                    <Input
                      type="number"
                      placeholder="Enter"
                      className={classes.formInput}
                      //   name="patId"
                      value={noLabels}
                      onChange={(e) => setNoLabels(e.target.value)}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                      <div style={{ color: "red" }}>{errors.patId}</div>
                    )} */}
                    <br />
                  </div>
                  <div className={classes.formDiv2}>
                    <div className={classes.formLable}>Lab ref no</div>
                    <Input
                      type="text"
                      placeholder="NDSC-1234-23"
                      disabled={true}
                      value={rows?.labPrefix?.prefix}
                      className={classes.formInput}
                    //   name="patId"
                    //   value={values.patId}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                    <div style={{ color: "red" }}>{errors.patId}</div>
                  )} */}
                    <br />
                    <div className={classes.formLable}>Patient last name</div>
                    <Input
                      type="text"
                      placeholder="Kumar"
                      className={classes.formInput}
                      disabled={true}
                      value={rows?.lastName}

                    //   name="patId"
                    //   value={values.patId}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                    <div style={{ color: "red" }}>{errors.patId}</div>
                  )} */}
                    <br />
                    <div className={classes.formLable}>Age*</div>
                    <Input
                      type="text"
                      placeholder="35"
                      className={classes.formInput}
                      disabled={true}
                      value={rows?.age}
                    //   name="patId"
                    //   value={values.patId}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                    <div style={{ color: "red" }}>{errors.patId}</div>
                    )} */}
                    <br />
                    <div className={classes.formLable}>Registration time</div>
                    <Input
                      type="text"
                      placeholder="10:30AM"
                      className={classes.formInput}
                      disabled={true}
                      value={rows?.createdAt ? formatTime(rows?.createdAt) : ""}
                    //   name="patId"
                    //   value={values.patId}
                    //   onChange={handleChange}
                    //   onBlur={handleBlur}
                    />
                    {/* {errors.patId && touched.patId && (
                    <div style={{ color: "red" }}>{errors.patId}</div>
                  )} */}
                    <br />
                  </div>
                </div>

                <div className={classes.formDiv4}>
                  <Buttons
                    className={classes.cancelButton}
                    onClick={() => navigate(`/appointment-view/${params.id}`)}
                  >
                    Cancel
                  </Buttons>

                  <Buttons
                    className={classes.submitButton}
                    onClick={handleOpen}
                  >
                    Print
                  </Buttons>
                </div>
              </FormControl>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style }}>
            <Barcode width={1.5} height={40} fontSize={14} value={sample?.sampleNo} />
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default BarcodeCollection
