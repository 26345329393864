import React from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "../../Components/Shared/Buttons";
import { useNavigate } from "react-router-dom";

const AccessRight = () => {
    const tableclasses = tableStyles();
    const classes = formStyles();
    const navigate = useNavigate();
    const handleAccess = () =>{

    }
  return (
    <div className={tableclasses.root}>
      <div className={tableclasses.body}>
        <div className={tableclasses.backgroundDull}>
         <div className={tableclasses.accessHeader}>
        <span style={{display: 'flex', alignItems: 'center'}}>
        <div className={tableclasses.access}>Access Right</div>
        <div className={tableclasses.operator}>operator: ANAND</div>
        </span>
        <Buttons className={classes.backToButton} style={{width: '120px'}}
        onClick={() => navigate("/register-user")}
        >Back to Table</Buttons>
        </div>
        <div style={{paddingTop: '29px', display: 'flex'}}>
            <input type="radio" style={{width: '20px', height: '20px', cursor: 'pointer'}} />
            <p className={tableclasses.radio} style={{color: '#181c32'}}>Select all</p>
            <br />
            <input type="radio" style={{width: '18px', height: '18px', marginLeft: '31px', cursor: 'pointer'}} />
            <p className={tableclasses.radio} style={{color: '#181c32'}}>Unselect all</p>
        </div>
        <div className={tableclasses.borderbg}>
          <p style={{fontWeight: '500',fontSize: '14px'}}>All Menus</p>

          <div style={{paddingTop: '21px', display: 'flex', alignItems: 'center'}}>
            <input type="checkbox" className={tableclasses.sizecheckbox} />
            <p className={tableclasses.radio}>Accounts</p>
         </div>

          <div style={{paddingTop: '13px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Doctors</p>
         </div>

          <div style={{paddingTop: '15px', paddingLeft: '14px', display: 'flex'}}>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '52px'}} />
            <p className={tableclasses.radio}>Doctors</p>
          </div>
          <div style={{paddingTop: '15px', paddingLeft: '14px', display: 'flex'}}>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '52px'}} />
            <p className={tableclasses.radio}>Doctors</p>
          </div>
          <div style={{paddingTop: '15px', paddingLeft: '14px', display: 'flex'}}>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '52px'}} />
            <p className={tableclasses.radio}>Doctors</p>
          </div>

         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Operators</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Sample collectors</p>
         </div>

         <div style={{paddingTop: '15px', display: 'flex', alignItems: 'center'}}>
            <input type="checkbox" className={tableclasses.sizecheckbox} />
            <p className={tableclasses.radio}>Master data</p>
         </div>

          <div style={{paddingTop: '10px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Tests</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Reports</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Report format</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Price list</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Accounts</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Sample from</p>
         </div>

         <div style={{paddingTop: '15px', display: 'flex', alignItems: 'center'}}>
            <input type="checkbox" className={tableclasses.sizecheckbox} />
            <p className={tableclasses.radio}>Patients</p>
         </div>

          <div style={{paddingTop: '10px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Reception/patients</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Patient cards</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>Visits</p>
         </div>
         <div style={{paddingTop: '12px',paddingLeft: '15px', display: 'flex', alignItems: 'center'}}>
          <div className={tableclasses.checkbox}>+</div>
          <input type="checkbox" className={tableclasses.sizecheckbox} style={{marginLeft: '14px'}} />
            <p className={tableclasses.radio}>All reports</p>
         </div>

         <div style={{paddingTop: '15px', display: 'flex', alignItems: 'center'}}>
            <input type="checkbox" className={tableclasses.sizecheckbox} />
            <p className={tableclasses.radio}>Payments</p>
         </div>

        </div>
       </div>
     </div>
    </div>
  )
}

export default AccessRight
