import React, { useState, useEffect } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Input from "../../Components/Shared/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Buttons from "../../Components/Shared/Buttons";
import Select from "@material-ui/core/Select";
import Api from '../../helper/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'

const UpdateCollection = ({
  setUpdateCollection,
  collectionToEdit,
  setCollectionToEdit,
  rows,
  setReFetch,
  reFetch
}) => {
  const tableclasses = tableStyles();
  const classes = formStyles();
  const api = new Api();
  const params = useParams();


  const [value, setValue] = useState({
    sampleType: "",
    sampleStatus: "",
    barCodeId: "",
    date: "",
    sampleNo: ""
  })

  useEffect(() => {
    if (rows?.sampleCollection.length > 0) {

      const collection = rows?.sampleCollection.find((collection) => collection._id === collectionToEdit)
      setValue((prev) => {
        return { ...collection }
      })
    }
  }, [collectionToEdit])

  const handleChange = (e) => {
    e.persist();

    setValue((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const handleSubmit = async () => {
    try {
      const { data, status } = await api.editPatientSample(value, {
        id: params.id,
        sample: collectionToEdit
      })

      if (status === 200) {
        toast.success("Successfully updated")
        setUpdateCollection(false)
        setCollectionToEdit(null)
        setReFetch(!reFetch)
      }
    } catch (err) {
      toast.error("Failed to save")
    }
  }

  return (
    <div className={tableclasses.outBackground}>
      <div className={tableclasses.sampleCollection} style={{ marginTop: '198px' }}>
        <div
          className={tableclasses.exitbutton}
          style={{ cursor: "pointer" }}
          onClick={() => setUpdateCollection(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            style={{ position: 'relative', top: '23px', left: '92.5%' }}
          >
            <path
              d="M1 17L17 1M1 1L17 17"
              stroke="black"
              stroke-opacity="0.7"
              stroke-width="1.88067"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className={tableclasses.headSample}>Update Status</div>
        <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '1px #9c9c9c solid', marginBottom: '20px', paddingBottom: '25px' }}>
          <div className={classes.formlabled}>Sample: {value.sampleType}</div>
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Sample status</div>
          <Select
            className={classes.selectInput}
            name="sampleStatus"
            value={value.sampleStatus}
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            displayEmpty
          >
            <MenuItem value="collected">Collected</MenuItem>
            <MenuItem value="received">Received</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </div>
        <div className={tableclasses.sampleIn}>
          <Buttons
            className={classes.cancelButton}
            onClick={() => setUpdateCollection(false)}
          >
            Cancel
          </Buttons>
          <span className="px-1"></span>
          <Buttons
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Update
          </Buttons>
        </div>
      </div>

    </div>

  )
}

export default UpdateCollection;
