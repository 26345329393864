import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import { DoctorSvg } from "../../Components/Shared/UserSvg";
import FilterCategory from "../MasterData/FilterCategory";
import { DropdownSvg, FilterSvg, ResetFilterSvg } from "../../Components/Shared/UserSvg";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {
    MenuItem,
    ListItemIcon,
    ListItemText,
    Popover,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import Button from "@mui/material/Button";
import {
    formatedDateInDigit,
} from "../../helper/helper";
import AddPriceList from "./AddPriceList";
import DeleteCollection from "../patience/DeleteCollection";
import { toast } from "react-toastify";
import Api from "../../helper/api";
import { FiSearch } from "react-icons/fi";

import TextField from "@material-ui/core/TextField";

import { useReactToPrint } from "react-to-print";
import EditPriceList from "./EditPriceList";
import DeletePriceList from "./DeletePriceList";


const DoctorPriceList = () => {
    const api = new Api();
    const tableclasses = tableStyles();
    const [pageInfo, setPageInfo] = useState();
    const reportContent = useRef();
    const [filter, setFilter] = useState({
        category: null,
        accounts: null
    })
    const [filterData, setFilterData] = useState({
        category: [],
        accounts: "",
        search: ""
    })

    const classes = formStyles();
    const navigate = useNavigate();
    const [popupForm, setPopupForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);
    const [deleteForm, setDeleteForm] = useState(false);
    const [newData, setNewData] = useState(false);
    const [page, setPage] = useState(1);
    const [priceListToDelete, setPriceListToDelete] = useState(null);

    const [formToEdit, setFormToEdit] = useState(null);
    const [rows, setRows] = useState();
    const [doctor, setDoctor] = useState({});
    const [reFetch, setReFetch] = useState(false);
    const params = useParams();


    const TOKEN = localStorage.getItem("logintoken");

    const fetchData = async () => {
        try {
            const { data, status } = await api.fetchDoctorById(params.id);
            setDoctor(data.doctors);
        } catch (err) {
            console.log(err)
        }
    };

    const fetchDoctorPriceList = async () => {
        try {
            const { data, status } = await api.fetchDoctorPriceList({
                doctor: params.id,
                page,
                limit: 10,
                name: filterData.search,
                account: filterData.accounts
            })

            if (status === 200) {
                setRows(data.priceList)
                setPageInfo({
                    totalPages: data.totalPages,
                    currentPage: data.currentPage,
                    totalItems: data.totalItems
                })
            }

        } catch (err) {
            console.log(err)
        }
    }

    const generatePDF = useReactToPrint({
        content: () => reportContent.current,
        documentTitle: "PatientReport",
        // onAfterPrint:alert("Pdf Downloaded")
    })

    const handleSearch = (e) => {
        e.persist();

        setFilterData((prev) => {
            return { ...prev, search: e.target.value }
        })
    }

    const handleEdit = (data) => {
        setUpdateForm(true)
        setFormToEdit(data)
    };

    const handleDelete = async (id) => {
        setDeleteForm(true)
        setPriceListToDelete(id);
    };

    const setNextPage = () => {
        if (pageInfo?.currentPage > 0) {
            if (page === pageInfo?.totalPages) return
            setPage(page + 1)
        }
    }

    const setPrevPage = () => {
        if (pageInfo.currentPage > 1) {
            setPage(page - 1)
        }
    }

    const handleReset = () => {
        setFilterData((prev) => {
            return {
                ...prev,
                category: [],
                accounts: "",
            }
        })
    }

    useEffect(() => {
        fetchData();
    }, [reFetch]);

    useEffect(() => {
        fetchDoctorPriceList();
    }, [reFetch, filterData.search, filterData.accounts]);

    useEffect(() => {
        if (!TOKEN) {
            navigate("/");
        }
    }, [TOKEN]);


    return (
        <div className={tableclasses.root} style={{ margin: "25px" }}>
            {
                updateForm && <EditPriceList formToEdit={formToEdit} reFetch={reFetch} setReFetch={setReFetch} setUpdateForm={setUpdateForm} />
            }

            {
                deleteForm && <DeletePriceList setDeleteForm={setDeleteForm} priceListToDelete={priceListToDelete} reFetch={reFetch} setReFetch={setReFetch} />
            }
            <div className={tableclasses.appointmentbody}>
                <div className={tableclasses.headertwo}>
                    <div className={classes.formname}>
                        <div className={classes.formh2}>Brooklyn Simmons</div>
                        <div className={classes.viewHead}>
                            {rows?.urgentRequired && (
                                <div
                                    className={classes.formspecification}
                                    style={{ color: "#B82C3A" }}
                                >
                                    Urgent Requirement
                                </div>
                            )}
                        </div>
                        <Buttons
                            className={classes.backToButton}
                            style={{ width: "120px" }}
                            onClick={() => navigate("/register-doctor")}
                        >
                            Back to Table
                        </Buttons>
                    </div>
                </div>
                <div className={classes.biodata} style={{ justifyContent: "flex-start", gap: 50 }}>
                    <div style={{ display: "flex" }}>
                        <div className={classes.question}>
                            <p>Id</p>
                            <p>Doctor name</p>
                            <p>Phone</p>
                            <p>Specialization</p>
                            <p>Email address</p>
                        </div>
                        <div className={classes.of}>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                        </div>
                        <div className={classes.question}>
                            <p>{doctor?._id ? doctor?._id : "NA"}</p>
                            <p>
                                {doctor.name ? doctor.name : "NA"}
                            </p>
                            <p>
                                {doctor.phone ? doctor.phone : "NA"}
                            </p>
                            <p>{doctor?.specialisation ? doctor?.specialisation : "NA"}</p>
                            <p>{doctor?.email ? doctor?.email : "NA"}</p>
                        </div>
                    </div>

                    <div style={{ display: "flex" }}>
                        <div className={classes.question}>
                            <p>Location</p>
                            <p>Address</p>
                            <p>Status</p>
                            <p>Created Date</p>
                            <p>Hospital Name</p>
                        </div>
                        <div className={classes.of}>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                        </div>
                        <div className={classes.question}>
                            <p>{doctor?.location ? doctor?.location : "NA"}</p>
                            <p>{doctor?.address ? doctor?.address : "NA"}</p>
                            <p>{doctor?.status ? doctor?.status : "NA"}</p>
                            <p>{formatedDateInDigit(doctor?.createdAt)}</p>
                            <p>{doctor?.hospitalName ? doctor?.hospitalName : "NA"}</p>
                        </div>
                    </div>
                </div>
                {popupForm ? <AddPriceList reFetch={reFetch} setReFetch={setReFetch} setPopupForm={setPopupForm} /> : null}
                <div style={{ width: "100%" }}>

                    <FilterCategory
                        filter={filter}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        setFilter={setFilter} />
                    <div>
                        <div className={tableclasses.header}>
                            <div className={tableclasses.name}>
                                <div className={tableclasses.h2}>Price List</div>
                                <div className={tableclasses.specification}>{pageInfo?.totalItems} available test</div>

                            </div>
                            <div>
                                <Buttons
                                    className={tableclasses.addButton2}
                                    onClick={() => setPopupForm(true)}
                                >
                                    <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.3"
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.49984 9.88346C5.65889 9.88346 4.1665 8.39108 4.1665 6.55013C4.1665 4.70918 5.65889 3.2168 7.49984 3.2168C9.34079 3.2168 10.8332 4.70918 10.8332 6.55013C10.8332 8.39108 9.34079 9.88346 7.49984 9.88346ZM15.8332 9.88347C15.3729 9.88347 14.9998 9.51038 14.9998 9.05014V7.38347H13.3332C12.8729 7.38347 12.4998 7.01038 12.4998 6.55014C12.4998 6.0899 12.8729 5.7168 13.3332 5.7168H14.9998V4.05014C14.9998 3.5899 15.3729 3.2168 15.8332 3.2168C16.2934 3.2168 16.6665 3.5899 16.6665 4.05014V5.7168H18.3332C18.7934 5.7168 19.1665 6.0899 19.1665 6.55014C19.1665 7.01038 18.7934 7.38347 18.3332 7.38347H16.6665V9.05014C16.6665 9.51038 16.2934 9.88347 15.8332 9.88347Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M0.00054307 17.5494C0.323549 13.5722 3.55159 11.5501 7.48612 11.5501C11.476 11.5501 14.7541 13.4611 14.9983 17.5501C15.008 17.713 14.9983 18.2168 14.3722 18.2168C11.2843 18.2168 6.6956 18.2168 0.606252 18.2168C0.39726 18.2168 -0.0170515 17.7661 0.00054307 17.5494Z"
                                            fill="white"
                                        />
                                    </svg>{" "}
                                    &nbsp; Add new price list
                                </Buttons>
                            </div>
                        </div>

                        <div className={tableclasses.filterSearch}>
                            <div>
                                <Buttons className={tableclasses.filterButton1}>
                                    <FilterSvg />
                                </Buttons>
                                <Buttons
                                    className={tableclasses.filterButton2}>Filter By
                                </Buttons>
                                <Buttons
                                    onClick={(e) => setFilter((prev) => { return { ...prev, accounts: e.currentTarget } })}
                                    className={tableclasses.filterButton2}>
                                    Accounts
                                    <DropdownSvg />
                                </Buttons>
                                <Buttons
                                    onClick={(e) => setFilter((prev) => { return { ...prev, category: e.currentTarget } })}
                                    className={tableclasses.filterButton2}>Category
                                    <DropdownSvg />
                                </Buttons>
                                <Buttons
                                    onClick={handleReset}
                                    className={tableclasses.filterButton3}>
                                    <ResetFilterSvg />
                                    <span style={{ color: "#FF8743", marginLeft: 6, text: "center" }}>
                                        Reset FIlter
                                    </span>{" "}
                                </Buttons>
                                <Buttons className={tableclasses.printButton} onClick={generatePDF}>Print</Buttons>
                            </div>
                            <div className={tableclasses.searchContainer}>
                                <FiSearch className={tableclasses.searchIcon} />
                                <TextField
                                    className={tableclasses.searchField}
                                    placeholder="Search"
                                    variant="standard"
                                    size="small"
                                    value={filterData.search}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>

                        <Table className={tableclasses.table} ref={reportContent}>
                            <TableHead className={tableclasses.tableHead}>
                                <TableRow>
                                    <TableCell className={tableclasses.customHeadName}>
                                        Report ID
                                    </TableCell>
                                    <TableCell className={tableclasses.customHeadName}>
                                        Report name
                                    </TableCell>
                                    <TableCell className={tableclasses.customHeadName}>
                                        Rate
                                    </TableCell>
                                    <TableCell className={tableclasses.customHeadName}>
                                        Report Category
                                    </TableCell>
                                    <TableCell className={tableclasses.customHeadName}>
                                        Account
                                    </TableCell>
                                    <TableCell className={tableclasses.customHeadName}>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.map((row) => (
                                    <TableRow key={row._id}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                        >
                                            <div className={tableclasses.name}>
                                                <div>{row.priceList._id}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>{row.priceList.priceListName}</div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>₹ {row.rate}</div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>{row?.priceList.category?.name ? row?.priceList.category?.name : "NA"}</div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>{row?.priceList?.account?.name}</div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                <PopoverMenu
                                                    handleEdit={() => handleEdit(row)}
                                                    handleDelete={() => handleDelete(row.priceList._id)}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className={tableclasses.pagination}>
                            <div className={tableclasses.name}>Showing {rows?.length} of {pageInfo?.totalItems} entries</div>
                            <div>
                                <Buttons
                                    onClick={setPrevPage}
                                    className={tableclasses.pageButton}>Previous</Buttons>
                                <Buttons className={tableclasses.numButton}>{pageInfo?.currentPage}</Buttons>
                                <Buttons
                                    onClick={setNextPage}
                                    className={tableclasses.pageButton}>Next</Buttons>
                            </div>
                            {/* <div></div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

const PopoverMenu = ({
    handleEdit,
    handleDelete,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();
    const [role, setRole] = useState("");
    const user_role = localStorage.getItem("role");

    const path = location.pathname.split("/")[1];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        setRole(user_role)
    }, [user_role])

    const handleClose = () => {
        setAnchorEl(false);
    };

    const deleteInvestigation = () => {
        if (role === "admin") {
            handleDelete()
        } else {
            toast.error("Permission not granted!")
        }
    }

    return (
        <div>
            <IconButton
                onClick={handleClick}
                style={{
                    height: "32px",
                    width: "32px",
                    textAlign: "center",
                    borderRadius: "6px",
                    backgroundColor: "#FAFAFA",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <span
                    style={{
                        fontSize: "22px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: "13px",
                        "&.MuiIconButton-label": {
                            paddingBottom: "13px",
                            marginTop: "-12px",
                        },
                    }}
                >
                    ...
                </span>
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        backgroundColor: "#273142",
                        color: "#FFFFFF",
                        borderRadius: "16px",
                        minWidth: "114px",
                    },
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >

                <MenuItem
                    onClick={() => {
                        handleEdit();
                        handleClose();
                    }}
                >
                    <ListItemText
                        primary="Edit"
                        style={{
                            borderRadius: "43px",
                            border: "1px solid rgba(255, 255, 255, 0.2)",
                            textAlign: "center",
                            padding: "8px 10px",
                        }}
                    />
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        deleteInvestigation();
                        handleClose();
                    }}
                >
                    <ListItemText
                        primary="Delete"
                        style={{
                            borderRadius: "43px",
                            border: "1px solid rgba(255, 255, 255, 0.2)",
                            textAlign: "center",
                            padding: "8px 10px",
                        }}
                    />
                </MenuItem>
            </Popover>
        </div>
    );
};

export default DoctorPriceList;
