import React, { useState, useEffect } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Input from "../../Components/Shared/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Buttons from "../../Components/Shared/Buttons";
import Select from "@material-ui/core/Select";
import Api from '../../helper/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


const EditReceipt = ({ setReceipt, paymentToEdit, rows, setReFetch, reFetch, id }) => {
  const tableclasses = tableStyles();
  const classes = formStyles();
  const api = new Api();

  const [value, setValue] = useState({
    mode: "",
    paidAmount: 0,
    paymentNo: ""
  })

  useEffect(() => {
    if (rows?.payment.deposits.length > 0) {

      const payment = rows?.payment.deposits.find((payment) => payment._id === paymentToEdit)
      setValue((prev) => {
        return { ...payment }
      })
    }
  }, [paymentToEdit])

  // handle payment data
  const handlePayment = (e) => {
    e.persist();

    setValue((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const handleSubmit = async () => {
    try {
      const { data, status } = await api.editPatientPayment(value, {
        id: id,
        payment: paymentToEdit
      })

      if (status === 200) {
        toast.success("Payment receipt updatedd successfully")
        setReceipt(false)
        setReFetch(!reFetch)
      }
    } catch (err) {
      toast.error("Failed to update payment")
    }
  }
  return (
    <div className={tableclasses.outBackground}>
      <div className={tableclasses.sampleCollection} style={{ marginTop: '128px' }}>
        <div
          className={tableclasses.exitbutton}
          style={{ cursor: "pointer" }}
          onClick={() => setReceipt(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            style={{ position: 'relative', top: '23px', left: '92.5%' }}
          >
            <path
              d="M1 17L17 1M1 1L17 17"
              stroke="black"
              stroke-opacity="0.7"
              stroke-width="1.88067"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className={tableclasses.headSample}>New Payment Receipt</div>
        <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '1px #9c9c9c solid', marginBottom: '20px', paddingBottom: '25px' }}>
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Paid Amount</div>
          <Input
            type="number"
            placeholder="Enter"
            value={value.paidAmount}
            className={classes.formInputSample}
            onChange={handlePayment}
            name="paidAmount"
          />
          {/* {errors.name && touched.name && (
                      <div style={{ color: "red", fontSize: '13px' }}>{errors.name}</div>
                    )} */}
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Payment mode</div>
          <Select
            className={classes.selectInput}
            name="mode"
            onChange={handlePayment}
            value={value.mode}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            displayEmpty
          >
            <MenuItem
              value=""
              style={{ backgroundColor: "transparent" }}
            >
              select
            </MenuItem>
            <MenuItem
              value="online"
              style={{ backgroundColor: "transparent" }}
            >
              Online
            </MenuItem>
            <MenuItem value="cash">
              Cash
            </MenuItem>
          </Select>
          {/* <div className={classes.formLable}
            style={{ width: '355px' }}
          >Date & Time</div>
          <div style={{ width: '350px' }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              style={{
                '&.MuiStack-root':
                  { overflow: 'none', },
              }}
            >
              <DemoContainer components={['DatePicker']}>
                <DateTimePicker
                  className={classes.dateInput}
                  onChange={(e) => setValue((prev) => { return { ...prev, transactionDate: e.$d } })}
                  name="transactionDate"
                />
              </DemoContainer>
            </LocalizationProvider>
          </div> */}

        </div>
        <div className={tableclasses.sampleIn}>
          <Buttons
            className={classes.cancelButton}
            onClick={() => setReceipt(false)}
          >
            Cancel
          </Buttons>
          <span className="px-1"></span>
          <Buttons
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Update
          </Buttons>
        </div>
      </div>

    </div>

  )
}

export default EditReceipt;
