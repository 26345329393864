import React, { useEffect, useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, Button, InputLabel, List, ListItemText, Tab } from '@material-ui/core';
import Buttons from "../../Components/Shared/Buttons";
import { Checkbox } from "@material-ui/core";
import Api from '../../helper/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select, { components, MenuListProps } from 'react-select';
import Input from "../../Components/Shared/Input"

const EditPriceList = ({ setUpdateForm, reFetch, setReFetch, formToEdit }) => {
    const tableclasses = tableStyles();
    const classes = formStyles();
    const obj = {
        value: formToEdit.priceList._id,
        label: formToEdit.priceList.priceListName,
        name: formToEdit.priceList.priceListName,
        id: formToEdit.priceList._id,
        rate: formToEdit.rate
    }

    const [id, setId] = useState(obj)
    const [rate, setRate] = useState(formToEdit?.rate);

    const api = new Api();
    const params = useParams();

    const handleSubmit = async () => {
        try {
            const { data, status } = await api.editPriceList({
                doctor: params.id,
                priceList: id.id,
                rate: rate
            })

            if (status === 200) {
                toast.success("PriceList added successfully")
                setUpdateForm(false)
                setReFetch(!reFetch)
            }
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data.message)
            }
        }
    }


    const data = [{
        label: "Name", options: [obj]
    }]

    return (
        <div className={tableclasses.outBackground}>
            <div className={tableclasses.sampleCollection} style={{ marginTop: '158px', width: '55%', minHeight: "400px" }}>
                <div
                    className={tableclasses.exitbutton}
                    style={{ cursor: "pointer" }}
                    onClick={() => setUpdateForm(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        style={{ position: 'relative', top: '23px', left: '92.5%' }}
                    >
                        <path
                            d="M1 17L17 1M1 1L17 17"
                            stroke="black"
                            stroke-opacity="0.7"
                            stroke-width="1.88067"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div className={tableclasses.headSample}>Edit New Invastigation</div>
                <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '1px #9c9c9c solid', marginBottom: '20px', paddingBottom: '25px' }}>
                    <div className={classes.formLable}
                        style={{ width: '600px', marginBottom: "10px" }}
                    >Test Information</div>

                    <Select
                        options={data}
                        components={{ Option, GroupHeading }}
                        isDisabled
                        value={id}
                        styles={{ input: (styles) => ({ ...styles, width: "540px" }) }}
                    />
                    <div className={classes.formLable}
                        style={{ width: '600px', marginBottom: "10px" }}
                    >Rate</div>
                    <Input
                        type="number"
                        placeholder="Enter"
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                        className={classes.formInputSample}
                        style={{ width: "600px" }}
                        name="totalAmount"
                    />

                </div>
                <div className={tableclasses.sampleIn}>
                    <Buttons
                        className={classes.cancelButton}
                        onClick={() => setUpdateForm(false)}
                    >
                        Cancel
                    </Buttons>
                    <span className="px-1"></span>
                    <Buttons
                        onClick={handleSubmit}
                        className={classes.submitButton}
                    >
                        Submit
                    </Buttons>
                </div>

            </div>
        </div>
    )
}

const Option = (props) => {
    return (
        <>
            <components.Option {...props}>
                <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
                    <p>{props.data.label}</p>
                    <p>{props.data.id}</p>
                    <p>₹{props.data.rate}</p>
                </div>
            </components.Option>
        </>
    )
}

const GroupHeading = (props) => {
    return (
        <components.GroupHeading {...props}>
            <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
                <p>Name</p>
                <p>Id</p>
                <p>Rate</p>
            </div>
        </components.GroupHeading>
    )
}
export default EditPriceList
