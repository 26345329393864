import React, { useState, useEffect } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Input from "../../Components/Shared/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Buttons from "../../Components/Shared/Buttons";
import Select from "@material-ui/core/Select";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Api from '../../helper/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'


const PaymentCollection = ({ setPayment, setReFetch, reFetch, payment, id }) => {
  const tableclasses = tableStyles();
  const classes = formStyles();
  const api = new Api();

  const [value, setValue] = useState({
    mode: "",
    paidAmount: 0,
  })

  // handle payment data
  const handlePayment = (e) => {
    e.persist();

    setValue((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }


  const handleSubmit = async () => {
    try {
      const { data, status } = await api.addNewPayment(value, { id: id })

      if (status === 200) {
        toast.success("Payment receipt added successfully")
        setPayment(false)
        setReFetch(!reFetch)
      }
    } catch (err) {
      toast.error("Failed to add new payment")
    }
  }
  return (
    <div className={tableclasses.outBackground}>
      <div className={tableclasses.sampleCollection} style={{ marginTop: '128px' }}>
        <div
          className={tableclasses.exitbutton}
          style={{ cursor: "pointer" }}
          onClick={() => setPayment(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            style={{ position: 'relative', top: '23px', left: '92.5%' }}
          >
            <path
              d="M1 17L17 1M1 1L17 17"
              stroke="black"
              stroke-opacity="0.7"
              stroke-width="1.88067"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className={tableclasses.headSample}>New Payment Receipt</div>
        <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '1px #9c9c9c solid', marginBottom: '20px', paddingBottom: '25px' }}>
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Total Due Amount</div>
          <Input
            type="number"
            placeholder="Enter"
            disabled={true}
            value={payment.totalDueAmount}
            className={classes.formInputSample}
            name="totalAmount"
          />
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Paid Amount</div>
          <Input
            type="number"
            placeholder="Enter"
            className={classes.formInputSample}
            onChange={handlePayment}
            name="paidAmount"
          />
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Payment mode</div>
          <Select
            className={classes.selectInput}
            name="mode"
            onChange={handlePayment}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            displayEmpty
          >
            <MenuItem
              value=""
              style={{ backgroundColor: "transparent" }}
            >
              select
            </MenuItem>
            <MenuItem
              value="online"
              style={{ backgroundColor: "transparent" }}
            >
              Online
            </MenuItem>
            <MenuItem value="cash">
              Cash
            </MenuItem>
          </Select>
        </div>
        <div className={tableclasses.sampleIn}>
          <Buttons
            className={classes.cancelButton}
            onClick={() => setPayment(false)}
          >
            Cancel
          </Buttons>
          <span className="px-1"></span>
          <Buttons
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Update
          </Buttons>
        </div>
      </div>

    </div>

  )
}

export default PaymentCollection;
