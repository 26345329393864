import React, { useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Input from "../../Components/Shared/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Buttons from "../../Components/Shared/Buttons";
import Select from "@material-ui/core/Select";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Api from '../../helper/api';
import { toast } from 'react-toastify'
import dayjs from 'dayjs';

const AddnewBtn = ({ setPopup, id, reFetch, setReFetch }) => {
  const api = new Api();
  const tableclasses = tableStyles();
  const classes = formStyles();

  const [value, setValue] = useState({
    sampleType: "",
    sampleStatus: "",
    barCodeId: "",
    date: new Date(),
    sampleNo: ""
  })

  const handleChange = (e) => {
    e.persist();

    setValue((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const handleSubmit = async () => {
    try {
      const { data, status } = await api.addNewSample(value, { id })

      if (status === 200) {
        toast.success("Sample added successfully")
        setPopup(false)
        setReFetch(!reFetch)
      }
    } catch (err) {
      toast.error("Failed to add new sample")
    }
  }

  return (
    <div className={tableclasses.outBackground}>
      <div className={tableclasses.sampleCollection}>
        <div
          className={tableclasses.exitbutton}
          style={{ cursor: "pointer" }}
          onClick={() => setPopup(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            style={{ position: 'relative', top: '23px', left: '92.5%' }}
          >
            <path
              d="M1 17L17 1M1 1L17 17"
              stroke="black"
              stroke-opacity="0.7"
              stroke-width="1.88067"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className={tableclasses.headSample}>Add New sample collection</div>
        <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '1px #9c9c9c solid', marginBottom: '20px', paddingBottom: '25px' }}>
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Sample name</div>
          <Input
            type="text"
            placeholder="Enter"
            className={classes.formInputSample}
            name="sampleType"
            onChange={handleChange}
          />
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Bar code Sufix</div>
          <Input
            type="text"
            placeholder="Enter"
            className={classes.formInputSample}
            name="barCodeId"
            onChange={handleChange}
          />
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Sample status</div>
          <Select
            className={classes.selectInput}
            name="sampleStatus"
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            displayEmpty
          >
            <MenuItem value="collected">Collected</MenuItem>
            <MenuItem value="received">Received</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
          <div className={classes.formLable}
            style={{ width: '355px' }}
          >Collection date</div>
          <div style={{ width: '350px' }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              style={{
                '&.MuiStack-root':
                  { overflow: 'none', },
              }}
            >
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker
                  className={classes.dateInput}
                  value={dayjs(value.date)}
                  onChange={(e) => setValue((prev) => { return { ...prev, date: e.$d } })}
                  name="date"
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          {/* <div className={classes.formLable}
            style={{ width: '355px' }}
          >Collection time</div> */}
          {/* <Select
            className={classes.selectInput}
            name="status"
            //   value={values.status}
            // onChange={handleChange}
            // onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            displayEmpty
          >
            <MenuItem
              value=""
              style={{ backgroundColor: "transparent" }}
            >
              10:30AM
            </MenuItem>
            <MenuItem
              value="Active"
              style={{ backgroundColor: "transparent" }}
            >
              Active
            </MenuItem>
            <MenuItem value="Inactive">
              Inactive
            </MenuItem>
          </Select> */}
        </div>
        <div className={tableclasses.sampleIn}>
          <Buttons
            className={classes.cancelButton}
            onClick={() => setPopup(false)}
          >
            Cancel
          </Buttons>
          <span className="px-1"></span>
          <Buttons
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Submit
          </Buttons>
        </div>
      </div>

    </div>

  )
}

export default AddnewBtn
