import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const Uploading = () => {
    return (
        <div className='uploading' >
            <CircularProgress style={{color: "#fff"}} />
        </div>
    )
}

export default Uploading