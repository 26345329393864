import { makeStyles } from "@material-ui/core/styles";

export const tableStyles = makeStyles({
  root: {
    background: "#f5f5f5", // Set your desired background color
    paddingBottom: "3%",
    paddingTop: "2%",
    fontFamily: "Poppins",
    height: "100%",
  },
  table: {
    border: "none",
    width: "94%",
    borderRadius: "10px",
    marginBottom: "10px",
    "& .MuiTableCell-root": {
      borderBottom: "none",
      padding: "10px"
    },
  },
  customlistitemtext: {
    border: "1px solid #5a5a5a",
    padding: "8px 16px 8px 16px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "43px",
    marginRight: "35px",
  },
  tableHead: {
    background: "rgba(218, 218, 218, 0.49)",
    height: "43px",
    margingBottom: "20px",
    fontFamily: "Poppins",
    borderRadius: "6px !important",
    "& .MuiTableHead-root": {
      width: "96%", // Set your desired width
      height: "43px", // Set your desired height
      color: "#B5B5C3",
      boxShadow: "none",
      textAlign: "center",
      borderRadius: "6px !important",
    },
    "& .MuiTableCell-head": {
      color: "#464E5f",
    },
    "& .MuiTableCell-stickyHeader": {
      background: "rgba(218, 218, 218)",
    }
  },
  paymentTableHead: {
    background: "rgba(218, 218, 218, 0.49)",
    height: "24px",
    margingBottom: "20px",
    fontFamily: "Poppins",
    borderRadius: "6px !important",
    "& .MuiTableHead-root": {
      width: "96%", // Set your desired width
      height: "24px", // Set your desired height
      color: "#B5B5C3",
      boxShadow: "none",
      textAlign: "center",
      borderRadius: "6px !important",
    },
    "& .MuiTableCell-head": {
      color: "#464E5f",
    },
    "& .MuiTableCell-stickyHeader": {
      background: "rgba(218, 218, 218)",
    }
  },
  customTableCell: {
    borderBottom: "none",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 600,
    color: "rgba(7, 21, 70, 1)",
    transformText: "none",
    padding: "10px 10px",
    verticalAlign: "top"
  },

  customTableCellAppt: {
    borderBottom: "none",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 600,
    color: "#464E5F",
    boxShadow: "4px 4px 20px 0px rgba(0, 0, 0, 0.20)",
  },
  customHeadName: {
    borderBottom: "none",
    fontSize: "12px",
    fontWeight: 600,
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "Poppins",
  },

  customHeaderAppt: {
    borderBottom: "1px solid gray",
    fontSize: "12px",
    fontWeight: 600,
    color: "#B5B5C3",
    fontFamily: "Poppins",
    borderRadius: "5px",
  },
  header: {
    width: "94%",
    paddingTop: "3%",
    paddingBottom: "1%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  headertwo: {
    width: "100%",
    fontFamily: "Poppins",
    padding: "25px 0 55px 0",
  },
  h2: {
    fontSize: "18px",
    fontWeight: 500,
    color: "#212121",
    fontFamily: "Poppins",
  },
  customRow: {
    background: "lightblue",
    fontFamily: "Poppins",
  },
  customActive: {
    borderRadius: "6px",
    height: "26px",
    width: "74px",
    fontFamily: "Poppins",
    fontSize: "11px",
    fontWeight: 500,
    "&.MuiButton-contained": {
      backgroundColor: "#F4FFF3",
      color: "#5F8D4E",
      boxShadow: "none",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#F4FFF3",
      },
    },

    "&:hover": {},
  },
  customInActive: {
    borderRadius: "6px",
    height: "26px",
    width: "74px",
    fontFamily: "Poppins",
    fontSize: "11px",
    fontWeight: 500,
    "&.MuiButton-contained": {
      backgroundColor: "#FFE2E5",
      color: "#F64E60",
      boxShadow: "none",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#FFE2E5",
      },
    },

    "&:hover": {},
  },
  customArrow: {
    backgroundColor: "#FAFAFA",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    // marginLeft:"18px",
    // marginBottom:"5px"
  },
  body: {
    fontFamily: "Poppins",
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "12px",
    marginLeft: "6%",
    marginRight: "6%",
    marginTop: "1%",
    position: "relative",
  },
  appointmentbody: {
    fontFamily: "Poppins",
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 31px",
    borderRadius: "12px",
    marginLeft: "6%",
    marginRight: "6%",
    marginTop: "3%",
    position: "relative",
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "6px",
  },
  MuiAvatar: {
    borderRadius: "6px",
  },
  profile: {
    display: "flex",
  },
  name: {
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "center",
    alignItems: "left",
    flexDirection: "column",
    /*  paddingLeft: "12px", */
    fontSize: "12px",
    color: "#464E5F",
  },
  specification: {
    color: "#B5B5C3",
    fontSize: "12px",
    fontFamily: "Poppins",
  },
  addButton: {
    backgroundColor: "#B82C3A",
    borderRadius: "6px",
    height: "40px",
    width: "170px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#B82C3A",
      color: "#FFFFFF",
      fontSize: "12px",
      boxShadow: "none",
      textTransform: "none",
      "&:hover": {
        /*  transform: 'none', */ backgroundColor: "#B82C3A",
      },
    },
  },
  addButton2: {
    borderRadius: "6px",
    height: "40px",
    width: "178px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#B82C3A",
      color: "#FFFFFF",
      fontSize: "12px",
      boxShadow: "none",
      textTransform: "none",
      "&:hover": {
        /*  transform: 'none', */ backgroundColor: "#B82C3A",
      },
    },
  },
  addButton3: {
    borderRadius: "6px",
    height: "40px",
    width: "218px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#B82C3A",
      color: "#FFFFFF",
      fontSize: "12px",
      boxShadow: "none",
      textTransform: "none",
      "&:hover": {
        /*  transform: 'none', */ backgroundColor: "#B82C3A",
      },
    },
  },
  filterSearch: {
    width: "94%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "left",
    fontFamily: "Poppins",
    marginBottom: "30px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gridGap: "8px",
    maxWidth: "230px",
    height: "fit-content",
    padding: "12px 16px",
    marginBottom: "0px",
    borderRadius: "63px",
    backgroundColor: "#FAFAFA",
    border: "1px solid #B5B5C3",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  findPatience: {
    width: "255px",
    height: "44px",
    borderRadius: "40px",
    border: "1px solid var(--Grey, #C9C9C9)",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    marginLeft: "5px",
    padding: "0 12px",
  },
  sampleIn: {
    width: "250px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    paddingBottom: "28px",
    margin: "auto",
  },
  outBackground: {
    position: "fixed",
    width: "100%",
    height: "100vh",
    overflowY: "auto",
    top: "0",
    left: "0",
    backgroundColor: "var(--gray, #00000080)",
    zIndex: "10",
  },
  paper: {
    // position: 'absolute',
    margin: "127px auto 30px auto",
    paddingBottom: "20px",
    width: "calc(100% - 120px)",
    backgroundColor: "white",
    height: "fit-content",
    borderRadius: "12px",
    zIndex: "5",
    overflow: "hidden",
  },
  calendar: {
    "&.MuiPopper-root": {
      top: '0',
      position: "relative !important",
      transform: "0 !important",
    },
  },
  sampleCollection: {
    margin: "auto",
    width: "48%",
    backgroundColor: "white",
    height: "fit-content",
    borderRadius: "12px",
    zIndex: "5",
    marginTop: "38px",
    marginBottom: "25px",
    overflow: "hidden",
  },
  exitbutton: {
    position: "relative",
  },
  headSample: {
    textAlign: "center",
    color: "#000",
    fontSize: "17px",
    fontWeight: "600",
    letterSpacing: "0.51px",
    borderBottom: "1px #9c9c9c solid",
    paddingBottom: "23px",
    background: "var(--Gray-Lite, #FAFAFA)",
  },
  labeling: {
    color: "#181C32",
    minWidth: "100px",
    height: "41px",
    alignItems: "center",
    display: "flex",
    fontSize: "13px",
  },
  patientdDetail: {
    width: "273px",
    height: "41px",
    borderRadius: "4px",
    border: "1px solid #C9C9C9",
    justifyContent: "flex-start",
    display: "flex",
  },
  searchBy: {
    color: "#212121",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "21px",
    padding: "24px 47px",
  },
  findButton: {
    backgroundColor: "#273142",
    borderRadius: "43px",
    width: "61px",
    height: "33px",
    border: "1px solid rgba(255, 255, 255, 0.20)",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "14px",
      borderRadius: "43px",
      boxShadow: "none",
      width: "61px",
      height: "33px",
      border: "1px solid rgba(255, 255, 255, 0.20)",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  findHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "19px 46px",
    borderBottom: "1px solid #C9C9C9",
    alignItems: "center",
    backgroundColor: "var(--Grey-Lite, #FAFAFA)",
  },
  backgroundDull: {
    width: "96%",
    alignItems: "left",
    paddingTop: "2%",
    height: "fit-content",
  },
  access: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#212121",
  },
  operator: {
    fontSize: "13px",
    color: "#B5B5C3",
    paddingLeft: "29px",
    fontWeight: "500",
  },
  sizecheckbox: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
  },
  accessHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  accessHeader2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "75%",
  },
  firstBox: {
    borderRadius: "12px",
    background: "#FFF",
    boxShadow: "4px 4px 20px 0px rgba(0, 0, 0, 0.20)",
    margin: "21px 0 30px 0",
    flexDirection: "column",
    padding: "0 0 25px 0",
  },
  radio: {
    fontSize: "13px",
    padding: "0px 7px",
  },
  checkbox: {
    border: "1px solid #C2C2C2",
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    cursor: "pointer",
  },
  borderbg: {
    width: "100%",
    height: "99%",
    margin: "0.5% auto",
    border: "0.5px solid #999",
    padding: "21px 24px",
    color: "#424242",
  },
  searchField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    width: "100%",
    "& .MuiInputBase-root": {
      fontFamily: "Poppins",
      color: "#B5B5C3",
      fontSize: "12px !important",
      width: "100%",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#B5B5C3",
      fontSize: "12px !important",
    },
    "& label": {
      transform: "none",
      fontSize: "12px !important",
    },
    "& .MuiInputLabel-animated": {
      transition: "none", // disable the label transition to prevent the label popup
    },
    "&:hover .MuiInputLabel-animated": {
      transition: "none", // disable the label transition on hover to prevent the label popup
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
      content: "none",
    },
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none", // remove the underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "&:hover .MuiInput-underline:after": {
      borderBottom: "none", // remove the underline on hover
    },
    "& .MuiInputBase-inputMarginDense": {
      padding: "0",
    },
    "& input": {
      "&::placeholder": {
        color: "#636377", // Change the color to your desired color
      },
    },
  },
  searchIcon: {
    fontSize: "16px",
    color: "#B5B5C3",
    fontFamily: "Poppins",
  },

  filterButton1: {
    height: "44px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "14px",
      fontWeight: 500,
      boxShadow: "none",
      borderRadius: "6px  0 0 6px",
      borderRight: "1px solid #3A4251",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  filterButton2: {
    height: "44px",
    fontFamily: "Poppins",
    fontWeight: 500,
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "14px",
      boxShadow: "none",
      borderRadius: "0  0 0 0",
      borderRight: "1px solid #3A4251",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  filterButton3: {
    height: "44px",
    width: "138px",
    fontFamily: "Poppins",
    fontWeight: 500,
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "14px",
      boxShadow: "none",
      borderRadius: "0 6px 6px 0",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  filterButtonHori: {
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    flexDirection: "column",
    backgroundColor: "#273142",
    padding: 15,
    borderRadius: "15px",
    position: "absolute",
    top: "330px",
    right: "0px",
    zIndex: "1",
  },
  filterButtonh1: {
    height: "33px",
    width: "58px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "13px",
      boxShadow: "none",
      borderRadius: "25px",
      textTransform: "none",
      border: "1px solid #B5B5C3",
      marginBottom: 10,
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  filterButtonh2: {
    height: "33px",
    width: "76px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "13px",
      boxShadow: "none",
      borderRadius: "25px",
      textTransform: "none",
      border: "1px solid #B5B5C3",
      marginBottom: 10,
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  filterButtonh3: {
    height: "33px",
    width: "86px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "13px",
      boxShadow: "none",
      borderRadius: "25px",
      textTransform: "none",
      border: "1px solid #B5B5C3",
      marginBottom: 10,
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  filterButtonh4: {
    height: "33px",
    width: "120px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "13px",
      boxShadow: "none",
      borderRadius: "25px",
      textTransform: "none",
      border: "1px solid #B5B5C3",
      marginBottom: 10,
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  printButton: {
    height: "44px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "12px",
      boxShadow: "none",
      borderRadius: "5px",
      textTransform: "none",
      border: "1px solid #B5B5C3",
      marginLeft: "5px",
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
    "&.MuiButton-root": {
      minWidth: "50px !important"
    }
  },

  printButton2: {
    height: "44px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "13px",
      boxShadow: "none",
      borderRadius: "5px",
      textTransform: "none",
      border: "1px solid #B5B5C3",
      marginLeft: 0,
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  pageButton: {
    height: "33px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "transparent",
      color: "#464E5F",
      fontSize: "13px",
      boxShadow: "none",
      borderRadius: "5px",
      textTransform: "none",
      border: "1px solid #B5B5C3",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  numButton: {
    height: "33px",
    width: "30px",
    fontFamily: "Poppins",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "13px",
      boxShadow: "none",
      borderRadius: "5px",
      textTransform: "none",
      border: "1px solid #B5B5C3",
      marginLeft: 10,
      marginRight: 10,
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  pagination: {
    width: "57%",
    display: "flex",
    justifyContent: "space-between",
    margin: "40px 35% 40px 0",
  },

  patienceListMain: {
    position: "absolute",
    width: "1300px",
    height: "456px",
    left: "0px",
    top: "0px",
    background: " #FFFFFF",
    borderRadius: "12px",
  },

  patienceTypo: {
    width: "259px !important",
    height: "21px !important",
    left: "29.5px !important",
    top: "138.95px !important",

    fontFamily: "Poppins !important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px !important",
    lineHeight: "21px !important",
    /* identical to box height */

    color: "#464E5F",
  },

  entryHeader: {
    position: "absolute",
    height: "60px",
    left: "0.65px",
    right: "0.5px",
    top: "0.47px",
    background: "#FAFAFA",
    borderRadius: "6px",
    marginTop: "17px",
    width: "100%",
    border: "1px solid #464E5F",
  },

  entryHeaderTypo: {
    width: "151px",
    height: "21px",
    left: "47.64px",
    top: "20px",
    marginLeft: "47px",
    marginTop: "17px",

    fontFamily: "Poppins !important",
    fontStyle: "bold",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "21px",

    color: "#212121 !important",
  },

  patienceDetailsMain: {
    display: "flex",
    alignItems: "center",
    marginTop: "65px",
    justifyContent: "space-between",
  },

  patienceDetailstime: {
    marginLeft: "200px",
  },

  patienceDetailsReport: {
    marginLeft: "100px",
  },

  patienceFont: {
    // height: "20px",
    left: "41px",
    top: "105px",

    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "20px",
    /* identical to box height */

    color: "#464E5F",
  },
});
