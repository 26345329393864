import { makeStyles   } from '@material-ui/core/styles';

export const headerStyles = makeStyles({

    headerMain:{
        fontFamily: 'Poppins',
        display: 'flex',
        width: '100%', 
     /*    backgroundColor: '#B82C3A', */
        height: "160px" ,
    },
     tabMain:{
        color: '#FFFFFF',
}, 
tabs:{
    fontFamily: 'Poppins',
    '&.MuiTab-wrapper':{
        textTransform: 'none !important',
        fontFamily: 'Poppins',
    },
    
    '& .MuiTabs-indicator':{
    bottom: "none",
    border: "none",
    display: 'none',
    },      
    '&.MuiTabs-flexContainer':{
    height: '60px',
    },
    }, 
    tabsfont:{
        fontFamily: 'Poppins',
        '&.MuiTab-wrapper':{
            textTransform: 'none !important',
            fontFamily: 'Poppins',
        },},
    tablist:{
        height: '60px',
        width: '80px',
        textTransform: 'none !important',
        fontSize: "16px !important",
        fontFamily: 'Poppins',
        '&.MuiTab-root':{
            minWidth: '140px',
            fontFamily: 'Poppins',
        },
        '&.Mui-selected':{
            backgroundColor: '#FFFFFF',
           /*  textTransform: 'none', */
            color: '#B82C3A',
           /*  top: '16px',
            height: '60px', */
            borderRadius: '6px 6px 0 0',
            fontFamily: 'Poppins',
        },

    },
    linkActive:{
        transform: 'none', 
        backgroundColor: '#B82C3A1A',
        boxShadow: 'none',
        color: '#B82C3A !important',
        borderRadius: '4px',
        fontFamily: 'Poppins',
        fontSize: '16px',
        padding: '8px 0px',
        fontFamily: 'Poppins',
    },
    customButton:{
        borderRadius: '4px',
        height : '40px',
        fontFamily: 'Poppins',
        fontSize: '14px',
        backgroundColor: 'transparent !important',
        '&.MuiButton-contained':{
            backgroundColor: 'transparent !important',
            color: '#B82C3A',
            fontSize: '14px',
            boxShadow: 'none',
            textTransform: 'none',
             '&:hover': {
               transform: 'none', 
               backgroundColor: 'transparent !important',
               boxShadow: 'none',
               color: '#B82C3A !important',
             },
            /*  '&.Mui-active': {
                 backgroundColor: '#B82C3A1A', 
               }, */
        },    
    },

});

