import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { Box, Checkbox, ListItemText } from "@material-ui/core";
import { formStyles } from "../Styles/Formstyle";
import Buttons from "../Components/Shared/Buttons";
import Input from "../Components/Shared/Input";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ReportFormat from './../Pages/Accounts/ReportFormat';
import { formatedDate } from './../helper/helper';
import { tableClasses } from "@mui/material";

const AddPriceListForm = () => {
  const classes = formStyles();
  const [rows, setRows] = useState();
  const [account, setAccount] = useState();
  const [reportE1, setReportE1] = useState(false)
  const [sub, setSub] = useState();
  const [reportFormat, setReportFormat] = useState([]);

  const navigate = useNavigate();

  const TOKEN = localStorage.getItem("logintoken");

  // fetch category ie. Report group
  const fetchCategory = async () => {
    try {
      const data = await axios.get(`${API}/gettestcategory`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setRows(data.data.testCategory);
    } catch (e) {
      console.log(e);
    }
  };

  // fetch report format
  const getreportformat = async () => {
    try {
      const data = await axios.get(`${API}/getreportformat`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setReportFormat(data.data.reportFormat);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSubTest = async () => {
    try {
      const data = await axios.get(`${API}/gettestsubcategory`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setSub(data.data.subTestCategory);
    } catch (e) {
      console.log(e);
    }
  };

  // fetch Account of laboratories
  const fetchAccount = async () => {
    const data = await axios.get(`${API}/getaccountdetails`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setAccount(data?.data?.accountDetails);
  };

  useEffect(() => {
    fetchCategory();
    fetchSubTest();
    fetchAccount();
    getreportformat();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { data, status } = await axios.post(`${API}/addPriceList`, {
        ...values,
      }, {
        headers: { authtoken: `${TOKEN}` },
      });

      if (status === 200) {
        toast.success("Price Added SuccessFully")
        resetForm({
          values: {
            rate: 0,
            category: "",
            reportFormat: [],
            shortName: "",
            sampleCollection: "",
            priceListName: "",
            account: ""
          },
        })
        navigate("/add-price-list")
      }
    } catch (e) {
      toast.error("Price Added Error")
    }
  };

  const validationSchema = Yup.object({
    rate: Yup.number().required("Rate is required"),
    category: Yup.string().required("Category is required"),
    reportFormat: Yup.array().required("Group Format is required"),
    shortName: Yup.string().required("Shortname Format is required"),
    sampleCollection: Yup.string().required("Collection Format is required"),
    priceListName: Yup.string().required("Pricelist Format is required"),
    account: Yup.string().required("Account is required")
  });

  const formik = useFormik({
    initialValues: {
      rate: 0,
      category: "",
      reportFormat: [],
      shortName: "",
      sampleCollection: "",
      priceListName: "",
      account: ""
    },
    validationSchema,
    onSubmit: handleSubmit,
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    variant: "menu",
    PaperProps: {
      style: {
        height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const handleFormat = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1]) {
      formik.setFieldValue("reportFormat", typeof value === 'string' ? value.split(',') : value
      )
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.collectorForm}>
          <div className={classes.formheader}>
            <div className={classes.formnameHead}>
              <div className={classes.formh2}>Add New Price list </div>
              <div className={classes.littlehead}>
                {/* {rows?.length} */} You can create a new price list here
              </div>
            </div>
            <div>
              <Buttons
                className={classes.formButton}
                onClick={() => navigate("/add-price-list")}
              >
                &nbsp; Back to table
              </Buttons>
            </div>
          </div>

          <div>
            <div className={classes.formMain}>
              <FormControl>
                <div className={classes.formDiv1}>
                  <div className={classes.formDiv2}>
                    <div className={classes.formHeading}> New price list </div>
                    <div className={classes.formLable}>Select Account</div>
                    <Select
                      name="account"
                      value={formik.values.account}
                      onChange={formik.handleChange}
                      className={classes.selectInput}
                    >
                      {account?.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.errors.account && (
                      <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.account}</div>
                    )}
                    <div className={classes.formLable}>Rate</div>
                    <Input
                      name="rate"
                      type="number"
                      placeholder="Enter Rate"
                      className={classes.formInput}
                      value={formik.values.rate}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.rate && (
                      <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.rate}</div>
                    )}
                    <div className={classes.formLable}>Price List Name</div>
                    <Input
                      name="priceListName"
                      type="text"
                      placeholder="Enter Price List"
                      className={classes.formInput}
                      value={formik.values.priceListName}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.priceListName && (
                      <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.priceListName}</div>
                    )}
                  </div>
                  <div className={classes.formDiv31}>

                    <div className={classes.formLable}>Select Category</div>
                    <Select
                      name="category"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      className={classes.selectInput}
                      MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                      style={{ marginTop: '8px' }}
                    >
                      {rows?.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.errors.category && (
                      <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.category}</div>
                    )}
                    <div className={classes.formLable}>Short Name/Id</div>
                    <Input
                      name="shortName"
                      type="text"
                      placeholder="Enter Short Name/Id"
                      className={classes.formInput}
                      value={formik.values.shortName}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.shortName && (
                      <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.shortName}</div>
                    )}
                    <div className={classes.formLable}>Sample Collection</div>
                    <Input
                      name="sampleCollection"
                      type="text"
                      placeholder="Enter Sample Collection"
                      className={classes.formInput}
                      value={formik.values.sampleCollection}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.sampleCollection && (
                      <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.sampleCollection}</div>
                    )}
                  </div>

                </div>
                <div className={classes.formLable}>Select Group Format</div>
                <Select
                  name="reportFormat"
                  value={formik.values.reportFormat}
                  onChange={handleFormat}
                  className={`${classes.selectInput}`}
                  style={{ width: '750px' }}
                  multiple
                  open={reportE1}
                  onClose={() => setReportE1(false)}
                  onOpen={() => setReportE1(true)}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <span key={value}>
                          {
                            reportFormat.find((item) => item._id === value)
                              ?.reportName
                          }
                          {", "}
                        </span>
                      ))}
                    </div>
                  )}

                >
                  <div className={tableClasses.accessHeader} style={{ display: 'flex' }}>
                    <div className={classes.formspecification2}>Report Format Name</div>
                    <div className={`${classes.formspecification2}`} style={{ marginLeft: '225px' }}> Format ID</div>
                  </div>
                  <hr className={classes.headline} />
                  {reportFormat?.map((option) => (
                    <MenuItem key={option._id} value={option._id} className={tableClasses.accessHeader}>
                      <ListItemText primary={option.reportName} />
                      <div className={classes.firstp}>Format ID</div>
                      <Checkbox style={{ margin: '0 1rem' }} checked={formik.values.reportFormat.includes(option._id)} />
                    </MenuItem>
                  ))}
                  <hr className={classes.headline} />
                  <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                    <Buttons
                      onClick={() => setReportE1(false)}
                      className={classes.submitButton}
                    >
                      Proceed
                    </Buttons>
                  </div>
                </Select>
                {formik.errors.reportFormat && (
                  <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.reportFormat}</div>
                )}
                <div className={classes.formDiv4}>

                  <Buttons
                    onClick={() => navigate('/add-price-list')}
                    className={classes.cancelButton}>Cancel</Buttons>
                  <Buttons
                    className={classes.submitButton}
                    onClick={formik.handleSubmit}
                  >
                    Submit
                  </Buttons>
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPriceListForm;
