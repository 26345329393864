export const labSuffix = [
    {
        value: 20,
        label: 20
    },
    {
        value: 21,
        label: 21
    },
    {
        value: 22,
        label: 22
    },
    {
        value: 23,
        label: 23
    },
    {
        value: 24,
        label: 24
    }
]

export const gender = [
    {
        value: "male",
        label: "Male"
    },
    {
        value: "female",
        label: "Female"
    }
]

export const sampleFrom = [
    {
        value: "doctor",
        label: "Doctor"
    },
    {
        value: "lab",
        label: "Lab"
    }
]

export const sendRepoBy = [
    {
        value: "email",
        label: "Email"
    },
    {
        value: "phone",
        label: "Phone"
    }
]

export const sampleType = [
    {
        value: "blood",
        label: "Blood"
    },
    {
        value: "urine",
        label: "Urine"
    },
    {
        value: "blood and urine",
        label: "Blood and Urine"
    }
]

export const sampleStatus = [
    {
        value: "collected",
        label: "Collected"
    },
    {
        value: "received",
        label: "Received"
    }, {
        value: "pending",
        label: "Pending"
    }
]

export const paymentMode = [
    {
        value: "",
        label: "All"
    },
    {
        value: "online",
        label: "Online"
    }, {
        value: "cash",
        label: "Cash"
    }
]