import React from 'react'
import { Button } from '@material-ui/core';

const Buttons = ({ className, type, value, children, style, onClick, disabled, component }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={className}
      component={component}
      type={type}
      style={style}
      value={value}
      variant="contained"
    > {children} </Button>
  )
}

export default Buttons