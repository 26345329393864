import React, { useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { formStyles } from "../Styles/Formstyle";
import Buttons from "../Components/Shared/Buttons";
import Input from "../Components/Shared/Input";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Selects, { components, } from "react-select"
import Api from "../helper/api";
import Uploading from "../Components/Shared/Uploading";

const AddNewPriceList = () => {
    const classes = formStyles();
    const api = new Api();
    const [account, setAccount] = useState();
    const [priceList, setPriceList] = useState([]);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const TOKEN = localStorage.getItem("logintoken");

    const validationSchema = Yup.object({
        rate: Yup.number().required("Rate is required"),
        account: Yup.string().required("Account is required"),
        copyFrom: Yup.string().required("Copy from is required"),
        discount: Yup.number().required("Discount is required"),
    });

    const handleSubmit = async (values) => {
        try {
            setLoading(true)
            const { data, status } = await axios.post(`${API}/copyPriceList`, {
                ...values,
                copyAll: values.copyAll == "true" ? true : false
            }, {
                headers: { authtoken: `${TOKEN}` },
            });

            if (status === 200) {
                toast.success("PriceList Added SuccessFully")
                setLoading(false)
                navigate("/add-price-list")
            }
        } catch (e) {
            setLoading(false)
            toast.error("Error adding pricelist")
        }
    };

    const formik = useFormik({
        initialValues: {
            rate: 0,
            account: "",
            copyFrom: "",
            copyAll: "true",
            priceListsToCopy: [],
            discountBy: "PERCENT",
            discount: 0
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const { values, setFieldValue, setFieldTouched, handleChange, handleBlur } = formik


    // fetch pricelist ----------------------------------
    // --------------------------------------------------
    const fetchPriceList = async () => {
        try {
            const { data, status } = await axios.get(`${API}/getPriceList/${1}/1000000?account=${values.copyFrom}`, {
                headers: { authtoken: `${TOKEN}` },
            });

            if (status === 200) {
                setPriceList(data?.priceList);
            }
        } catch (err) {
            console.log(err);
        }
    };


    // fetch Account of laboratories
    const fetchAccount = async () => {
        const data = await axios.get(`${API}/getaccountdetails`, {
            headers: { authtoken: `${TOKEN}` },
        });
        setAccount(data?.data?.accountDetails);
    };

    useEffect(() => {
        fetchAccount();
    }, [])

    useEffect(() => {
        fetchPriceList();
        setFieldValue("priceListsToCopy", [])
    }, [values.copyFrom]);


    return (
        <>
            <div className={classes.root}>
                <div className={classes.collectorForm}>
                    <div className={classes.formheader}>
                        <div className={classes.formnameHead}>
                            <div className={classes.formh2}>Add New Price list </div>
                            <div className={classes.littlehead}>
                                {/* {rows?.length} */} You can create a new price list here
                            </div>
                        </div>
                        <div>
                            <Buttons
                                className={classes.formButton}
                                onClick={() => navigate("/add-price-list")}
                            >
                                &nbsp; Back to table
                            </Buttons>
                        </div>
                    </div>

                    <div>
                        <div className={classes.formMain}>
                            <div>
                                <div className={classes.formDiv1}>
                                    <div className={classes.formDiv2}>
                                        <div className={classes.formHeading}> New price list </div>
                                        <div className={classes.formLable}>Select Account</div>
                                        <Select
                                            name="account"
                                            value={formik.values.account}
                                            onChange={formik.handleChange}
                                            className={classes.selectInput}
                                            onBlur={handleBlur}
                                        >
                                            {account?.map((option) => (
                                                <MenuItem key={option._id} value={option._id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {formik.errors.account && (
                                            <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.account}</div>
                                        )}

                                        <div className={classes.formLable}>Copy From</div>
                                        <Select
                                            name="copyFrom"
                                            value={formik.values.copyFrom}
                                            onChange={formik.handleChange}
                                            className={classes.selectInput}
                                            onBlur={handleBlur}
                                        >
                                            {account?.map((option) => (
                                                <MenuItem key={option._id} value={option._id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {formik.errors.copyFrom && (
                                            <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.copyFrom}</div>
                                        )}
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={values.copyAll}
                                            name="copyAll"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value={true} control={<Radio sx={{
                                                color: "#B82C3A !important",
                                                '&.Mui-checked': {
                                                    color: "#B82C3A !important",
                                                },
                                            }} />} label="Copy All" />
                                            <FormControlLabel value={false} control={<Radio sx={{
                                                color: "#B82C3A !important",
                                                '&.Mui-checked': {
                                                    color: "#B82C3A !important",
                                                },
                                            }} />} label="Copy Selected" />
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className={classes.formLable}>Select Pricelist</div>

                                {/* <div style={{ width: "95%" }}> */}

                                <Selects
                                    options={[{
                                        label: "Name", options: priceList?.map((price) => {
                                            return {
                                                ...price,
                                                value: price?._id,
                                                label: price?.priceListName,
                                                priceListName: price?.priceListName,
                                                _id: price?._id,
                                                rate: price.rate,
                                                shortName: price.shortName,
                                            }
                                        })
                                    }]}
                                    isMulti
                                    isDisabled={values.copyAll == "true" ? true : false}
                                    value={[...values.priceListsToCopy?.map((price) => {
                                        return {
                                            ...price,
                                            value: price?._id,
                                            label: price?.priceListName,
                                            priceListName: price?.priceListName,
                                            _id: price?._id,
                                            rate: price?.rate,
                                            shortName: price?.shortName,
                                        }
                                    })]}
                                    components={{ Option, GroupHeading }}
                                    styles={{ container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }), control: (styles) => ({ ...styles, padding: "3px 0" }) }}
                                    onChange={(value) => setFieldValue("priceListsToCopy", value)}
                                />

                                {/* </div> */}

                                <div className={classes.formLable}>Discount By</div>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={values.discountBy}
                                    onChange={handleChange}
                                    name="discountBy"
                                >
                                    <FormControlLabel value={'PERCENT'} control={<Radio sx={{
                                        color: "#B82C3A !important",
                                        '&.Mui-checked': {
                                            color: "#B82C3A !important",
                                        },
                                    }} />} label="Percentage" />
                                    <FormControlLabel value={'FIXED'} control={<Radio sx={{
                                        color: "#B82C3A !important",
                                        '&.Mui-checked': {
                                            color: "#B82C3A !important",
                                        },
                                    }} />} label="Fixed" />
                                </RadioGroup>

                                <Input
                                    name="discount"
                                    type="number"
                                    placeholder="Enter Discount rate"
                                    className={classes.formInput}
                                    value={formik.values.discount}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.discount && (
                                    <div style={{ color: "red", fontSize: '13px', }}>{formik.errors.discount}</div>
                                )}
                                <div className={classes.formDiv4}>

                                    <Buttons
                                        onClick={() => navigate('/add-price-list')}
                                        className={classes.cancelButton}>Cancel</Buttons>
                                    <Buttons
                                        className={classes.submitButton}
                                        onClick={formik.handleSubmit}
                                        disabled={loading}
                                    >
                                        {
                                            loading ? <Uploading /> : "Submit"
                                        }
                                    </Buttons>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const Option = (props) => {
    return (
        <>
            <components.Option {...props}>
                <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
                    <p>{props.data.label}</p>
                    <p>{props.data?._id}</p>
                    <p>₹{props.data?.rate}</p>
                </div>
            </components.Option>
        </>
    )
}

const GroupHeading = (props) => {
    return (
        <components.GroupHeading {...props}>
            <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
                <p>Test Name</p>
                <p>Test Id</p>
                <p>Rate/Price</p>
            </div>
        </components.GroupHeading>
    )
}

export default AddNewPriceList;
