import React, { useEffect, useRef, useState } from 'react'
import Buttons from '../../Components/Shared/Buttons'
import { tableStyles } from '../../Styles/AddNewDocStyle';
import { useReactToPrint } from 'react-to-print';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import DatePicker from "react-datepicker"
import PopoverMenu from "../../Components/Shared/Popover";
import {
    DoctorSvg,
    DropdownSvg,
    EnteredSvg,
    FilterSvg,
    PendingSvg,
    ResetFilterSvg,
    VerifiedSvg,
} from "../../Components/Shared/UserSvg";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from 'react-router-dom';
import AssignCollector from '../../Components/Shared/CollectorAssign';
import Api from '../../helper/api';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInvestigationAPI, deletePatientAPI, deletePatientReceipt, fetchAccount, fetchData, fetchPatient, sendRepoByEmail, setActivePatient, setFilterData, setNewData, setPage, setSearch, setValues } from '../../redux/findPatientSlice';
import { formStyles } from '../../Styles/Formstyle';
import Uploading from '../../Components/Shared/Uploading';
import FindPatient from '../../Components/modal/FindPatient';
import Investigation from './Investigation';
import ReportLayout from '../../Components/Shared/ReportLayout';
import AddMacro from '../../Components/Shared/AddMacro';
import PaymentCollection from './paymentCollection';
import EditReceipt from './EditReceipt';
import UploadImg from './UploadImg';
import ShowReport from './ShowReport';
import { formatedDateInDigit } from '../../helper/helper';
import Barcode from 'react-barcode';
import AddnewBtn from './AddnewBtn';
import EditCollection from './EditCollection';
import Selects, { components, MenuListProps } from 'react-select';
import { ToWords } from "to-words"
import RenderRange from '../../Components/modal/RenderRange';

const header = [
    "SL No",
    "Time",
    "Samp No",
    "Lab No",
    "Patient's name",
    "Ref. Dr",
    "Samp F.",
    "Account",
    "Status",
];

const investigationHeader = [
    "Investigations", "SN", "Price", "Category", "Collection"
]

const reportsHeader = [
    "Sl no", "Report", "Operator", "Date & Time", "Report no.", "Email", "Status"
]

const receiptHeader = [
    "Recp. No", "Date", "Amount", "Mode", "Operator", "Printed"
]

const sampleHeader = [
    "Sample", "Date/Time", "Status", "Bar code"
]

const formatedDate = (newDate) => {
    const date = new Date(newDate);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
};

const formatTime = (newTime) => {
    const time = new Date(newTime);
    return time.toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" });
};

const PatientNew = () => {
    const tableclasses = tableStyles();
    const api = new Api();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        filterData, rows,
        pageInfo, activePatient,
        activeAccount, account,
        activeInvestigation, activeReport, activeSample, activeSampleNo,
        activeReceipt, newData,
        page, search, editReceipt, deleteReceipt,
        deletePatient, deleteInvestigation, deleteReport,
        rowsPerPage, payment, image,
        showReport, assignSample, editSample,
        layout, macro, addReceipt, loading,
        addInvestPopup, reFetch,
        investigations, assign } = useSelector(state => state.findPatient);

    const [id, setID] = useState();
    const [popup, setPopup] = useState(false);

    const reportContent = useRef()
    const billContent = useRef();
    const receiptContent = useRef();
    const patientReportContent = useRef();

    const generatePDF = useReactToPrint({
        content: () => reportContent.current,
        documentTitle: "PatientReport",
    })

    useEffect(() => {
        dispatch(fetchPatient());
    }, [newData, activeAccount, filterData.date, filterData.status, filterData.category, search]);

    useEffect(() => {
        dispatch(fetchAccount());
    }, [])

    const generateBill = useReactToPrint({
        content: () => billContent.current,
        documentTitle: "Patient Bill",
    })

    const generateReceipt = useReactToPrint({
        content: () => receiptContent.current,
        documentTitle: "Patient Receipt",
        onAfterPrint: async () => {
            try {
                const { data, status } = await api.updateReceiptPrintedCount("", { id: activePatient, paymentNo: activeReceipt })

                if (status === 200) {
                    dispatch(setValues({ name: "reFetch", value: !reFetch }));
                }
            } catch (err) {
                console.log(err);
            }
        }
    })

    const handlePatientKeyDown = (event) => {
        const findIndex = rows.findIndex(row => row._id === activePatient)
        if (event.keyCode === 38) { // Up arrow key
            const patient = rows[findIndex - 1];
            if (patient) {
                dispatch(setActivePatient({
                    patient: patient?._id,
                    sampleNo: patient?.sampleNo
                }));
            }
        } else if (event.keyCode === 40) { // Down arrow key
            const patient = rows[findIndex + 1];
            if (patient) {
                dispatch(setActivePatient({
                    patient: patient?._id,
                    sampleNo: patient?.sampleNo
                }));
            }
        }
    };

    const handleInvestigationKeyDown = (event) => {
        const findIndex = investigations.investigation.findIndex(row => row._id === activeInvestigation)
        if (event.keyCode === 38) { // Up arrow key
            const _id = investigations.investigation[findIndex - 1]?._id
            if (_id) {
                dispatch(setValues({ name: "activeInvestigation", value: _id }));
            }
        } else if (event.keyCode === 40) { // Down arrow key
            const _id = investigations.investigation[findIndex + 1]?._id
            if (_id) {
                dispatch(setValues({ name: "activeInvestigation", value: _id }));
            }
        }
    }

    const handleReportKeyDown = (event) => {
        const findIndex = investigations.investigation.findIndex(row => row._id === activeReport)
        if (event.keyCode === 38) { // Up arrow key
            const _id = investigations.investigation[findIndex - 1]?._id
            if (_id) {
                dispatch(setValues({ name: "activeReport", value: _id }));
            }
        } else if (event.keyCode === 40) { // Down arrow key
            const _id = investigations.investigation[findIndex + 1]?._id
            if (_id) {
                dispatch(setValues({ name: "activeReport", value: _id }));
            }
        }
    }

    const handleReceiptKeyDown = (event) => {
        const findIndex = investigations.payment.deposits.findIndex(row => row?.paymentNo === activeReceipt)
        if (event.keyCode === 38) { // Up arrow key
            const _id = investigations.payment.deposits[findIndex - 1]?.paymentNo
            if (_id) {
                dispatch(setValues({ name: "activeReceipt", value: _id }));
            }
        } else if (event.keyCode === 40) { // Down arrow key
            const _id = investigations.payment.deposits[findIndex + 1]?.paymentNo
            if (_id) {
                dispatch(setValues({ name: "activeReceipt", value: _id }));
            }
        }
    }

    const handleSampleKeyDown = (event) => {
        const findIndex = investigations.sampleCollection.findIndex(row => row._id === activeSample)
        if (event.keyCode === 38) { // Up arrow key
            const _id = investigations.sampleCollection[findIndex - 1]?._id
            if (_id) {
                dispatch(setValues({ name: "activeSample", value: _id }));
            }
        } else if (event.keyCode === 40) { // Down arrow key
            const _id = investigations.sampleCollection[findIndex + 1]?._id
            if (_id) {
                dispatch(setValues({ name: "activeSample", value: _id }));
            }
        }
    }

    const viewPatient = () => {
        if (activePatient) {
            navigate(`/appointment-view/${activePatient}`)
        } else {
            toast.error("Please select patient")
        }
    }

    const editPatient = () => {
        if (activePatient) {
            navigate(`/edit-patience/${activePatient}`)
        } else {
            toast.error("Please select patient")
        }
    }

    const setDeletePatient = () => {
        if (activePatient) {
            dispatch(setValues({ name: "deletePatient", value: true }))
        } else {
            toast.error("Please select patient")
        }
    }

    const setAssign = () => {
        if (activePatient) {
            dispatch(setValues({ name: "assign", value: true }))
        } else {
            toast.error("Please select patient")
        }
    }

    const addInvestigation = () => {
        if (activePatient) {
            dispatch(setValues({ name: "addInvestPopup", value: true }))
        } else {
            toast.error("Please select patient")
        }
    }

    const handleDeleteInvestigation = () => {
        if (activeInvestigation) {
            dispatch(setValues({ name: "deleteInvestigation", value: true }))
        } else {
            toast.error("Please select investigation to delete")
        }
    }

    const handleLayout = () => {
        if (activeReport) {
            dispatch(setValues({ name: "layout", value: true }))
        } else {
            toast.error("Please select report.")
        }
    }

    const handleReportEntry = () => {
        if (activeReport) {
            navigate(`/new-report-entry/${activePatient}/${activeReport}`)
        } else {
            toast.error("Please select report.")
        }
    }

    const handleReportVerify = () => {
        if (activeReport) {
            navigate(`/verify-report/${activePatient}/${activeReport}`)
        } else {
            toast.error("Please select report.")
        }
    }

    const handleReportPreview = () => {
        if (activeReport) {
            navigate(`/patience-report-preview/${activePatient}/${activeReport}`)
        } else {
            toast.error("Please select report.")
        }
    }

    const handleReportMacro = () => {
        if (activeReport) {
            dispatch(setValues({ name: "macro", value: true }))
        } else {
            toast.error("Please select report.")
        }
    }

    const generateReport = useReactToPrint({
        content: () => patientReportContent.current,
        documentTitle: "Patient report",
    })

    const handleReportPrint = async () => {
        const report = investigations.investigation.find((item) => item._id === activeReport)

        if (report.status.toUpperCase() === "VERIFIED") {
            generateReport()
        } else {
            toast.error("Report not available!")
        }
    }

    const handleReceiptAdd = () => {
        if (activeReport) {
            dispatch(setValues({ name: "addReceipt", value: true }))
        } else {
            toast.error("Please select Patient.")
        }
    }

    const handleReceiptEdit = () => {
        if (activeReceipt) {
            dispatch(setValues({ name: "editReceipt", value: true }))
        } else {
            toast.error("Please select receipt.")
        }
    }

    const handleReceiptImage = () => {
        if (activePatient) {
            dispatch(setValues({ name: "image", value: true }))
        } else {
            toast.error("Please select patient.")
        }
    }

    const handleAssignSample = () => {
        if (activePatient) {
            dispatch(setValues({ name: "assignSample", value: true }))
        } else {
            toast.error("Please select patient.")
        }
    }

    const handleEditSample = () => {
        if (activeSample) {
            dispatch(setValues({ name: "editSample", value: true }))
        } else {
            toast.error("Please select sample.")
        }
    }

    // get permission and print
    async function requestDevice(id) {
        try {
            const device = await navigator.usb.requestDevice({ filters: [] });
            testPrint(device, id);

        } catch (e) {
            console.error(e);
        }
    }

    async function testPrint(device, id) {
        const noLabels = prompt("Please enter no. of labels")

        // left margin, top mark
        if (noLabels !== null) {
            const cmds = [
                'SIZE 48 mm,25 mm',
                'CLS',
                `TEXT 30,10,"2",0,1,1,"${`${investigations?.firstName}, ${investigations?.age}Y/${investigations?.gender.slice(0, 1).toUpperCase()}`}"`,
                `BARCODE 30,40,"128",50,1,0,3,2, "${id}"`,
                `TEXT 30,150,"2",0,1,1, "${`${formatedDateInDigit(investigations?.createdAt)}, ${investigations?.labPrefix?.prefix}- ${investigations?.labSuffix}`}"`,
                `PRINT ${noLabels}`,
                'END',
            ];

            await device.open();
            await device.selectConfiguration(1);
            await device.claimInterface(0);
            await device.transferOut(
                device.configuration.interfaces[0].alternate.endpoints.find(obj => obj.direction === 'out').endpointNumber,
                new Uint8Array(
                    new TextEncoder().encode(cmds.join('\r\n'))
                ),
            );
            await device.close();
        } else {
            console.log("User cancelled the prompt.");
        }
    }

    const handlePrintBarcode = (type) => {
        if (type == "PATIENT") {
            if (activePatient) {
                requestDevice(activePatient);
            } else {
                toast.error("Please select patient.")
            }
        } else if (type == "SAMPLE") {
            if (activeSampleNo) {
                requestDevice(activeSampleNo);
            } else {
                toast.error("Please select sample.")
            }
        }
    }

    const handleReceiptDelete = () => {
        if (activeReceipt) {
            dispatch(setValues({ name: "deleteReceipt", value: true }))
        }
        else {
            toast.error("Please select receipt to delete")
        }
    }

    const handleReportByEmail = () => {
        if (activeReport) {
            dispatch(sendRepoByEmail())
        } else {
            toast.error("Please select report to send")
        }
    }

    return (
        <div style={{ background: "#f5f5f5", width: "100%", padding: 15 }}>
            <div style={{ background: "#fff", padding: 15, borderRadius: 10, display: 'flex', flexDirection: "column", gridGap: 20 }}>
                {
                    deletePatient &&
                    <DeleteItem handleClose={() => { dispatch(setValues({ name: "deletePatient", value: false })) }} handleDelete={() => { dispatch(deletePatientAPI()) }} />
                }
                {
                    deleteReceipt &&
                    <DeleteItem handleClose={() => { dispatch(setValues({ name: "deleteReceipt", value: false })) }} handleDelete={() => { dispatch(deletePatientReceipt()) }} />
                }
                {
                    deleteInvestigation &&
                    <DeleteItem handleClose={() => { dispatch(setValues({ name: "deleteInvestigation", value: false })) }} handleDelete={() => { dispatch(deleteInvestigationAPI()) }} />
                }
                {
                    assign &&
                    <AssignCollector
                        assign={assign}
                        setAssign={(value) => dispatch(setValues({ name: "assign", value }))}
                        patienceId={activePatient}
                    />
                }
                {
                    loading &&
                    <Uploading />
                }
                {popup ? <FindPatient setPopup={setPopup} /> : ""}
                {
                    addInvestPopup &&
                    <Investigation id={activePatient} reFetch={reFetch} setReFetch={(value) => dispatch(setValues({ name: "reFetch", value }))} setPopupForm={(value) => dispatch(setValues({ name: "addInvestPopup", value }))} />
                }
                {
                    layout &&
                    <ReportLayout
                        handleClose={() => dispatch(setValues({ name: "layout", value: false }))}
                        layoutId={activeReport}
                        id={activePatient}
                    />
                }
                {
                    macro &&
                    <AddMacro
                        handleClose={() => dispatch(setValues({ name: "macro", value: false }))}
                        macroId={activeReport}
                        id={activePatient}
                    />
                }

                {addReceipt && (
                    <PaymentCollection
                        payment={payment}
                        setPayment={(value) => dispatch(setValues({ name: "addReceipt", value }))}
                        setReFetch={(value) => dispatch(setValues({ name: "reFetch", value }))}
                        reFetch={reFetch}
                        id={activePatient}
                    />
                )}

                {editReceipt && (
                    <EditReceipt
                        setReceipt={(value) => dispatch(setValues({ name: "editReceipt", value }))}
                        setReFetch={(value) => dispatch(setValues({ name: "reFetch", value }))}
                        reFetch={reFetch}
                        paymentToEdit={activeReceipt}
                        rows={investigations}
                        id={activePatient}
                    />
                )}
                {image &&
                    <UploadImg
                        setReFetch={(value) => dispatch(setValues({ name: "reFetch", value }))}
                        reFetch={reFetch}
                        id={activePatient}
                        setUpload={(value) => dispatch(setValues({ name: "image", value }))}
                        setShowReport={(value) => dispatch(setValues({ name: "showReport", value }))} />
                }

                {showReport.show ? <ShowReport handleClose={() => dispatch(setValues({ name: "showReport", value: { show: false, src: "" } }))} showReport={showReport} /> : ""}
                {assignSample
                    && (
                        <AddnewBtn
                            setPopup={(value) => dispatch(setValues({ name: "assignSample", value }))}
                            id={activePatient}
                            setReFetch={(value) => dispatch(setValues({ name: "reFetch", value }))}
                            reFetch={reFetch}
                        />
                    )
                }

                {editSample && (
                    <EditCollection
                        setReFetch={(value) => dispatch(setValues({ name: "reFetch", value }))}
                        reFetch={reFetch}
                        id={activePatient}
                        setEditCollection={(value) => dispatch(setValues({ name: "editSample", value }))}
                        collectionToEdit={activeSample}
                        setCollectionToEdit={() => { }}
                        rows={investigations}
                    />
                )}

                < div >
                    <h5 style={{ color: "rgba(33, 33, 33, 1)", fontSize: 18, fontWeight: 500 }}>Patients</h5>
                    <p style={{ color: "rgba(181, 181, 195, 1)", fontSize: 16 }}>10 pending patient reports</p>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", position: "relative", zIndex: 3, flexWrap: "wrap", width: "60%", gridRowGap: 5 }}>
                        <DatePicker
                            showIcon
                            selected={filterData.date}
                            onChange={(date) => dispatch(setFilterData({ name: "date", value: date }))}
                            className='date-picker'
                            dateFormat="dd/MM/yyyy"
                        />
                        <Selects
                            options={[{ value: "", label: "All" }, ...account?.map((account) => {
                                return {
                                    value: account?._id,
                                    label: account?.prefix
                                }
                            })]}
                            value={
                                activeAccount ?
                                    {
                                        value: account?.find((account) => account._id === activeAccount)?._id,
                                        label: account?.find((account) => account._id === activeAccount)?.prefix
                                    }
                                    : ""
                            }
                            placeholder="All"
                            styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginLeft: "8px", width: 90, zIndex: 10 }) }}
                            onChange={(value) => dispatch(setValues({ name: "activeAccount", value: value.value }))}
                        />
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={() => navigate("/add-patience")}
                        >
                            Add
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={viewPatient}
                        >
                            View
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={editPatient}
                        >
                            Edit
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={setDeletePatient}
                        >
                            Delete
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={setAssign}
                        >
                            Assign
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={generatePDF}
                        >
                            Print
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={() => setPopup(true)}
                        >
                            Find
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={() => handlePrintBarcode("PATIENT")}
                        >
                            Barcode
                        </Buttons>
                        <div className={tableclasses.findPatience}>
                            <TextField
                                value={search}
                                onChange={(e) => dispatch(setSearch(e.target.value))}
                                placeholder="Enter Patient ID/Phone no/Patient name"
                                className={tableclasses.searchField}
                            />
                        </div>
                    </div>
                    <div style={{ width: "40%", display: "flex", justifyContent: "flex-end" }}>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={addInvestigation}
                        >
                            Add
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={handleDeleteInvestigation}
                        >
                            Delete
                        </Buttons>
                        <Buttons
                            className={
                                tableclasses.printButton
                            }
                            onClick={generateBill}
                        >
                            Bill
                        </Buttons>
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", gap: "20px" }}>
                    <div onKeyDown={handlePatientKeyDown} tabIndex="0" style={{ width: "60%", height: "420px", overflow: "scroll", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", outline: "none", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 10 }}>
                        <>
                            <Table stickyHeader className={{ ...tableclasses.table, width: "100%" }} ref={reportContent} style={{ minWidth: 800 }}>
                                <TableHead className={tableclasses.tableHead}>
                                    <TableRow>
                                        {header.map((header) => {
                                            return (
                                                <TableCell className={tableclasses.customHeadName} style={{ padding: "5px 10px" }}>
                                                    {header}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows.length !== 0
                                            ? rows?.map((row, index) => {
                                                let status = "pending";

                                                let pending = 0;
                                                let verified = 0;
                                                let entered = 0;
                                                if (row.investigation?.length > 0) {
                                                    for (let i = 0; i < row?.investigation?.length; i++) {
                                                        if (row.investigation[i]?.status.toUpperCase() === "PENDING") {
                                                            pending++
                                                        } else if (row.investigation[i]?.status.toUpperCase() === "VERIFIED") {
                                                            verified++
                                                        } else if (row.investigation[i]?.status.toUpperCase() === "ENTERED") {
                                                            entered++
                                                        } else if (row.investigation[i]?.status.toUpperCase() === "PRINTED") {
                                                            verified++
                                                        }
                                                    }

                                                    if (row.investigation?.length === verified) {
                                                        status = "Completed"
                                                    } else if (entered > 0 || verified > 0) {
                                                        status = "Entered"
                                                    } else {
                                                        status = "Pending"
                                                    }
                                                }

                                                return (
                                                    <TableRow style={{ background: activePatient === row._id ? "rgba(211, 223, 251, 1)" : "transparent", cursor: "pointer" }} key={row.id} onClick={() => dispatch(setActivePatient({ patient: row._id, sampleNo: row?.sampleNo }))}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={tableclasses.customTableCell}
                                                            style={{ display: "flex" }}
                                                        >
                                                            <div>{(page - 1) * 10 + index + 1}</div>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={tableclasses.customTableCell}
                                                        >
                                                            <div>
                                                                {formatTime(row.createdAt)}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={tableclasses.customTableCell}
                                                        >
                                                            <div style={{ color: "rgba(61, 119, 247, 1)" }}>
                                                                {row?.sampleNo}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={tableclasses.customTableCell}>
                                                            <div>
                                                                <div>{`${row?.labPrefix?.prefix}-${row?.labNumber}-${row?.labSuffix}`}</div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={tableclasses.customTableCell}>
                                                            <p>
                                                                {row.firstName} {row.lastName}
                                                            </p>
                                                        </TableCell>
                                                        <TableCell className={tableclasses.customTableCell}>
                                                            <div>{row?.refferedBy?.name || "Self"}</div>
                                                        </TableCell>
                                                        <TableCell className={tableclasses.customTableCell}>
                                                            <div>{row.sampleFrom || "LAB"}</div>
                                                        </TableCell>
                                                        <TableCell className={tableclasses.customTableCell}>
                                                            <div>{row?.labPrefix?.prefix}</div>
                                                        </TableCell>
                                                        <TableCell className={tableclasses.customTableCell}>
                                                            <div
                                                                style={{
                                                                    text: "center",
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <div style={{ display: "flex" }}>
                                                                    {
                                                                        status === "Pending"
                                                                            ? <PendingSvg />
                                                                            : status === "Entered" ? <EnteredSvg />
                                                                                : <VerifiedSvg />
                                                                    }
                                                                    <span
                                                                        style={{
                                                                            color: status === "Pending"
                                                                                ? "#B82C3A"
                                                                                : status === "Entered" ? "#D48A48"
                                                                                    : "#60BB47",
                                                                            marginLeft: 6,
                                                                            text: "center",
                                                                        }}
                                                                    >
                                                                        {status}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        {assign && id === row._id && (
                                                            <AssignCollector
                                                                assign={assign}
                                                                setAssign={(value) => dispatch(setValues({ name: "assing", value }))}
                                                                patienceId={activePatient}
                                                            />
                                                        )}
                                                    </TableRow>
                                                )
                                            })
                                            : <h1 style={{ textAlign: "center", fontSize: "16px", color: "#000", fontWeight: 500 }}>No Data to show</h1>
                                    }
                                </TableBody>
                            </Table>
                        </>
                    </div>
                    <div style={{ width: "40%", display: "flex", flexDirection: "column", gap: "20px" }}>
                        <div onKeyDown={handleInvestigationKeyDown} tabIndex="0" style={{ width: "100%", height: "160px", overflow: "scroll", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", outline: "none", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 10 }}>
                            <InvestigationComp tableclasses={tableclasses} />
                        </div>
                        <div style={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
                            <div style={{ width: "70%" }}>
                                <div onKeyDown={handleSampleKeyDown} tabIndex="0" style={{ width: "100%", height: "160px", overflow: "scroll", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", outline: "none", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 10 }}>
                                    <SampleCollector tableclasses={tableclasses} />
                                </div>
                                <div style={{ marginTop: 15 }}>
                                    <Buttons
                                        className={
                                            tableclasses.printButton
                                        }
                                        onClick={handleAssignSample}
                                    >
                                        Assign
                                    </Buttons>
                                    <Buttons
                                        className={
                                            tableclasses.printButton
                                        }
                                        onClick={handleEditSample}
                                    >
                                        Edit
                                    </Buttons>
                                    <Buttons
                                        className={
                                            tableclasses.printButton
                                        }
                                        onClick={() => handlePrintBarcode("SAMPLE")}
                                    >
                                        Barcode
                                    </Buttons>
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: 15 }}>
                                <div style={{ display: "flex", flexDirection: "column", textAlign: "right", gap: 5, verticalAlign: "center" }}>
                                    <p style={{ height: "32px", marginTop: 0, verticalAlign: "center", color: "rgba(7, 21, 70, 1)", fontWeight: 500, padding: "5px 0 " }}>Total:</p>
                                    <p style={{ height: "32px", marginTop: 0, verticalAlign: "center", color: "rgba(7, 21, 70, 1)", fontWeight: 500, padding: "5px 0 " }}>Disc:</p>
                                    <p style={{ height: "32px", marginTop: 0, verticalAlign: "center", color: "rgba(7, 21, 70, 1)", fontWeight: 500, padding: "5px 0 " }}>Net:</p>
                                    <p style={{ height: "32px", marginTop: 0, verticalAlign: "center", color: "rgba(7, 21, 70, 1)", fontWeight: 500, padding: "5px 0 " }}>Paid:</p>
                                    <p style={{ height: "32px", marginTop: 0, verticalAlign: "center", color: "rgba(7, 21, 70, 1)", fontWeight: 500, padding: "5px 0 " }}>Due:</p>
                                    <p style={{ height: "32px", marginTop: 0, verticalAlign: "center", color: "rgba(7, 21, 70, 1)", fontWeight: 500, padding: "5px 0 " }}>Deduct amount:</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                    <input type="text" value={payment.totalAmount} style={{ height: "32px", color: "rgba(7, 21, 70, 1)", fontWeight: 500, width: "60px", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 8, padding: 5 }} />
                                    <input type="text" style={{ height: "32px", color: "rgba(7, 21, 70, 1)", fontWeight: 500, width: "60px", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 8, padding: "5px 5px" }} value={payment.totalDiscount} />
                                    <input type="text" style={{ height: "32px", color: "rgba(7, 21, 70, 1)", fontWeight: 500, width: "60px", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 8, padding: "5px 5px" }} value={payment.netAmount} />
                                    <input type="text" style={{ height: "32px", color: "rgba(7, 21, 70, 1)", fontWeight: 500, width: "60px", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 8, padding: "5px 5px" }} value={payment.totalPaidAmount} />
                                    <input type="text" style={{ height: "32px", color: "rgba(7, 21, 70, 1)", fontWeight: 500, width: "60px", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 8, padding: "5px 5px", color: payment.totalDueAmount > 0 ? "red" : "black" }} value={payment.totalDueAmount} />
                                    <input type="text" style={{ height: "32px", color: "rgba(7, 21, 70, 1)", fontWeight: 500, width: "60px", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 8, padding: "5px 5px" }} value={0} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", gap: "20px" }}>
                    <div style={{ width: "60%" }}>
                        <div style={{ display: "flex", flexWrap: "wrap", gridRowGap: 5 }}>
                            <h5 style={{ color: "rgba(33, 33, 33, 1)", fontSize: 18, fontWeight: 500, marginRight: 10 }}>Reports</h5>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={addInvestigation}
                            >
                                Add
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReportEntry}
                            >
                                Edit
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleLayout}
                            >
                                Layout
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReportEntry}
                            >
                                Entry
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReportVerify}
                            >
                                Verify
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReportPreview}
                            >
                                Preview
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReportMacro}
                            >
                                Macros
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReportPrint}
                            >
                                Print
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={generatePDF}
                            >
                                SMS
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReportByEmail}
                            >
                                Email
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReceiptImage}
                            >
                                Image
                            </Buttons>
                        </div>
                        <div onKeyDown={handleReportKeyDown} tabIndex="0" style={{ width: "100%", height: "160px", overflow: "scroll", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", outline: "none", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 10, marginTop: 15 }}>
                            <Reports tableclasses={tableclasses} />
                        </div>
                    </div>
                    <div style={{ width: "40%" }}>
                        <div style={{ display: "flex", flexWrap: "wrap", gridRowGap: 5 }}>
                            <h5 style={{ color: "rgba(33, 33, 33, 1)", fontSize: 18, fontWeight: 500, marginRight: 10 }}>Receipt</h5>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReceiptAdd}
                            >
                                Add
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReceiptEdit}
                            >
                                Edit
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={handleReceiptDelete}
                            >
                                Delete
                            </Buttons>
                            <Buttons
                                className={
                                    tableclasses.printButton
                                }
                                onClick={generateReceipt}
                            >
                                Print
                            </Buttons>
                        </div>
                        <div onKeyDown={handleReceiptKeyDown} tabIndex="0" style={{ width: "100%", height: "160px", overflow: "scroll", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", outline: "none", border: "1px solid rgba(201, 201, 201, 1)", borderRadius: 10, marginTop: 15 }}>
                            <Receipt tableclasses={tableclasses} />
                        </div>
                    </div>
                </div>
            </div>
            <Bill billContent={billContent} tableclasses={tableclasses} />
            <ReceiptBill receiptContent={receiptContent} tableclasses={tableclasses} />
            <PatientReport patientReportContent={patientReportContent} />
        </div >
    )
}

const InvestigationComp = ({ tableclasses }) => {
    const dispatch = useDispatch();
    const { activePatient, reFetch, investigations, activeInvestigation } = useSelector(state => state.findPatient);

    useEffect(() => {
        dispatch(fetchData());
    }, [activePatient, reFetch])
    return (
        <>
            <Table stickyHeader className={{ ...tableclasses.table, width: "100%" }} style={{ minWidth: 400 }}>
                <TableHead className={tableclasses.tableHead}>
                    <TableRow>
                        {investigationHeader.map((header) => {
                            return (
                                <TableCell className={tableclasses.customHeadName} style={{ padding: "5px 10px" }}>
                                    {header}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        investigations.investigation?.length !== 0
                            ? investigations.investigation?.map((row, index) => {
                                return (
                                    <TableRow onClick={() => dispatch(setValues({ name: "activeInvestigation", value: row._id }))} style={{ background: activeInvestigation === row._id ? "rgba(211, 223, 251, 1)" : "transparent", cursor: "pointer" }} key={row._id}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                            style={{ display: "flex" }}
                                        >
                                            <div>{index + 1}</div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                        >
                                            <div>
                                                {row?.shortName ? row?.shortName : "NA"}
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                <div>{row.rate}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                <div>{row?.category?.name}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            : <h1 style={{ textAlign: "center", fontSize: "16px", color: "#000", fontWeight: 500 }}>No Data to show</h1>
                    }
                </TableBody>
            </Table>
        </>
    )
}

const SampleCollector = ({ tableclasses }) => {
    const dispatch = useDispatch();
    const { investigations, activeSample } = useSelector(state => state.findPatient);

    return (
        <>
            <Table stickyHeader className={{ ...tableclasses.table, width: "400px !important" }} style={{ minWidth: 400 }}>
                <TableHead className={tableclasses.tableHead}>
                    <TableRow>
                        {sampleHeader.map((header) => {
                            return (
                                <TableCell className={tableclasses.customHeadName} style={{ padding: "5px 10px" }}>
                                    {header}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        investigations.sampleCollection?.length !== 0
                            ? investigations.sampleCollection?.map((row, index) => {
                                return (
                                    <TableRow onClick={() => dispatch(setValues({ name: "activeSample", value: row._id }))} style={{ background: activeSample === row._id ? "rgba(211, 223, 251, 1)" : "transparent", cursor: "pointer" }} key={row._id}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                            style={{ display: "flex" }}
                                        >
                                            <div>{row?.sampleType}</div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                        >
                                            <div>
                                                {
                                                    row?.date
                                                        ? `${formatedDateInDigit(row?.date)}, ${formatTime(
                                                            row?.date
                                                        )}`
                                                        : ""
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                <div>{row?.sampleStatus}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                {
                                                    row?.sampleNo ? <Barcode displayValue={false} width={1} height={30} fontSize={14} value={row?.sampleNo} margin={0} background='transparent' /> : ""
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            : <h1 style={{ textAlign: "center", fontSize: "16px", color: "#000", fontWeight: 500 }}>No Data to show</h1>
                    }
                </TableBody>
            </Table>
        </>
    )
}

const Reports = ({ tableclasses }) => {
    const dispatch = useDispatch();
    const { activePatient, investigations, activeInvestigation, activeReport } = useSelector(state => state.findPatient);
    return (
        <>
            <Table stickyHeader className={{ ...tableclasses.table, width: "100%" }} style={{ minWidth: 600 }}>
                <TableHead className={tableclasses.tableHead}>
                    <TableRow>
                        {reportsHeader.map((header) => {
                            return (
                                <TableCell className={tableclasses.customHeadName} style={{ padding: "5px 10px" }}>
                                    {header}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        investigations.investigation?.length !== 0
                            ? investigations.investigation?.map((row, index) => {
                                return (
                                    <TableRow onClick={() => dispatch(setValues({ name: "activeReport", value: row._id }))} style={{ background: activeReport === row._id ? "rgba(211, 223, 251, 1)" : "transparent", cursor: "pointer" }} key={row._id}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                            style={{ display: "flex" }}
                                        >
                                            <div>{index + 1}</div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                        >
                                            <div>
                                                {row?.shortName ? row?.shortName : "NA"}
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                <div>{row?.assignToUser?.name}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                <div>{row?.date && formatedDate(row.date)}, {row?.date && formatTime(row.date)} </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                {row._id}
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div>
                                                {
                                                    row?.isEmailed ? "Yes" : "No"
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell}>
                                            <div
                                                style={{
                                                    text: "center",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ display: "flex" }}>
                                                    {
                                                        row?.status.toUpperCase() === "PENDING"
                                                            ? <PendingSvg />
                                                            : row?.status.toUpperCase() === "ENTERED" ? <EnteredSvg />
                                                                : <VerifiedSvg />
                                                    }
                                                    <span
                                                        style={{
                                                            color: row?.status.toUpperCase() === "PENDING"
                                                                ? "#B82C3A"
                                                                : row?.status.toUpperCase() === "ENTERED" ? "#D48A48"
                                                                    : "#60BB47",
                                                            marginLeft: 6,
                                                            text: "center",
                                                        }}
                                                    >
                                                        {row?.status}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            : <h1 style={{ textAlign: "center", fontSize: "16px", color: "#000", fontWeight: 500 }}>No Data to show</h1>
                    }
                </TableBody>
            </Table>
        </>
    )
}

const Receipt = ({ tableclasses }) => {
    const dispatch = useDispatch();
    const { activePatient, investigations, activeInvestigation, activeReceipt } = useSelector(state => state.findPatient);

    return (
        <>
            <Table stickyHeader className={{ ...tableclasses.table, width: "100%" }} style={{ minWidth: 500 }}>
                <TableHead className={tableclasses.tableHead}>
                    <TableRow>
                        {receiptHeader.map((header) => {
                            return (
                                <TableCell className={tableclasses.customHeadName} style={{ padding: "5px 10px" }}>
                                    {header}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        investigations.payment.deposits?.map((payment, index) => {
                            return (
                                <TableRow onClick={() => dispatch(setValues({ name: "activeReceipt", value: payment?.paymentNo }))} style={{ background: activeReceipt === payment?.paymentNo ? "rgba(211, 223, 251, 1)" : "transparent", cursor: "pointer" }} key={payment?.paymentNo}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        className={tableclasses.customTableCell}
                                        style={{ display: "flex" }}
                                    >
                                        <div>{payment?.paymentNo ? payment?.paymentNo : "NA"}</div>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        className={tableclasses.customTableCell}
                                    >
                                        <div>
                                            {formatedDate(payment?.transactionDate)}
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        className={tableclasses.customTableCell}
                                    >
                                        <div>
                                            {payment?.paidAmount}
                                        </div>
                                    </TableCell>
                                    <TableCell className={tableclasses.customTableCell}>
                                        <div>
                                            <div>{payment?.mode ? payment?.mode : "NA"}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className={tableclasses.customTableCell}>
                                        <div>
                                            Abhi
                                        </div>
                                    </TableCell>
                                    <TableCell className={tableclasses.customTableCell}>
                                        <div>
                                            {payment?.printed || 0}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </>
    )
}

const DeleteItem = ({ handleClose, handleDelete }) => {
    const tableclasses = tableStyles();
    const classes = formStyles();
    const { isDeleting } = useSelector(state => state.findPatient);

    return (
        <div className={tableclasses.outBackground}>
            <div className={tableclasses.sampleCollection} style={{ marginTop: '198px', width: '40%' }}>
                <div
                    className={tableclasses.exitbutton}
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        style={{ position: 'relative', top: '23px', left: '92.5%' }}
                    >
                        <path
                            d="M1 17L17 1M1 1L17 17"
                            stroke="black"
                            stroke-opacity="0.7"
                            stroke-width="1.88067"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div className={tableclasses.headSample}>Delete Confirmation</div>
                <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '0.5px #9c9c9c solid', marginBottom: '20px', paddingBottom: '30px' }}>
                    <div className={classes.formLable}
                    >Are you sure , you want to delete this?</div>
                </div>
                <div className={tableclasses.sampleIn}>
                    <Buttons
                        className={classes.cancelButton}
                        onClick={handleClose}
                    >
                        Cancel
                    </Buttons>
                    <span className="px-1"></span>
                    <Buttons
                        className={classes.submitButton}
                        onClick={handleDelete}
                        disabled={isDeleting}
                    >
                        {
                            isDeleting ? <CircularProgress style={{ color: "#fff" }} /> : "Delete"
                        }
                    </Buttons>
                </div>
            </div>

        </div>

    )
}

const Bill = ({ billContent, tableclasses }) => {
    const { investigations, payment } = useSelector(state => state.findPatient)
    const toWord = new ToWords();
    return (
        <div style={{ display: "none" }}>
            <main className="px-20 " id="report-content" ref={billContent} style={{ paddingTop: "18px", marginBottom: "18px", color: "#000" }}>
                <div style={{ color: "#000" }}>
                    <h4 style={{ margin: "auto", textAlign: "center", color: "black", fontWeight: 700, fontSize: "16px", width: "fit-content", borderBottom: "1px solid #000", lineHeight: "24px" }}>Nagalikar Diagnostic Scanning Centre</h4>
                    <p style={{ textAlign: "center", color: "#000", fontWeight: 400, lineHeight: "16px", padding: "5px 0" }}>
                        Main:151, super market Near Hanuman temple, Kalaburagi 243321 <br />
                        Branch: Pastapur Hospital building, Bus Stand Road, jewargi Cross <br />
                        Near Sangameshwar Hospital Gulbarga Ph-08472-256990 <br />
                        Branch: One way station road, Near Gurukul college ph:08472-246990
                    </p>
                </div>
                <div className="bg-[#000] h-1 w-full my-1"></div>
                <ul className="flex justify-between max-w-2xl mx-auto text-[#000] font-regular mb-2">
                    <div className="flex-1">
                        <li
                            className=" flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Patient name</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{investigations.firstName} {investigations.lastName}</div>
                        </li>

                        <li
                            className=" flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Place</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{""}</div>
                        </li>

                        <li
                            className=" flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Reffered by</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{investigations?.refferedBy?.name || "Self"}</div>
                        </li>
                    </div>
                    <div className="flex-1">
                        <li
                            className=" flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Bill No/Sample No</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{`${investigations?._id}`}</div>
                        </li>

                        <li
                            className="flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Date</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{formatedDateInDigit(new Date())}</div>
                        </li>
                        <li
                            className="flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Ref. No</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{investigations.labPrefix?.prefix}-{investigations._id}-{investigations?.labSuffix}</div>
                        </li>
                    </div>
                </ul>

                <Table stickyHeader className={{ ...tableclasses.table, width: "100%" }} style={{ borderTop: "1px solid black", borderLeft: "1px solid black", borderBottom: "1px solid black" }}>
                    <TableHead className={tableclasses.tableHead} style={{ background: "transparent", height: "20px" }}>
                        <TableRow>
                            <TableCell className={tableclasses.customHeadName} style={{ padding: "5px 10px", background: "transparent", borderBottom: "1px solid black", borderRight: "1px solid black", color: "#000", fontWeight: "500" }}>
                                Srl.
                            </TableCell>
                            <TableCell className={tableclasses.customHeadName} style={{ padding: "5px 10px", background: "transparent", borderBottom: "1px solid black", borderRight: "1px solid black", color: "#000", fontWeight: "500" }}>
                                Name of Test / Service
                            </TableCell>
                            <TableCell className={tableclasses.customHeadName} style={{ padding: "5px 10px", background: "transparent", borderBottom: "1px solid black", borderRight: "1px solid black", color: "#000", fontWeight: "500" }}>
                                Amount
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            investigations.investigation?.map((row, index) => {
                                return (
                                    <TableRow key={row._id}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                            style={{ display: "flex", borderRight: "1px solid black", padding: "2px 10px", color: "#000", fontWeight: 500 }}
                                        >
                                            <div>{index + 1}</div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={tableclasses.customTableCell}
                                            style={{ borderRight: "1px solid black", padding: "2px 10px", color: "#000", fontWeight: 500 }}
                                        >
                                            <div>
                                                {row?.shortName ? row?.shortName : "NA"}
                                            </div>
                                        </TableCell>
                                        <TableCell className={tableclasses.customTableCell} style={{ borderRight: "1px solid black", padding: "2px 10px", color: "#000", fontWeight: 500 }}>
                                            <div>
                                                <div>{row.rate}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }

                        <TableRow>
                            <TableCell style={{ borderTop: "1px solid black" }}></TableCell>
                            <TableCell style={{ borderTop: "1px solid black", borderRight: "1px solid black" }}>
                                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                    <h4 style={{ lineHeight: "24px", fontWeight: 400 }}>{toWord.convert(payment.netAmount, { currency: true, ignoreDecimal: true })}</h4>
                                    <div>
                                        <h5 style={{ fontWeight: 400 }}>Total Amount:</h5>
                                        <h5 style={{ fontWeight: 400 }}>Paid Amount:</h5>
                                        <h5 style={{ fontWeight: 400 }}>Due Amount:</h5>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell style={{ borderTop: "1px solid black", borderRight: "1px solid black" }}>
                                <div>
                                    <h5 style={{ fontWeight: 400 }}>{payment.netAmount}</h5>
                                    <h5 style={{ fontWeight: 400 }}>{payment.totalPaidAmount}</h5>
                                    <h5 style={{ fontWeight: 400 }}>{payment.totalDueAmount}</h5>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody >
                </Table >
            </main >
        </div>
    )
}

const ReceiptBill = ({ receiptContent, tableclasses }) => {
    const { investigations, payment, activeReceipt, activePatient } = useSelector(state => state.findPatient)

    const receipt = investigations.payment.deposits.find((row) => row?.paymentNo === activeReceipt)
    const toWord = new ToWords();
    return (
        <div style={{ display: "none" }}>
            <main className=" px-20 " id="report-content" ref={receiptContent} style={{ paddingTop: "18px", marginBottom: "18px" }}>
                <div style={{ color: "#000" }}>
                    <h4 style={{ margin: "auto", textAlign: "center", color: "black", fontWeight: 700, fontSize: "16px", width: "fit-content", borderBottom: "1px solid #000", lineHeight: "24px" }}>Nagalikar Diagnostic Scanning Centre</h4>
                    <p style={{ textAlign: "center", color: "#000", fontWeight: 400, lineHeight: "16px", padding: "5px 0" }}>
                        Main:151, super market Near Hanuman temple, Kalaburagi 243321 <br />
                        Branch: Pastapur Hospital building, Bus Stand Road, jewargi Cross <br />
                        Near Sangameshwar Hospital Gulbarga Ph-08472-256990 <br />
                        Branch: One way station road, Near Gurukul college ph:08472-246990
                    </p>
                    <div style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        <h4 style={{ textAlign: "center", fontWeight: 500, padding: 5, color: "black", lineHeight: "24px" }}>RECEIPT</h4>
                    </div>
                    <div style={{ fontWeight: 400, width: "100%", display: "flex", justifyContent: "space-between", color: "#000" }}>
                        <h4 style={{ lineHeight: "24px" }}>Receipt No: <span style={{ color: "rgba(0, 0, 0)", fontWeight: 700 }}>{activeReceipt}</span></h4>
                        <h4 style={{ lineHeight: "24px" }}>Sample No: <span style={{ color: "rgba(0, 0, 0)" }}>{activePatient}</span></h4>
                        <h4 style={{ lineHeight: "24px" }}>Lab Ref No: {investigations.labPrefix?.prefix}-{investigations._id}-{investigations?.labSuffix}</h4>
                        <h4 style={{ lineHeight: "24px" }}>Date: {formatedDateInDigit(new Date())}</h4>
                    </div>
                    <div style={{ fontWeight: 500, borderBottom: "1px solid black", paddingBottom: 15 }}>
                        <h4 style={{ lineHeight: "24px" }}>Received with thanks from {investigations?.firstName} {investigations?.lastName ? investigations?.lastName : ""}</h4>
                        <h4 style={{ lineHeight: "24px" }}>the sum of <span style={{ fontWeight: 700 }}>{toWord.convert(receipt?.paidAmount || 0, { currency: true, ignoreDecimal: true })}</span></h4>
                        <h4 style={{ lineHeight: "24px" }}>By {receipt?.mode}</h4>
                        <h4 style={{ lineHeight: "24px" }}>
                            for {""}
                            {
                                investigations.investigation.map((row) => `${row.shortName},`)
                            }
                        </h4>
                        <h4 style={{ marginTop: "10px", lineHeight: "24px" }}>Agains Bill No: {activePatient} {payment.totalDueAmount <= 0 && <span style={{ fontWeight: 600, marginLeft: "12px" }}>As Full Payment</span>}</h4>

                        <h4 style={{ border: "1px solid black", padding: "0 5px", width: 70, fontWeight: 700 }}>
                            {receipt?.paidAmount || 0}
                        </h4>
                        <h4 style={{ textAlign: "right" }}>
                            For Nagaliker Diagnostic Scanning Centre
                        </h4>
                    </div>
                </div>
            </main >
        </div>
    )
}

const PatientReport = ({ patientReportContent }) => {
    const { investigations: patience, activeReport, activeSampleNo } = useSelector(state => state.findPatient);
    const [report, setReport] = useState();
    const [test, setTest] = useState([]);
    const [macro, setMacro] = useState(null);
    const api = new Api();

    useEffect(() => {
        const report = patience?.investigation?.find((item) => item._id === activeReport)
        const macro = patience?.testResult?.find((item) => item.investigationId === activeReport);

        if (report) {
            setReport(report);
        }

        if (macro) {
            setMacro(macro)
        }
    }, [activeReport])

    useEffect(() => {
        const fetchTest = async () => {
            try {
                let test = [];

                for (let i = 0; i < report?.reportFormat?.length; i++) {
                    const id = report?.reportFormat[i];

                    const { data, status } = await api.fetchReportById({}, { id });

                    if (status === 200) {
                        if (data.report?.reportsubcategory?.length > 0) {
                            for (let i = 0; i < data?.report?.reportsubcategory?.length; i++) {
                                const testId = data?.report?.reportsubcategory[i]?._id;

                                const testExist = test.find((test) => test._id === testId)

                                if (!testExist) {
                                    test = [
                                        ...test,
                                        {
                                            ...data?.report?.reportsubcategory[i],
                                            reportName: data?.report?.reportName,
                                            reportId: data?.report?._id
                                        }
                                    ]
                                }
                            }
                        }
                    }
                }

                let testClone = test.reduce((acc, curr) => {
                    const id = curr?.reportId;

                    if (acc[id]) {
                        acc[id] = [...acc[id], curr]
                    } else {
                        acc[id] = [curr]
                    }
                    return acc
                }, {});

                setTest(testClone)
            } catch (err) {
                console.log(err)
            }
        }

        if (report?.reportFormat?.length > 0) {
            fetchTest()
        }
    }, [report?._id]);

    return (
        <div style={{ display: "none" }} >
            <main className="pb-20 px-20 " id="report-content" ref={patientReportContent} style={{ color: "#000", paddingTop: "220px", marginBottom: "18px" }}>
                <ul className="flex justify-between max-w-2xl mx-auto text-[#464E5F] font-medium">
                    <div className="flex-1" style={{ color: "#000" }}>
                        <li
                            className="my-0 flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Sample No</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1" style={{ fontWeight: 600 }}>{activeSampleNo}</div>
                        </li>
                        <li
                            className="my-0 flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Patient name</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.firstName} {patience?.lastName}</div>
                        </li>

                        <li
                            className="my-0 flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Reffered by</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.refferedBy?.name}</div>
                        </li>
                    </div>
                    <div className="flex-1" style={{ color: "#000" }}>
                        <li
                            className="my-0 flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Bill No</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{`${patience?._id}${report?._id}`}</div>
                        </li>
                        <li
                            className="my-0 flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Age/Sex</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{`${patience?.age}yrs`} / {patience?.gender}</div>
                        </li>
                        <li
                            className="my-0 flex items-center max-w-[300.65px]"
                        >
                            <div className="flex-[0.8]">Date</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{formatedDateInDigit(new Date())}</div>
                        </li>
                    </div>
                </ul>

                {
                    macro?.layout ? <div className="ql-editor">
                        <div className="reportLayout">
                            <div dangerouslySetInnerHTML={{ __html: macro?.layout }}>
                            </div>
                        </div>
                    </div>
                        :
                        <>

                            <div>
                                <h1 className=" font-semibold my-1 text-center text-[#000] underline underline-offset-2" style={{ textTransform: "capitalize", textAlign: "center" }}>
                                    {report?.category?.name}{" "}
                                </h1>
                                {
                                    Object.keys(test).map((id) => {
                                        return <>
                                            <div className="font-semibold text-sm max-w-[650px] mx-auto " style={{ marginTop: "15px" }}>
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", fontWeight: 600 }}><span style={{ textTransform: "capitalize", textAlign: "center", fontWeight: 500 }}>{test[id][0]?.reportName}</span></div>
                                            </div>
                                            <div className=" my-2 border-y border-[#000000] py-2 text-[#000] font-semibold text-sm max-w-[650px] mx-auto" style={{ display: "grid", gridTemplateColumns: "1fr 1fr .7fr" }}>
                                                <div>TEST</div>
                                                <div>RESULT</div>
                                                <div>NORMAL RANGE</div>
                                            </div>
                                            {
                                                test[id].map((subcat) => {
                                                    const result = macro?.testResult?.find((item) => item.testId === subcat._id)
                                                    return (
                                                        <>
                                                            <div className="text-[#000]  my-0 max-w-[650px] mx-auto" style={{ display: "grid", gridTemplateColumns: "1fr 1fr .7fr" }}>
                                                                <div style={{ fontWeight: 400, fontSize: 14 }}>{subcat?.name}
                                                                    {
                                                                        subcat?.extraText && <><br /> {subcat?.extraText}</>
                                                                    }
                                                                </div>
                                                                <div style={{ fontWeight: 400, fontSize: 14 }}>{result?.result} {subcat?.units}</div>
                                                                <div style={{ fontWeight: 400, fontSize: 14 }}>
                                                                    {
                                                                        patience?.gender === "male"
                                                                            ? <RenderRange patient={patience} range={subcat?.rangeForMale} unit={subcat?.units} />
                                                                            : <RenderRange patient={patience} range={subcat?.rangeForFemale} unit={subcat?.units} />
                                                                    }
                                                                </div>
                                                            </div>

                                                        </>
                                                    );
                                                })
                                            }
                                        </>
                                    })
                                }

                            </div>
                        </>
                }


                {
                    macro?.macroHtml &&
                    <div className="ql-editor">
                        <div dangerouslySetInnerHTML={{ __html: macro?.macroHtml }}>
                        </div>
                    </div>
                }

                {/* <div className="text-center flex justify-between align-middle text-sm mt-10 mb-2">
                    {
                        macro?.enteredBy &&
                        <h1 className="font-medium">Entered By: {macro.enteredBy.replace(/^\w/, c => c.toUpperCase())}</h1>
                    }
                    {
                        macro?.verifiedBy &&
                        <h1 className="font-medium">Verified By: {macro.verifiedBy.replace(/^\w/, c => c.toUpperCase())}</h1>
                    }
                </div> */}

                <div className="mt-10 px-20">
                    <div className="flex flex-col items-end">
                        <h4 style={{ fontSize: "16px", fontWeight: 700, lineHeight: "20px" }}>Dr. Anand Nagalikar</h4>
                        <p style={{ fontWeight: 500, lineHeight: "20px" }}>MBBS,MD,DNB(Path.)</p>
                        <p style={{ marginTop: 0, fontWeight: 500, lineHeight: "20px" }}>Consultant Pathologist</p>
                    </div>
                </div>
                <div className="text-center text-sm ">
                    <h1 className="font-medium text-center">-----End of report-----</h1>
                </div>
            </main>
        </div>
    )
}

export default PatientNew