import React, {useEffect, useState} from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import {
  TableCell
} from "@material-ui/core";
import { useNavigate, useParams } from 'react-router-dom';
import Buttons from "../../Components/Shared/Buttons";
import Api from '../../helper/api';
import { formatedDateInDigit } from '../../helper/helper';

const SampleView = () => {
  const api = new Api();
  const params = useParams();
  const id = params.id;
   const [rows, setRows] = useState();
    const tableclasses = tableStyles();
    const classes = formStyles();
    const navigate = useNavigate(); 
    const fetchSample = async() => {
      try{
        const {data, status} = await api.fetchPatientById({}, {id});
        setRows(data)
        console.log(data)
      }catch(error){
        console.log(error)
      }
    }
    useEffect(() => {
      fetchSample();
    },[])
  return (
    <div className={tableclasses.root} style={{ margin: "25px" }}>
      <div className={tableclasses.appointmentbody}>
        <div className={tableclasses.headertwo}>
          <div className={classes.formname}>
            <div className={classes.formh2}>{rows?.collector?.name}</div>
            <div className={classes.viewHead}>
              <div className={`${classes.formspecification}`}
              style={{marginLeft: '50px', marginRight: '50px'}}
              >
                Patient ID: 9876543210
              </div>
              <div className={classes.formspecification} style={{display: 'flex', alignItems: 'center'}}>
                Status: 
                <TableCell className={tableclasses.customTableCell}>
                  <Buttons className={tableclasses.customActive}>
                    <div style={{ color: 'green'}}>
                      Active</div>
                  </Buttons>
                </TableCell>
              </div>
              <div
                className={classes.formspecification}
                style={{ color: "#fff" }}
              >
                Urgent Requirement
              </div>
            </div>
            <Buttons
              className={classes.backToButton}
              style={{ width: "120px" }}
              onClick={() => navigate("/patience-cards")}
            >
              Back to Table
            </Buttons>
          </div>
          </div>
          <div className={classes.biodata}>
          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Lab</p>
              <p>Patient name</p>
              <p>Age/Sex</p>
              <p>Mobile number</p>
              <p>Email address</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
            <p>{rows?.labPrefix?.prefix ? rows?.labPrefix?.prefix : "NA"}</p>
              <p>
                {rows?.firstName} {rows?.lastName}
              </p>
              <p>
                {rows?.age}yrs / {rows?.gender}
              </p>
              <p>{rows?.phone ? rows?.phone : "NA"}</p>
              <p>{rows?.email ? rows?.email : "NA"}</p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Referred by</p>
              <p>Sample from</p>
              <p>Created date</p>
              <p>Address</p>
              <p>Reference ID</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
            <p>{rows?.refferedBy?.name ? rows?.refferedBy?.name : "NA"}</p>
              <p>{rows?.sampleFrom ? rows?.sampleFrom : "NA"}</p>
              <p>{formatedDateInDigit(rows?.createdAt)}</p>
              <p>{rows?.address ? rows?.address : "NA"}</p>
              <p>{rows?.refID ? rows?.refID : "NA"}</p>
            </div>
          </div>
          <div className={classes.imageside}></div>
          </div>
          </div>
          </div>
  )
}

export default SampleView
