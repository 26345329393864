import React, { useEffect, useState } from "react";

import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { formStyles } from "../Styles/Formstyle";
import Buttons from "../Components/Shared/Buttons";
import Input from "../Components/Shared/Input";
import axios from "axios";
import { API } from "../config";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Selects, { components } from 'react-select';

const EditReport = () => {
  const classes = formStyles();
  const [value, setValue] = useState("1");
  const [rows, setRows] = useState();
  const [sample, setSample] = useState(null);
  const [category, setCategory] = useState("");
  const [subcategory, setsubCategory] = useState([]);
  const [reportId, setReportId] = useState();
  const [reportName, setReportName] = useState();
  const [reportTat, setReportIdtat] = useState();
  const [reportshortName, setShortReportName] = useState();
  const [validation, setValidation] = useState(false);
  const [single, SetSigleReport] = useState();
  const [loadedCategory, setLoadedCategory] = useState([]);
  const [loadedSubCategory, setLoadedSubCategory] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    variant: "menu",
    PaperProps: {
      style: {
        height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const TOKEN = localStorage.getItem("logintoken");

  const SingleReport = async () => {
    const data = await axios.get(`${API}/getreportformat/${id}`, {
      headers: { authtoken: `${TOKEN}` },
    });

    SetSigleReport(data.data.report);
    const reportData = data.data.report;
    setReportId(reportData.reportId);
    setReportName(reportData.reportName);
    setReportIdtat(reportData.reportTat);
    setShortReportName(reportData.reportshortName);
    const cate = reportData?.reportcategory?.map((name) => name.name);
    const category = reportData?.reportcategory?.map((name) => name._id);
    const subcate = reportData?.reportsubcategory?.map((name) => name.name);
    const selectedTest = reportData?.reportsubcategory?.map((name) => {
      return {
        label: name.name, value: name._id, id: name.id
      }
    });

    setSelectedTest(selectedTest)
    setLoadedCategory(cate.join(", "));
    setSample(reportData.sample);
    setLoadedSubCategory(subcate);
    setCategory(category)
  };

  const fetchTest = async (e) => {
    try {
      const data = await axios.get(`${API}/gettestcategory`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setRows(data.data.testCategory);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchsubCategory = async (e) => {
    try {
      const data = await axios.get(`${API}/gettestsubcategory/1/1000000`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setsubCategory(data.data.subTestCategory);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    SingleReport();
    fetchTest();
    fetchsubCategory();
  }, []);

  const handleSubmit = async () => {
    if (
      !reportId ||
      !reportName ||
      !reportTat ||
      !reportshortName ||
      !category ||
      !sample
    ) {
      toast.error("Please Check All fields Are Entered");
    }
    try {
      const { data, status } = await axios.put(
        `${API}/editreportformat/${id}`,
        {
          reportId,
          reportName,
          reportshortName,
          reportTat,
          reportcategory: category,
          sample,
          reportsubcategory: [...selectedTest.map((test) => test.value)]
        },
        {
          headers: { authtoken: `${TOKEN}` },
        }
      );

      if (status === 200) {
        toast.success("Report Format Updated successfully");
        navigate("/add-report-format")
      } else {
        toast.error(data?.data?.errors);
      }
    } catch (e) {
      console.log(e);
      toast.error(e);
    }
  };

  const handleChange2 = (value) => {
    setSelectedTest(value)
  };

  return (
    <div className={classes.root}>
      <div className={classes.collectorForm}>
        <div className={classes.formheader}>
          <div className={classes.formnameHead}>
            <div className={classes.formh2}>Edit Report Group Format </div>
            <div className={classes.littlehead}>
              You can edit new report group here
            </div>
          </div>
          <div>
            <Buttons
              className={classes.formButton}
              onClick={() => navigate("/add-report-format")}
            >
              &nbsp; Back to table
            </Buttons>
          </div>
        </div>

        <div>
          <Box sx={{ padding: "0 8%", borderColor: "blue" }}>
            <TabContext value={value} className={classes.tabs}>
              <Box sx={{ padding: "0 30%" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{ "& .MuiTabs-indicator": { backgroundColor: "black" } }}
                >
                  <Tab
                    label=" Report Format"
                    className={classes.tablist}
                    value="1"
                  />
                  <Tab label="Add Test" className={classes.tablist} value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{}}>
                <div>
                  <div className={classes.formMain}>
                    <FormControl>
                      <div className={classes.formDiv1}>
                        <div className={classes.formDiv2}>
                          {/* <div className={classes.formHeading}>
                            New Report Group Format
                          </div> */}
                          <div className={classes.formLable}>Report ID</div>
                          <Input
                            type="text"
                            placeholder="Enter ID "
                            className={classes.formInput}
                            value={reportId}
                            onChange={(e) => setReportId(e.target.value)}
                          />
                          {!reportId && validation ? (
                            <p style={{ color: "red" }}>reportId is Required</p>
                          ) : null}{" "}
                          <br />
                          <div className={classes.formLable}>
                            Report group is:
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {" "}
                              {loadedCategory}
                              {console.log("loadedCategory", loadedCategory)}
                            </span>
                          </div>
                          <FormControl>
                            <Select
                              value={category}
                              onChange={(event) =>
                                setCategory(event.target.value)
                              }
                              className={classes.selectInput}
                              MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                            >
                              {rows?.map((option) => (
                                <MenuItem key={option._id} value={option._id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {!category && validation ? (
                            <p style={{ color: "red" }}>Category is Required</p>
                          ) : null}{" "}
                          <br />
                          <div className={classes.formLable}>Sample name</div>
                          <FormControl>
                            <Select
                              className={classes.selectInput}
                              value={sample}
                              onChange={(e) => setSample(e.target.value)}
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                <p>Select</p>
                              </MenuItem>
                              <MenuItem value="blood">Blood</MenuItem>
                              <MenuItem value="urine">Urine</MenuItem>
                            </Select>
                          </FormControl>{" "}
                          <br />
                        </div>
                        <div>
                          <div className={classes.formLable}>Report name</div>
                          <Input
                            type="text"
                            placeholder="Enter Report name"
                            className={classes.formInput}
                            value={reportName}
                            onChange={(e) => setReportName(e.target.value)}
                          />{" "}
                          <br />
                          <div className={classes.formLable}>
                            Report short name
                          </div>
                          <Input
                            type="text"
                            placeholder="Enter Short name"
                            className={classes.formInput}
                            value={reportshortName}
                            onChange={(e) => setShortReportName(e.target.value)}
                          />
                          {!reportshortName && validation ? (
                            <p style={{ color: "red" }}>
                              ReportshortName is Required
                            </p>
                          ) : null}{" "}
                          <br />
                          <div className={classes.formLable}>
                            Time arount test(TAT) in minutes
                          </div>
                          <Input
                            type="text"
                            placeholder="Enter Default value"
                            className={classes.formInput}
                            value={reportTat}
                            onChange={(e) => setReportIdtat(e.target.value)}
                          />{" "}
                          <br />
                        </div>
                      </div>
                      <div className={classes.formDiv4}>
                        <Buttons className={classes.cancelButton} onClick={() => navigate("/add-report-format")}>
                          Cancel
                        </Buttons>
                        <Buttons
                          onClick={() => {
                            setValue("2");
                          }}
                          className={classes.submitButtonwidth}
                        >
                          {validation ? "Add Test Data" : "Next"}
                        </Buttons>
                      </div>
                    </FormControl>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{}}>
                <div className={classes.formHeading}>List Test Available</div>
                <div>
                  <div className={classes.formLable}>Report SubCategory:
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      {" "}{loadedSubCategory.join(", ")}</span></div>
                  <FormControl
                    style={{
                      width: "80%",
                      marginLeft: "8px",
                    }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Tag
                    </InputLabel>

                    <Selects
                      options={[...subcategory.map((subCat) => {
                        return { label: subCat.name, value: subCat._id, id: subCat.id }
                      })]}
                      components={{ GroupHeading, Option }}
                      value={selectedTest}
                      onChange={handleChange2}
                      isMulti
                      styles={{ input: (styles) => ({ ...styles, width: "540px" }) }}
                    />

                  </FormControl>
                </div>
                <div className={classes.formDiv4}>
                  <Buttons
                    onClick={() => navigate("/add-report-format")}
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Buttons>
                  <Buttons
                    className={classes.submitButton}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Buttons>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
};

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
          <p>{props.data.label}</p>
          <p>{props.data.id}</p>
        </div>
      </components.Option>
    </>
  )
}

const GroupHeading = (props) => {
  return (
    <components.GroupHeading {...props}>
      <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
        <p>Name</p>
        <p>Id</p>
      </div>
    </components.GroupHeading>
  )
}

export default EditReport;
