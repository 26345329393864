import { makeStyles } from '@material-ui/core/styles';
export const formStyles = makeStyles({
  root: {
    background: '#f5f5f5', // Set your desired background color
    paddingBottom: '3%',
    paddingTop: '3%',
    fontFamily: 'Poppins',
    margin: 0,
  },


  collectorForm: {
    backgroundColor: '#FFFFFF',
    margin: '0 70px',
    borderRadius: '12px',
    paddingBottom: '6%',
  },
  myDiv5: {
    width: 480,
    height: 430,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
  },

  selectInput: {
    width: '350px',
    height: '42px',
    fontFamily: 'Poppins',
    borderRadius: '4px',
    border: '2px solid #c4c4c4',
    marginTop: '10px',
    marginBottom: '10px',
    padding: '0 12px',
    color: '#676767',
    fontSize: '13px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    backgroundColor: 'transparent !important',
    '&.MuiPaper-root': {
      marginTop: "20px !important"
    },
    '& .MuiSelect-icon': {
      backgroundColor: 'transparent',
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',

    },
    '&.MuiSelect-select:focus': {
      backgroundColor: 'transparent !important',
    },
    '&.MuiInput-underline:before': {
      borderBottom: 0,
      content: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 0,
      content: 'none',
    },
  },
  menuItem: {
    "&.MuiListItemText-root": {
      flex: 'none',
      width: '310px',
    }
  },

  /*  menuInput:{
     backgroundColor: 'transparent',
     '&.MuiSelect-select:focus':{
      backgroundColor: 'transparent',
     },
    '&.MuiSelect-select.MuiSelect-select':{
      paddingLeft: '12px',
    },
    top: 0,
    '&.MuiPaper-root':{
      top: 224,
    },

   }, */

  selectLabel: {
    borderRadius: '14px',
    border: '1px solid #DDDDDD',
    height: '53px',
    marginTop: '6px',
    '&.MuiInputLabel-shrink': {
      transform: 'none',
    },
  },

  formheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'left',
    paddingTop: '2%',
    paddingBottom: '1%',
    fontFamily: 'Poppins',
    margin: '0 60px'
  },
  formh2: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#212121',
    fontFamily: 'Poppins',
  },
  formname: {
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '12px',
    fontSize: '14px',
    width: '100%',
    color: '#464E5F',
  },
  formnameHead: {
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    paddingLeft: '12px',
    fontSize: '14px',
    width: '100%',
    color: '#464E5F',
    flexDirection: 'column'
  },
  littlehead: {
    fontWeight: 500,
    color: '#B5B5C3',
    fontSize: '12px',
    paddingBottom: '7px'
  },
  viewHead: {
    display: 'flex',
    justifyContent: 'center'
  },
  viewheading: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1%',
    paddingLeft: '3%'
  },
  viewBox: {
    width: '59%',
    height: 'content-fit',
    borderRadius: '12px',
    paddingTop: '1%',
    paddingBottom: '2%',
    boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.20)',
    marginRight: '2%',
  },
  pateintcard: {
    width: '39%',
    height: 'content-fit',
    borderRadius: '12px',
    paddingTop: '1%',
    paddingBottom: '2%',
    boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.20)'
  },
  veiwcard: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 120px)',
    margin: '0 60px'
  },
  hoverview: {
    cursor: "pointer",
    '&:hover': {
      backgroundColor: '#F8EAEB',
    }
  },
  active: {
    backgroundColor: '#F8EAEB',
  },
  headline: {
    marginTop: '1%',
    marginBottom: '1%'
  },
  halfArea: {
    width: '50%',
  },
  fontStyle: {
    color: '#B5B5C3',
    fontSize: '12px',
    fontWeight: '600',
    display: 'flex',
    letterSpacing: '0.36px',
    justifyContent: 'left'
  },
  halfhead: {
    width: '50%'
  },
  insideItem: {
    color: '#464E5F',
    fontWeight: '600',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'left'
  },
  formspecification: {
    margin: 'auto 20px',
    color: '#B5B5C3',
    fontSize: '13px',
    fontWeight: '500',
    fontFamily: 'Poppins',
  },
  formspecification2: {
    margin: '5px 20px',
    color: '#B5B5C3',
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Poppins',
  },
  status: {
    backgroundColor: 'rgba(212, 138, 72, 0.20)',
    color: '#D48A48',
    borderRadius: '6px',
    fontSize: '11px',
    padding: '5px 9px 5px 10px'
  },
  biodata: {
    margin: '1px 0px 30px 0px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  question: {
    width: '140px',
    color: '#464E5F',
    fontSize: '14px',
    fontWeight: '500'
  },
  of: {
    width: '25px',
    color: '#464E5F',
    fontSize: '14px',
    fontWeight: '500'
  },
  patientImage: {
    width: '400px',
    color: '#464E5F',
    fontSize: '14px',
    fontWeight: '500',
  },
  imageside: {
    width: '400px',
    justifyContent: 'center'
  },
  addPatience: {
    width: '750px',
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid rgba(201, 201, 201, 1)',
    borderRadius: '4px',
    backgroundColor: 'rgba(201, 201, 201, 0.15)',
    padding: '0 10px'
  },
  headerAp: {
    justifyContent: 'space-between',
    display: 'flex',
    width: '95%',
    fontWeight: '600',
    marginRight: '30px'
  },
  appointhead: {
    color: '#B5B5C3',
    fontSize: '12px',
    letterSpacing: '0.36px',
    padding: '12px 18px'
  },
  textmenu: {
    color: '#464E5F',
    fontSize: '14px',
    padding: '9px 18px',
    display: "flex",
    alignItems: "center"
  },
  firstp: {
    width: '30%',
    justifyContent: 'space-between'
  },
  secp: {
    width: '10%',
    justifyContent: 'space-between'
  },
  fourp: {
    width: '20%',
    justifyContent: 'space-between'
  },
  thirdp: {
    width: '25%',
    justifyContent: 'space-between'
  },
  formButton: {
    borderRadius: '6px',
    width: '126px',
    height: '38px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    '&.MuiButton-contained': {
      backgroundColor: '#C9C9C9',
      color: '#FFFFFF',
      fontSize: '12px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
             /*  transform: 'none', */ backgroundColor: '#C9C9C9',
      },
    }
  },
  formButtonwidth: {
    borderRadius: '6px',
    width: '158px',
    height: '38px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    '&.MuiButton-contained': {
      backgroundColor: '#C9C9C9',
      color: '#FFFFFF',
      fontSize: '12px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
             /*  transform: 'none', */ backgroundColor: '#C9C9C9',
      },
    }
  },
  formMain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    flexDirection: 'column',
  },
  formDiv1: {
    display: 'flex',
  },
  formDiv31: {
    marginTop: '37px',
  },
  formLable: {
    fontFamily: 'Poppins',
    marginTop: "22px",
    fontSize: '13px',
    color: '#181C32',
  },
  formlabled: {
    fontSize: '13px',
    fontWeight: '600',
    marginTop: "22px",
    color: 'var(--Black, #181C32)',
    width: '355px',
    marginBottom: '-20px'
  },
  formHeading: {
    /* margin: '0 0 70px 245px', */
    marginBottom: '30px',
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: 500,
  },
  refslipFirst: {
    width: "260px",
    padding: '35px',
    border: '1px dashed #999999',
    minHeight: '120px',
    marginTop: '14px',
  },
  refslip: {
    display: 'flex',
    alignItems: 'center',
    width: "fit-content",
    border: '1px dashed #999999',
    minHeight: '120px',
    marginTop: '14px',
  },
  vector: {
    position: 'absolute',
    top: '50%', left: '50%',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)'
  },
  refslip2: {
    height: '33px',
    padding: '0',
    width: '125px'
  },
  filterButton2: {
    height: "44px",
    fontFamily: "Poppins",
    fontWeight: 500,
    borderRadius: "43px",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "14px",
      boxShadow: "none",
      borderRight: "1px solid #3A4251",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  filterButton: {
    height: "35px !important",
    width: '35px !important',
    fontFamily: "Poppins",
    fontWeight: 500,
    borderRadius: "50% !important",
    "&.MuiButton-contained": {
      backgroundColor: "#273142",
      color: "#FFFFFF",
      fontSize: "26px",
      boxShadow: "none",
      borderRight: "1px solid #3A4251",
      textTransform: "none",
      "&.MuiButton-root": {
        minWidth: '0'
      },
      "&:hover": {
        backgroundColor: "#273142",
      },
    },
  },
  filterButton1: {
    height: "40px",
    fontFamily: "Poppins",
    fontWeight: 500,
    borderRadius: "43px",
    marginLeft: '65px',
    "&.MuiButton-contained": {
      backgroundColor: "#fff",
      color: "#273142",
      fontSize: "14px",
      boxShadow: "none",
      border: "2px solid #273142",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  },
  dateInput: {
    width: '350px',
    fontFamily: 'Poppins',
    margin: '4px 48px 24px 0px',
    overflow: 'hidden',
    shrink: 'false',
    '&.MuiStack-root': {
      overflow: 'none',
    },
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid #DDDDDD',
      height: '42px',
      marginTop: '6px',
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },


  },
  dateInput2: {
    width: '273px',
    fontFamily: 'Poppins',
    margin: '4px 48px 24px 0px',
    overflow: 'hidden',
    shrink: 'false',
    '&.MuiStack-root': {
      overflow: 'none',
    },
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid rgba(156, 156, 156, 0)',
      height: '42px',
      marginTop: '6px',
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #C9C9C9',
    },


  },
  formInput: {
    width: '350px',
    fontFamily: 'Poppins',
    margin: '4px 48px 8px 0px',
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid #DDDDDD',
      height: '42px',
      marginTop: '6px',
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },
    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#676767',
      '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },

  },
  otherformInput: {
    width: '350px',
    fontFamily: 'Poppins',
    margin: '3px 48px 8px 0px',
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid #DDDDDD',
      height: '42px',
      marginTop: '5px',
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },
    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#676767',
      '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },
  },
  formInputSample: {
    width: '355px',
    fontFamily: 'Poppins',
    margin: 'auto',
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid #c7c7c7',
      height: '42px',
      marginTop: '6px',
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "transparent !important"
    },
    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#676767',
      '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },

  },
  error: {
    color: 'red'
  },

  formDiv4: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'right',
    marginRight: '4%',
  },
  cancelButton: {
    borderRadius: '6px',
    height: '38px',
    fontFamily: 'Poppins',
    width: 110,
    marginRight: '2%',
    marginTop: '2%',
    '&.MuiButton-contained': {
      backgroundColor: '#C9C9C9',
      color: '#FFFFFF',
      fontSize: '12px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
             /*  transform: 'none', */ backgroundColor: '#C9C9C9',
      },
    }
  },
  submitButton: {
    borderRadius: '6px',
    height: '38px',
    fontFamily: 'Poppins',
    width: 110,
    marginRight: '2%',
    marginTop: '2%',
    '&.MuiButton-contained': {
      backgroundColor: '#B82C3A',
      color: '#FFFFFF',
      fontSize: '12px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#B82C3A',
      },
    }
  },
  submitButtonwidth: {
    borderRadius: '6px',
    height: '38px',
    fontFamily: 'Poppins',
    width: 'auto',
    marginRight: '2%',
    marginTop: '2%',
    '&.MuiButton-contained': {
      backgroundColor: '#B82C3A',
      color: '#FFFFFF',
      fontSize: '12px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#B82C3A',
      },
    }
  },
  backButton: {
    borderRadius: '6px',
    height: '43px',
    fontFamily: 'Poppins',
    width: 84,
    '&.MuiButton-contained': {
      backgroundColor: '#C9C9C9',
      color: '#FFFFFF',
      fontSize: '12px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
             /*  transform: 'none', */ backgroundColor: '#C9C9C9',
      },
    }
  },
  backToButton: {
    borderRadius: '6px',
    height: '38px',
    fontFamily: 'Poppins',
    width: 120,
    '&.MuiButton-contained': {
      backgroundColor: '#C9C9C9',
      color: '#FFFFFF',
      fontSize: '12px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#C9C9C9',
      },
    }
  },
  tablist: {
    color: "#C9C9C9",
    textTransform: 'none',
    '&.MuiTabs-root': {
      textTransform: 'none',
      color: '#273142 !important',
    },
    '&.Mui-selected': {
      borderBottom: '1px solid #273142 !important',
      textTransform: 'none',
      color: '#273142 !important',
      fontWeight: '600px',
    },
    '&.MuiTab-wrapper': {
      backgroundColor: '#273142 !important',
    }, '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
  },

  tabs: {
    '&.MuiTabs-root': {
      height: '72px',
      textTransform: 'none',
    },
    '& .MuiTabs-indicator': {
      bottom: "none",
      border: "none",
      display: 'none',
    },
    '& .PrivateTabIndicator-colorSecondary-12': {
      backgroundColor: '#273142 !important',
    },
  },

  genderDiv1: {
    backgroundColor: '#FAFAFA',
    padding: '5% 20px',
    margin: '5% 0',
    borderRadius: '12px',
  },
  genderDiv2: {
    margin: '40px 0',
    display: 'flex',
    /*  flexDirection: 'column', */
  },
  genderHeading: {
    fontSize: '16px',
    color: '#B82C3A',
    display: "flex",
    justifyContent: "space-between"
  },
  genformInput: {
    width: '90%',
    fontFamily: 'Poppins',
    margin: '4px 48px 0px 0px',
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#676767',
      '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid #DDDDDD',
      height: '42px',
      marginTop: '6px',
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', /* remove input bordercolor focus */
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },

  },

  addButton: {
    borderRadius: '6px',
    height: '40px',
    fontFamily: 'Poppins',
    marginLeft: '2%',
    marginTop: '1%',
    '&.MuiButton-contained': {
      backgroundColor: '#B82C3A',
      color: '#FFFFFF',
      fontSize: '10px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
         /*  transform: 'none', */ backgroundColor: '#B82C3A',
      },
    }
  },
  addButtonadd: {
    borderRadius: '6px',
    height: '40px',
    fontFamily: 'Poppins',
    marginLeft: '2%',
    marginTop: '0%',
    '&.MuiButton-contained': {
      backgroundColor: '#B82C3A',
      color: '#FFFFFF',
      fontSize: '10px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
       /*  transform: 'none', */ backgroundColor: '#B82C3A',
      },
    }
  },
  addTestInput: {
    width: '60%',
    height: '42px',
    fontFamily: 'Poppins',
    borderRadius: '4px',
    border: '2px solid #c4c4c4',
    marginTop: '11px',
    marginBottom: '28px',
    padding: '0 12px',
    color: '#676767',
    fontSize: '13px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    backgroundColor: 'transparent',
    '&.MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '&.MuiInput-underline:before': {
      borderBottom: 0,
      content: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 0,
      content: 'none',
    },

  },
  testSelect: {
    '&. MuiOutlinedInput': {
      borderColor: 'red',
    }

  },
  testName: {
    paddingLeft: '24px',
    backgroundColor: '#FAFAFA',
    borderBottom: '1px solid #c4c4c4',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  testMain: {
    display: 'flex',

  },
  selectInput2: {
    width: '260px',
    height: '42px',
    fontFamily: 'Poppins',
    borderRadius: '4px',
    border: '2px solid #c4c4c4',
    marginTop: '11px',
    marginBottom: '28px',
    padding: '0 12px',
    color: '#676767',
    fontSize: '13px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    '&.MuiInput-underline:before': {
      borderBottom: 0,
      content: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 0,
      content: 'none',
    },

  },
  secondClass: {
    border: '1px solid #c4c4c4'
  },
  reffered:{
    "&.MuiMenuItem-root": {
      width:'200px',
    },
  },
  formInput2: {
    width: '260px',
    fontFamily: 'Poppins',
    margin: '4px 48px 28px 0px',
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#676767',
      '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid #DDDDDD',
      height: '42px',
      marginTop: '6px',
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', /* remove input bordercolor focus */
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },

  },
  formInput4: {
    width: '100%',
    fontFamily: 'Poppins',
    margin: '0px 125px 28px 0px',
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#676767',
      '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid #DDDDDD',
      height: '42px',
      marginTop: '6px',
      marginRight: '125px'
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', /* remove input bordercolor focus */
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },

  },
  formInput3: {
    width: '88%',
    fontFamily: 'Poppins',
    margin: '4px 48px 28px 0px',
    '& .MuiInputBase-input': { /* input field */
      color: '#676767',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    '& .MuiInputBase-root': { /* input border */
      borderRadius: '4px',
      border: '1px solid #DDDDDD',
      height: '42px',
      marginTop: '6px',
    },
    '&  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent', /* remove input bordercolor focus */
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD',
    },
    '& input:-webkit-autofill': {
      '-webkit-text-fill-color': '#676767',
      '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
      transition: 'background-color 5000s ease-in-out 0s',
    },

  },
});     