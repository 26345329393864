import React, {useState} from "react";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "../../Components/Shared/Buttons";

const ShowReport = ({ handleClose, showReport }) => {
  const [height, setHeight] = useState('550px');
  const tableclasses = tableStyles();
  const classes = formStyles();
  return (
    <div className={tableclasses.outBackground}>
      <div
        className={tableclasses.sampleCollection}
        style={{ padding: "20px 60px", position: "relative", marginTop: height ? null : '150px' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "23px",
            top: "23px",
          }}
          onClick={handleClose}
        >
          <path
            d="M1 17L17 1M1 1L17 17"
            stroke="black"
            stroke-opacity="0.7"
            stroke-width="1.88067"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <img src={showReport.src} style={{ width: '600px', height: 'auto', objectFit: 'cover'}} />
      </div>
    </div>
  );
};

export default ShowReport;
