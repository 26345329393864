import React, { useEffect, useState } from "react";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { TextField } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "../../Components/Shared/Buttons";
import SearchedPatience from "./SearchedPatience";
import Api from "../../helper/api";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import FormControl from "@material-ui/core/FormControl";
import { Checkbox, Select } from "@material-ui/core";

const FindPatient = ({ setPopup }) => {
  const api = new Api();
  const [patience, setPaitence] = useState(false);
  const [rows, setRows] = useState();
  const [account, setAccount] = useState(); // lab accounts
  const [data, setData] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const tableclasses = tableStyles();
  const classes = formStyles();
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    "sampleCollection.sampleNo": "",
    labPrefix: "",
    labNumber: "",
    labSuffix: "",
    phone: "",
    createdAt: "",
    refferedBy: "",
    sampleFrom: "",
    category: "",
  })

  const removeComponents = () => {
    setPopup(false);
    setPaitence(false);
  };

  // fetch lab accounts
  const fetchAccount = async () => {
    const data = await api.fetchAccount({}, { page: 1, quntity: 200 });
    setAccount(data?.data?.accountDetails);
  };

  // fetch doctors
  const fetchDoctor = async () => {
    const data = await api.fetchDoctors();
    setDoctor(data?.data?.doctors);
  };

  // get report group
  const fetchTest = async (e) => {
    try {
      const data = await api.fetchReportGroup();
      setRows(data.data.testCategory);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAccount();
    fetchDoctor();
    fetchTest();
  }, []);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    variant: "menu",
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const handleChange = (e) => {
    e.persist();
    setValue((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  return (
    <div className={tableclasses.outBackground}>
      {patience ? (
        <SearchedPatience
          setPaitence={setPaitence}
          value={value}
          removeComponents={removeComponents}
        />
      ) : (
        <div className={tableclasses.paper}>
          <div className={tableclasses.findHeader}>
            <div className={tableclasses.h2}>Patients</div>
            <div
              className={tableclasses.h2}
              style={{ cursor: "pointer" }}
              onClick={() => setPopup(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M1 17L17 1M1 1L17 17"
                  stroke="black"
                  stroke-opacity="0.7"
                  stroke-width="1.88067"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className={tableclasses.searchBy}>Search by</div>
          <div style={{ display: "flex" }}>
            <span>
              <div style={{ paddingTop: "10px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>First Name</label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      placeholder="Enter"
                      value={value.firstName}
                      name="firstName"
                      onChange={handleChange}
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Last Name</label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      placeholder="Enter"
                      value={value.lastName}
                      name="lastName"
                      onChange={handleChange}
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Sex</label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue="Select"
                      value={value.gender}
                      name="gender"
                      onChange={handleChange}
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    >
                      <MenuItem value="">
                        <p>Select</p>
                      </MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </TextField>
                    {/* <TextField placeholder="Enter Patient ID/Phone no/Patient name" className={tableclasses.searchField} style={{padding: '10px 16px'}} /> */}
                  </div>
                </div>
              </div>

              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Sample no.</label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      value={value["sampleCollection.sampleNo"]}
                      name="sampleCollection.sampleNo"
                      onChange={handleChange}
                      placeholder="Select"
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Lab Prefix</label>
                  <div className={tableclasses.patientdDetail} style={{ border: 'none' }}>
                    <Select
                      value={value.labPrefix}
                      name="labPrefix"
                      onChange={handleChange}
                      className={`${classes.selectInput2} ${classes.secondClass}`}
                      style={{ width: '273px', margin: '0' }}
                      displayEmpty
                      MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                    >
                      <MenuItem value="">
                        <p>Select</p>
                      </MenuItem>
                      {account?.map((account) => {
                        return (
                          <MenuItem className={classes.reffered} value={account?._id}>
                            <p>{account?.prefix}</p>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {/* <TextField
                      id="outlined-select-currency"
                      select
                      displayEmpty
                      defaultValue="Select"
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    >
                      <MenuItem value="">
                        <p>Select</p>
                      </MenuItem>
                      {account?.map((account) => {
                        return (
                          <MenuItem value={account?._id}>
                            <p>{account?.prefix}</p>
                          </MenuItem>
                        );
                      })}
                    </TextField> */}
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Lab no.</label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      value={value.labNumber}
                      name="labNumber"
                      onChange={handleChange}
                      placeholder="Select"
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Lab Sufix</label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      value={value.labSuffix}
                      name="labSuffix"
                      onChange={handleChange}
                      placeholder="Select"
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    />
                  </div>
                </div>
              </div>
            </span>
            <span>
              <div style={{ paddingTop: "10px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Phone Number</label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      value={value.phone}
                      name="phone"
                      onChange={handleChange}
                      placeholder="Select"
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Date</label>
                  <div className={tableclasses.patientdDetail} style={{ border: 'none' }}>
                    <div style={{ marginTop: '-15px' }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            className={classes.dateInput2}
                            name="date"
                            onChange={(date) => setValue((prev) => { return { ...prev, createdAt: date.$d } })}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    {/* <TextField placeholder="Enter Patient ID/Phone no/Patient name" className={tableclasses.searchField} style={{padding: '10px 16px'}} /> */}
                  </div>
                </div>
              </div>

              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Reffered by</label>
                  <div className={tableclasses.patientdDetail} style={{ border: 'none' }}>
                    <Select
                      value={value.refferedBy}
                      name="refferedBy"
                      onChange={handleChange}
                      className={`${classes.selectInput2} ${classes.secondClass}`}
                      style={{ width: '273px', margin: '0' }}
                      displayEmpty
                      MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                    >
                      <MenuItem value="">
                        <p>Select</p>
                      </MenuItem>
                      {doctor?.map((doctors) => {
                        return (
                          <MenuItem className={classes.reffered} value={doctors._id}>
                            {doctors.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>

              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Sample from</label>
                  <div className={tableclasses.patientdDetail} style={{ border: 'none' }}>
                    <Select
                      value={value.sampleFrom}
                      name="sampleFrom"
                      onChange={handleChange}
                      className={`${classes.selectInput2} ${classes.secondClass}`}
                      style={{ width: '273px', margin: '0' }}
                      displayEmpty
                      MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                    >
                      <MenuItem value="">
                        <p>Select</p>
                      </MenuItem>
                      <MenuItem value="lab">
                        <p>Lab</p>
                      </MenuItem>
                      <MenuItem value="doctor">
                        <p>Doctor</p>
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Report id</label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      placeholder="Select"
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>Report group</label>
                  <div className={tableclasses.patientdDetail} style={{ border: 'none' }}>
                    <Select
                      value={value.category}
                      name="category"
                      onChange={handleChange}
                      className={`${classes.selectInput2} ${classes.secondClass}`}
                      style={{ width: '273px', margin: '0' }}
                      displayEmpty
                      MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                    >
                      <MenuItem value="">
                        <p>Select</p>
                      </MenuItem>
                      {rows?.map((row) => {
                        return (
                          <MenuItem className={classes.reffered} value={row?._id}>
                            {row?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {/* <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue="Select"
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    >
                      {rows?.map((row) => {
                        return (
                          <MenuItem value={row?._id}>
                            <p>{row?.name}</p>
                          </MenuItem>
                        );
                      })}
                    </TextField> */}
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "23px", paddingLeft: "181px" }}>
                <div
                  style={{
                    display: "flex",
                    gridGap: "19px",
                    alignItems: "center",
                  }}
                >
                  <label className={tableclasses.labeling}>
                    Report macros contains
                  </label>
                  <div className={tableclasses.patientdDetail}>
                    <TextField
                      placeholder="Select"
                      className={tableclasses.searchField}
                      style={{ padding: "10px 16px" }}
                    />
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Buttons
              className={classes.cancelButton}
              onClick={() => setPopup(false)}
            >
              {" "}
              Cancel
            </Buttons>
            <Buttons
              className={classes.submitButton}
              onClick={() => setPaitence(true)}
            >
              Find
            </Buttons>
          </div>
        </div>
      )}
    </div>
  );
};

export default FindPatient;
