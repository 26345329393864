import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { API } from "../../config";
import { formatTime, formatedDate, formatedDateInDigit } from "./../../helper/helper";
import { useReactToPrint } from "react-to-print";
import Api from "../../helper/api";
import 'quill/dist/quill.snow.css'
import RenderRange from "./RenderRange";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  width: "800.88px",
  padding: "0 10px",
  height: "100%",
  overflow: "scroll",
  height: "100%",
  display: "block",

  borderRadius: "6px",
  "&:focus": {
    outline: "none",
  },
};

const ReportPreview = () => {
  const [open, setOpen] = React.useState(true);
  const api = new Api();
  const params = useParams();
  const [patience, setPatience] = useState();
  const [report, setReport] = useState();
  const [test, setTest] = useState([]);
  const [macro, setMacro] = useState(null);
  const [layout, setLayout] = useState(null);

  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const reportContent = useRef();


  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: params.id })
    setPatience(data);

    const investigation = data?.investigation?.find((item) => item._id === parseInt(params.reportId))
    const macro = data?.testResult?.find((item) => item.investigationId === parseInt(params.reportId))
    setReport(investigation)

    if (macro) {
      setMacro(macro)
    }
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);

  useEffect(() => {
    const fetchTest = async () => {
      try {

        let test = [];

        for (let i = 0; i < report?.reportFormat?.length; i++) {
          const id = report?.reportFormat[i]

          const { data, status } = await api.fetchReportById({}, { id });

          if (status === 200) {
            if (data.report?.reportsubcategory?.length > 0) {
              for (let i = 0; i < data?.report?.reportsubcategory?.length; i++) {
                const testId = data?.report?.reportsubcategory[i]?._id;

                const testExist = test.find((test) => test._id === testId)

                if (!testExist) {

                  test = [
                    ...test,
                    {
                      ...data?.report?.reportsubcategory[i],
                      reportName: data?.report?.reportName,
                      reportId: data?.report?._id
                    }
                  ]
                }
              }
            }
          }
        }

        let testClone = test.reduce((acc, curr) => {

          const id = curr?.reportId;

          if (acc[id]) {
            acc[id] = [...acc[id], curr]
          } else {
            acc[id] = [curr]
          }

          return acc
        }, {})

        setTest(testClone)

      } catch (err) {
        console.log(err)
      }
    }

    if (report?.reportFormat?.length > 0) {
      fetchTest()
    }

  }, [report?._id])


  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
  });

  const handleClose = () => {
    setOpen(false);
    navigate(`/list-patient`);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-y-scroll "
    >
      <Box sx={style} className="">
        <div >
          <header className="flex justify-between border-b border-[#C9C9C9] px-6 py-6 ">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Report preview
            </Typography>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </header>
          <main className=" px-6 py-6 " id="report-content" ref={reportContent}>
            <div className="flex items-center px-6">
              <div className="bg-[#B82C3A] w-fit p-3 text-white font-semibold">
                LOGO
              </div>
              <div className="flex-1 grid justify-center">
                <h1 className="text-[#B82C3A] text-lg font-medium">
                  Naglikar diagnostic labouratory
                </h1>
                <p className="text-xs text-[#B5B5C3]">
                  Nariguddenahalli, kuvempu road, Bangalore -560064
                </p>
              </div>
            </div>
            <div className="bg-[#B82C3A] h-1 w-full my-6"></div>
            <ul className="flex justify-between max-w-2xl mx-auto text-[#464E5F] font-medium">
              <div className="flex-1">
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Patient No</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?._id}</div>
                </li>
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Patient name</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?.firstName} {patience?.lastName}</div>
                </li>

                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Age/Sex</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{`${patience?.age}yrs`} / {patience?.gender}</div>
                </li>

                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Reffered by</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?.refferedBy?.name}</div>
                </li>
              </div>
              <div className="flex-1">
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Bill No</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{`${patience?._id}${report?._id}`}</div>
                </li>
                {/* <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Bill Date</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">
                    {formatedDateInDigit(report?.date)}{" "}
                    {formatTime(report?.date)}
                  </div>
                </li> */}

                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Phone Number</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?.phone}</div>
                </li>

                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Bill Type</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?.payment[0]?.mode}</div>
                </li>
                <li
                  className="my-2.5 flex items-center max-w-[300.65px]"
                >
                  <div className="flex-[0.8]">Sample from</div>
                  <div className="flex-[0.2]">:</div>
                  <div className="flex-1">{patience?.sampleFrom}</div>
                </li>
              </div>
            </ul>

            {
              macro?.layout ? <div className="ql-editor">
                <div className="reportLayout">
                  <div dangerouslySetInnerHTML={{ __html: macro?.layout }}>
                  </div>
                </div>
              </div>
                :
                <>
                  <div className="grid grid-cols-[1fr_100px_100px_1fr]  my-5 border-y border-#606060]/60 py-4 text-[#464E5F] font-semibold text-sm max-w-[650px] mx-auto">
                    <div>Test Name</div>
                    <div>Results</div>
                    <div>Units</div>
                    <div className="text-center">Normal Range</div>
                  </div>

                  <div>
                    <h1 className=" font-semibold my-10 text-center text-[rgb(70,78,95)] underline underline-offset-2">
                      {report?.category?.name}{" "}
                    </h1>
                    {
                      Object.keys(test).map((id) => {
                        return <>
                          <div className="grid grid-cols-[1fr_100px_100px_1fr]  my-2  font-semibold text-sm max-w-[650px] mx-auto ">
                            <div><span>{test[id][0]?.reportName}</span></div>

                          </div>
                          {
                            test[id].map((subcat) => {
                              const result = macro?.testResult?.find((item) => item.testId === subcat._id)
                              return (
                                <>
                                  <div className="grid grid-cols-[1fr_100px_100px_1fr] text-[#464E5F]/90  my-1  text-sm max-w-[650px] mx-auto">
                                    <div>{subcat?.name}</div>
                                    <div>{result?.result}</div>
                                    <div>{subcat?.units}</div>
                                    <div className="text-center">

                                      {
                                        patience?.gender === "male"
                                          ? <RenderRange patient={patience} range={subcat?.rangeForMale} unit={subcat?.units} />
                                          : <RenderRange patient={patience} range={subcat?.rangeForFemale} unit={subcat?.units} />
                                      }
                                    </div>
                                  </div>

                                </>
                              );
                            })
                          }
                        </>
                      })
                    }

                  </div>
                </>
            }


            {
              macro?.macroHtml &&
              <div className="ql-editor">
                <div dangerouslySetInnerHTML={{ __html: macro?.macroHtml }}>
                </div>
              </div>
            }

            <div className="text-center flex justify-between align-middle text-sm mt-10 mb-2">
              {
                macro?.enteredBy &&
                <h1 className="font-medium">Entered By: {macro.enteredBy.replace(/^\w/, c => c.toUpperCase())}</h1>
              }
              {
                macro?.verifiedBy &&
                <h1 className="font-medium">Verified By: {macro.verifiedBy.replace(/^\w/, c => c.toUpperCase())}</h1>
              }
            </div>
            <div className="text-center text-sm ">
              <h1 className="font-medium text-center">***End of report***</h1>
            </div>
            <div className="my-10">
              <div className="flex flex-col items-end">
                <button
                  onClick={generatePDF}
                  className="bg-[#B82C3A] py-2 px-4 text-white font-semibold"
                >
                  Signature
                </button>
                <p className="text-sm my-2">Lab Technologist</p>
              </div>

              <div className="bg-[#B82C3A] h-1 w-full mt-6"></div>
              <div className="flex items-center text-sm justify-between">
                <p className="text-[#B5B5C3]">Thanks for refferal</p>
                <p className="text-[#464E5F]">Phone number: 9876543210</p>
              </div>
            </div>
          </main>
        </div>
      </Box>
    </Modal>
  );
};

export default ReportPreview;
