import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import { DropdownSvg, FilterSvg, ResetFilterSvg, UserSvg } from "../../Components/Shared/UserSvg";
import PopoverMenu from "../../Components/Shared/Popover";
import { formatedDateInDigit } from "../../helper/helper";
import { useReactToPrint } from "react-to-print";
import { FiSearch } from "react-icons/fi";
import FilterCategory from "../MasterData/FilterCategory";


const PatienceCardList = () => {
  const tableclasses = tableStyles();
  const navigate = useNavigate();

  const [rows, setRows] = useState();
  const [newData, setNewData] = useState(false);
  const reportContent = useRef();
  const [filter, setFilter] = useState({
    date: null,
    status: null
  });
  const [filterData, setFilterData] = useState({
    date: "",
    status: ""
  })
  const [search, setSearch] = useState("")

  const handleReset = () => {
    setFilterData({
      date: "",
      status: ""
    })
  }

  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
    // onAfterPrint:alert("Pdf Downloaded")
  })

  const TOKEN = localStorage.getItem("logintoken");

  const fetchData = async () => {
    const { date, status } = filterData
    const data = await axios.get(`${API}/get-patient-card?date=${JSON.stringify(date)}&status=${status}&search=${search}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setRows(data?.data?.patients);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  // const filteredData = data.filter(item =>
  //   item.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    fetchData();
  }, [newData, filterData.date, filterData.status, search]);

  useEffect(() => {
    if (!TOKEN) {
      navigate("/");
    }
  }, [TOKEN]);

  const handleEdit = (id) => {
    navigate(`/edit-patient-card/${id}`);
  };

  const handleDelete = async (id) => {
    const data = await axios.delete(`${API}/delete-patient-card/${id}`, {
      headers: { authtoken: `${TOKEN}` },
    });

    if (data?.data?.message === "Card removed successfully") {
      setNewData(true);
      toast.success("Card removed successfully");
      setNewData(false);
    }
  };

  const handleView = (id) => {
    navigate(`/view-patient-card/${id}`);
  };

  const header = [
    "SL NO",
    "Patient Name",
    "Patient ID",
    "LAB",
    "Created Dated",
    "Refered BY",
    "Phone",
    "No of Visits",
    "status",
    "Options"
  ];

  const status = [
    "Active", "InActive"
  ]
  return (
    <div className={tableclasses.root}>
      <FilterCategory status={status} setFilter={setFilter} filter={filter}
        filterData={filterData} setFilterData={setFilterData} />
      <div className={tableclasses.body}>
        <div className={tableclasses.header}>
          <div className={tableclasses.name}>
            <div className={tableclasses.h2}>Patient Cards</div>
            <div className={tableclasses.specification}>
              {rows?.length} available cards
            </div>
          </div>
          <div>
            <Buttons className={tableclasses.addButton3}
              onClick={() => navigate("/add-patience-cards")}
            >
              <UserSvg /> &nbsp; Create new Patient Card
            </Buttons>
          </div>
        </div>

        <div className={tableclasses.filterSearch} >
          <div>
            <Buttons className={tableclasses.filterButton1}> <FilterSvg /></Buttons>
            <Buttons
              className={tableclasses.filterButton2}
            >
              Filter
            </Buttons>
            <Buttons
              className={tableclasses.filterButton2}
              onClick={(e) => setFilter((prev) => { return { ...prev, date: e.currentTarget } })}>
              Date
              <DropdownSvg />
            </Buttons>
            <Buttons className={tableclasses.filterButton2}
              onClick={(e) => setFilter((prev) => {
                return { ...prev, status: e.currentTarget }
              })}
            >Card Status
              <DropdownSvg /> </Buttons>
            <Buttons
              onClick={handleReset}
              className={tableclasses.filterButton3}>
              <ResetFilterSvg />
              <span style={{ color: "#FF8743", marginLeft: 6, text: "center" }}>
                Reset FIlter
              </span>{" "}
            </Buttons>
            <Buttons className={tableclasses.printButton} onClick={generatePDF}>Print</Buttons>
          </div>

          <div className={tableclasses.searchContainer}>
            <FiSearch className={tableclasses.searchIcon} />
            <TextField
              className={tableclasses.searchField}
              placeholder="Search"
              variant="standard"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <Table className={tableclasses.table} ref={reportContent}>
          <TableHead className={tableclasses.tableHead}>
            <TableRow>
              {header.map((header, i) => {
                return (
                  <TableCell className={tableclasses.customTableCell}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={tableclasses.customTableCell}
                >
                  <div className={tableclasses.profile}>
                    <div>
                      {index + 1}
                    </div>
                    <div className={tableclasses.name}>
                      <div></div>
                      <div className={tableclasses.specification}>
                        {row.specification}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.firstName}{" "}{row.lastName}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.patientId}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.labNumber}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{formatedDateInDigit(row.createdAt)}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row?.referredBy?.map((ref) => ref.name)}</div>
                </TableCell>

                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.phoneNumber}</div>
                </TableCell>


                <TableCell className={tableclasses.customTableCell}>
                  <div>0</div>
                </TableCell>

                <TableCell className={tableclasses.customTableCell}>
                  <Buttons className={row.status === "active" ? tableclasses.customActive : tableclasses.customInActive}>
                    <div style={{ color: row.status === 'active' ? 'green' : 'red' }}>
                      {row.status}</div>
                  </Buttons>
                </TableCell>

                <TableCell className={tableclasses.customTableCell}>
                  <div>
                    <PopoverMenu
                      data={rows}
                      handleEdit={() => handleEdit(row._id)}
                      handleDelete={() => handleDelete(row._id)}
                      handleView={() => handleView(row._id)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PatienceCardList;
