import React, { useEffect, useState } from "react";

import { Box, List, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { formStyles } from "../Styles/Formstyle";
import Buttons from "../Components/Shared/Buttons";
import Input from "../Components/Shared/Input";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../helper/api";

const AddReportFormatForm = () => {
  const api = new Api();
  const classes = formStyles();
  const [value, setValue] = useState("1");
  const [rows, setRows] = useState();
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setsubCategory] = useState();
  const [reportId, setReportId] = useState();
  const [reportName, setReportName] = useState();
  const [reportTat, setReportIdtat] = useState();
  const [reportshortName, setShortReportName] = useState();
  const [validation, setValidation] = useState(false);
  const [selectedTest, setSelectedTest] = useState([]);
  const navigate = useNavigate();
  const [reportE1, setReportE1] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TOKEN = localStorage.getItem("logintoken");

  const [personName, setPersonName] = React.useState([]);
  const fetchTest = async (e) => {
    try {
      const data = await axios.get(`${API}/gettestcategory`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setRows(data.data.testCategory);
    } catch (e) {
      console.log(e);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const names = [
    'Test',
    'Test',
    'Test',
    'Test',
    'Test',
    'Test',
    'Test',
  ];

  const fetchsubCategory = async (e) => {
    try {
      const data = await axios.get(`${API}/gettestsubcategory/1/100000`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setsubCategory(data.data.subTestCategory);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTest();
    fetchsubCategory();
  }, []);

  const handleSubmit = async () => {
    if (
      !reportId ||
      !reportName ||
      !reportTat ||
      !reportshortName ||
      !category ||
      !status
    ) {
      toast.error("Please Check All fields Are Entered");
    }
    try {
      const data = await api.addReportFormat({
        reportId,
        reportName,
        reportTat,
        reportshortName,
        category,
        status,
        selectedTest,
      });

      if (data?.data?.message === "Report Format saved") {
        toast.success(data?.data?.message);
        navigate("/add-report-format");
      } else {
        toast.error(data?.data?.errors);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const handleChange2 = (event) => {
    const { value } = event.target;

    if (value[value.length - 1]) {
      setSelectedTest(() => {
        return typeof value === 'string' ? value.split(',') : value
      })
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.collectorForm}>
        <div className={classes.formheader}>
          <div className={classes.formnameHead}>
            <div className={classes.formh2}>Add New Report Group Format </div>
            <div className={classes.littlehead}>
              {/* {rows?.length} */} You can create new report group here
            </div>
          </div>
          <div>
            <Buttons
              className={classes.formButton}
              onClick={() => navigate("/add-report-format")}
            >
              &nbsp; Back to  table
            </Buttons>
          </div>
        </div>

        <div>
          <Box sx={{ padding: "0 8%", borderColor: "blue" }}>
            <TabContext value={value} className={classes.tabs}>
              <Box sx={{ padding: "0 30%" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{ "& .MuiTabs-indicator": { backgroundColor: "black" } }}
                >
                  <Tab
                    label="New Report Group Format"
                    className={classes.tablist}
                    value="1"
                  />
                  <Tab label="Add Test" className={classes.tablist} value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{}}>
                <div>
                  <div className={classes.formMain}>
                    <FormControl>
                      <div className={classes.formDiv1}>
                        <div className={classes.formDiv2}>
                          {/* <div className={classes.formHeading}>
                            New Report Group Format
                          </div> */}
                          <div className={classes.formLable}>Report ID</div>
                          <Input
                            type="text"
                            placeholder="Enter ID "
                            className={classes.formInput}
                            value={reportId}
                            onChange={(e) => setReportId(e.target.value)}
                          />
                          {!reportId && validation ? (
                            <p style={{ color: "red", fontSize: '13px', }}>reportId is Required</p>
                          ) : null}{" "}
                          <br />
                          <div className={classes.formLable}>Report group</div>
                          <FormControl>
                            <Select
                              value={category}
                              onChange={(event) =>
                                setCategory(event.target.value)
                              }
                              className={classes.selectInput}
                              MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                              displayEmpty
                            >
                              <MenuItem value={""}>
                                Select
                              </MenuItem>
                              {rows?.map((option) => (
                                <MenuItem key={option._id} value={option._id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {!category && validation ? (
                            <p style={{ color: "red", fontSize: '13px', }}>Category is Required</p>
                          ) : null}{" "}
                          <br />
                          <div className={classes.formLable}>Sample name</div>
                          <FormControl>
                            <Select
                              className={classes.selectInput}
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                <p>Select</p>
                              </MenuItem>
                              <MenuItem value="blood">Blood</MenuItem>
                              <MenuItem value="urine">Urine</MenuItem>
                            </Select>
                          </FormControl>{" "}
                          <br />
                        </div>
                        <div className={classes.formDiv31} style={{
                          marginTop: '0px',
                        }}>
                          <div className={classes.formLable}>Report name</div>
                          <Input
                            type="text"
                            placeholder="Enter Report name"
                            className={classes.formInput}
                            value={reportName}
                            onChange={(e) => setReportName(e.target.value)}
                          />{" "}
                          <br />
                          <div className={classes.formLable}>
                            Report short name
                          </div>
                          <Input
                            type="text"
                            placeholder="Enter Short name"
                            className={classes.formInput}
                            value={reportshortName}
                            onChange={(e) => setShortReportName(e.target.value)}
                          />
                          {!reportshortName && validation ? (
                            <p style={{ color: "red", fontSize: '13px', }}>
                              ReportshortName is Required
                            </p>
                          ) : null}{" "}
                          <br />
                          <div className={classes.formLable}>
                            Time arount test(TAT) in minutes
                          </div>
                          <Input
                            type="number"
                            placeholder="Enter Default value"
                            className={classes.formInput}
                            value={reportTat}
                            onChange={(e) => setReportIdtat(e.target.value)}
                          />{" "}
                          <br />
                        </div>
                      </div>
                      <div className={classes.formDiv4}>
                        <Buttons
                          onClick={() => navigate("/add-report-format")}
                          className={classes.cancelButton}
                        >
                          Cancel
                        </Buttons>
                        <Buttons
                          onClick={() => {
                            setValue("2");
                          }}
                          className={classes.submitButton}
                        >
                          {validation ? "Add Test Data" : "Next"}
                        </Buttons>
                      </div>
                    </FormControl>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{}}>
                <div className={classes.formHeading}>List Test Available</div>
                <div>
                  <FormControl
                    style={{
                      width: '75%',
                      margingLeft: '8px',
                      padingLeft: '8px',
                      '&.MuiInputLabel-shrink': {
                        transform: 'translate(0, 1.5px) scale(0)',
                      },
                      '&.MuiFormLabel-root': {
                        padingLeft: '8px',
                      },
                    }}>
                    <div className={classes.formLable}>
                      Select Test
                    </div>
                    <InputLabel id="demo-multiple-checkbox-label"
                      style={{
                        width: '80%',
                        padingLeft: '8px',
                        position: 'absolute',
                        top: '26px',
                        left: '12px',
                        fontSize: '14px',
                        fontFamily: "Poppins",

                        '&.MuiInputLabel-shrink': {
                          transform: 'translate(0, 1.5px) scale(0)',
                        },
                        '&.PrivateNotchedOutline-legendLabelled-39 > span': {
                          display: 'none',
                        },
                      }}>
                    </InputLabel>
                    <Select
                      className={classes.selectInput}
                      style={{ width: '720px' }}
                      multiple
                      value={selectedTest}
                      open={reportE1}
                      onClose={() => setReportE1(false)}
                      onOpen={() => setReportE1(true)}
                      onChange={handleChange2}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                    >
                      <div style={{
                        paddingLeft: '16px',
                        backgroundColor: '#FAFAFA',
                        borderBottom: '1px solid #c4c4c4',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '34%',
                      }}>
                        <List>Test&nbsp;Name</List>
                        <List>Test&nbsp;ID</List>
                      </div>
                      {subcategory?.map((name) => (
                        <MenuItem style={{}} key={name._id} value={name._id}>
                          <ListItemText className={classes.menuItem} primary={name.name} />
                          <ListItemText primary={name.id} />
                          <Checkbox
                            color="default"
                            checked={selectedTest.indexOf(name._id) > -1}
                          />
                        </MenuItem>
                      ))}

                      <Button
                        onClick={() => setReportE1(false)}
                        variant="contained"
                        style={{
                          marginLeft: '40%',
                          backgroundColor: '#B82C3A',
                          color: '#FFFFFF',
                          marginTop: '20px',
                        }}>
                        Proceed
                      </Button>
                    </Select>
                  </FormControl>

                </div>
                <div className={classes.formDiv4}>
                  <Buttons
                    className={classes.cancelButton}
                    onClick={() => navigate("/add-report-format")}
                  >
                    Cancel
                  </Buttons>
                  <Buttons
                    className={classes.submitButton}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Buttons>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddReportFormatForm;
