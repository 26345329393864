const view = [
    "register-user", "add-report-format", "add-price-list", "list-patience", "patience-cards", "assign-collector"
]

const entry = [
    "patient-report"
]

const edit = [
    "register-doctor", "register-user", "register-collector", "add-report-group", "add-test", "add-report-format", "add-price-list",
    "get-account-list", "get-sample-list", "list-patience", "patience-cards", "visitor-book"
]

const layout = [
    "patient-report"
]

const verify = [
    "patient-report"
]

const preview = [
    "patient-report"
]

const macros = [
    "patient-report"
]

const Delete = [
    "register-doctor", "register-user", "register-collector", "add-report-group", "add-test", "add-report-format", "add-price-list",
    "get-account-list", "get-sample-list", "list-patience", "patience-cards", "assign-collector", "visitor-book"
]

const report = [
    "list-patience"
]

const assign = [
    "list-patience"
]

const accessRight = [
    "register-user"
]

const print = [
    "patient-report"
]

const priceList = [
    "register-doctor"
]

const options = {
    "view": view,
    "entry": entry,
    "edit": edit,
    "layout": layout,
    "verify": verify,
    "preview": preview,
    "macros": macros,
    "delete": Delete,
    "report": report,
    "assign": assign,
    "accessRight": accessRight,
    "print": print,
    "priceList": priceList
}

export default options;