import React, { useEffect, useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from '../../Components/Shared/Buttons';
import Input from "../../Components/Shared/Input";
import Api from '../../helper/api';
import { toast } from 'react-toastify';

const UploadImg = ({ setUpload, setShowReport, setReFetch, reFetch, id }) => {
  const tableclasses = tableStyles();
  const api = new Api();
  const classes = formStyles();
  const [slip, setSlip] = useState([
    {
      _id: 1,
      src: "",
      note: ""
    },
    {
      _id: 2,
      src: "",
      note: ""
    },
    {
      _id: 3,
      src: "",
      note: ""
    }
  ])
  const totalImages = [0, 1, 2]

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const { data, status } = await api.fetchPatientById({}, { id: id });

        if (data.refSlipImg) {
          const clone = [...slip]

          for (let i = 0; i < totalImages.length; i++) {
            if (data.refSlipImg[i]) {
              clone.splice(i, 1, {
                _id: data.refSlipImg[i]?._id,
                src: data.refSlipImg[i]?.src,
                note: data.refSlipImg[i]?.note
              })
            }
          }
          setSlip(clone)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchPatient();
  }, []);

  const uploadImg = async (index, e) => {
    e.preventDefault();

    if (e.target.files[0].type === "application/pdf") {
      alert("Please upload Images")
    } else {

      try {
        const { data, status } = await api.uploadSlip({
          image: e.target.files[0]
        }, { id: id })

        if (status === 200) {
          if (data.length > 0) {
            const clone = [...slip]
            clone.splice(index, 1, {
              _id: slip[index]._id,
              src: data[0].src,
              note: slip[index].note
            })

            setSlip(clone)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleNote = (index, e) => {
    const cloned = [...slip];
    cloned.splice(index, 1, {
      _id: slip[index]._id,
      src: slip[index].src,
      note: e.target.value
    })

    setSlip(cloned)
  }

  const handleSave = async () => {
    try {
      const { data, status } = await api.updatePatientSlip(slip, { id: id })

      if (status === 200) {
        toast.success("Saved successfully")
        setReFetch(!reFetch)
        setTimeout(() => {
          setUpload(false)
        }, 1500);
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async (index) => {
    try {
      const cloned = [...slip]
      cloned.splice(index, 1, {
        _id: slip[index]._id,
        src: "",
        note: slip[index].note
      })

      setSlip(cloned)

      const { data, status } = await api.updatePatientSlip(cloned, { id: id })

      if (status === 200) {
        toast.success("Deleted successfully")
        setReFetch(!reFetch)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className={tableclasses.outBackground}>
      <div className={`${tableclasses.sampleCollection} ${tableclasses.headSample}`} style={{ marginTop: '85px', width: 'fit-content', textAlign: 'left', paddingBottom: '20px' }}>
        <div
          className={tableclasses.findHeader}
        >
          <div>Reference slip image</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            style={{ cursor: 'pointer' }}
            onClick={() => setUpload(false)}
          >
            <path
              d="M1 17L17 1M1 1L17 17"
              stroke="black"
              stroke-opacity="0.7"
              stroke-width="1.88067"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div style={{ display: 'flex', gridGap: '30px', margin: '15px 20px' }}>
          {
            totalImages.map((index) => (
              <div
                key={index}
                style={{ width: '220px' }}
                className={classes.forjmDiv3}
              >
                <div className={classes.formLable} style={{ fontWeight: '400' }}>
                  {`image${index + 1}`}
                </div>
                <div className={classes.refslip}
                  style={{
                    minWidth: "215px",
                    height: "120px",
                    marginTop: "9px",
                    padding: "10px 20px"
                  }} >
                  {
                    slip[index].src ? <div style={{ position: 'relative', }}>
                      <img
                        src={slip[index].src}
                        style={{
                          width: "70px",
                          height: "80px",
                          objectFit: "cover",
                        }}
                      />
                      <div className={classes.vector} onClick={() => setShowReport({ show: true, src: slip[index].src })}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M0.00200907 14.9975L0 24H9.00347L6.09413 21.091L12.0917 15.0937L8.90707 11.909L2.90951 17.9063L0.00200907 14.9975ZM14.9996 0.000457341L17.906 2.90654L11.9056 8.90671L15.0902 12.091L21.0907 6.0908L24 9.00026V0L14.9996 0.000457341Z"
                            fill="#666666"
                          />
                          <path
                            d="M9.00254 0.00200877L1.90735e-06 -4.80825e-07L1.04868e-06 9.00347L2.909 6.09413L8.90631 12.0917L12.091 8.90707L6.09366 2.90951L9.00254 0.00200877ZM23.9995 14.9996L21.0935 17.906L15.0933 11.9056L11.909 15.0902L17.9092 21.0907L14.9997 24L24 24L23.9995 14.9996Z"
                            fill="#666666"
                          />
                        </svg>
                      </div>
                    </div>
                      :
                      <Buttons component="label" className={classes.filterButton2}>Upload Image
                        <input type="file" name="uploadImg" style={{ display: "none" }} onChange={(e) => uploadImg(index, e)} id="uploadImg" />
                      </Buttons>
                  }
                </div>
                {" "}
                <br />
                <Buttons
                  onClick={() => handleDelete(index)}
                  className={classes.filterButton1}
                >
                  Delete
                </Buttons>
                <br />
                <div className={classes.formLable} style={{ fontWeight: '400' }}>
                  {`Note${index + 1}`}
                </div>
                <Input
                  type="text"
                  placeholder="Enter"
                  className={classes.formInput2}
                  style={{ width: "215px" }}

                  //   name="patId"
                  value={slip[index].note}
                  onChange={(e) => handleNote(index, e)}
                //   onBlur={handleBlur}
                />
                {/* {errors.patId && touched.patId && (
                        <div style={{ color: "red" }}>{errors.patId}</div>
                      )} */}
                <br />
              </div>
            ))
          }

        </div>
        <div className={tableclasses.formDiv4} style={{ marginRight: '10px', justifyContent: 'end', display: 'flex' }}>
          <Buttons
            className={classes.cancelButton}
            onClick={() => setUpload(false)}
          >
            Cancel
          </Buttons>
          <span className="px-1"></span>
          <Buttons
            className={classes.submitButton}
            onClick={handleSave}
          >
            Save
          </Buttons>
        </div>
      </div>
    </div>
  )
}

export default UploadImg
