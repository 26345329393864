import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { InputLabel, List, ListItemText } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "./Buttons";
import axios from "axios";
import { API } from "../../config";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setValues } from "../../redux/findPatientSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        fontSize: "16px",
        display: "flex",
        width: "450px",
        justifyContent: "center",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AssignCollector = ({ assign, setAssign, patienceId }) => {
  const classes = formStyles();
  const { reFetch } = useSelector(state => state.findPatient)
  const dispatch = useDispatch();
  const [rows, setRows] = useState();
  const [collectorId, setCollector] = useState("");

  const handleClose = () => {
    setAssign(false);
  };

  const TOKEN = localStorage.getItem("logintoken");

  const fetchData = async () => {
    const data = await axios.get(`${API}/getcollectorlist`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setRows(data.data.collector);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const assignCollector = async (event) => {
    event.preventDefault();
    const { data, status } = await axios.post(
      `${API}/assigncollection`,
      { patienceId, collectorId },
      {
        headers: { authtoken: `${TOKEN}` },
      }
    );

    if (status === 201) {
      toast.success("collector assigned successfully");
      dispatch(setValues({ name: "reFetch", value: !reFetch }))
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "390px",
      },
    },
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title2"
        open={assign}
        width="500px"
        maxHeight="300px"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title2"
          onClose={handleClose}
        >
          Assign Sample collection
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form>
            <div className={classes.formMain} style={{ padding: "20px 0" }}>
              <FormControl
                style={{
                  margingLeft: "8px",
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(0, 1.5px) scale(0)",
                  },
                  "&.MuiFormLabel-root": {
                    paddingRight: "8px",
                  },
                  width: "80%",
                  margin: "auto",
                }}
              >
                <div style={{ fontSize: "15px", color: "#181C32" }}>
                  Sample collector{" "}
                </div>
                <Select
                  className={classes.selectInput}
                  placeholder="Select"
                  label="Select"
                  value={collectorId}
                  onChange={(e) => setCollector(e.target.value)}
                  MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                  style={{
                    marginBottom: 0,
                    marginTop: "10px",
                    width: "100%",

                  }}
                  displayEmpty
                >
                  <div
                    style={{
                      display: "flex",
                      color: "#B5B5C3",
                      justifyContent: "space-around",
                      height: "30px",
                      borderBottom: "1px solid #B5B5C3",
                      width: "380px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    <List>Select</List>
                    <List>Location</List>
                  </div>
                  {rows?.map((row, i) => {
                    return (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        style={{
                          backgroundColor: "transparent",
                          display: "flex",
                          justifyContent: "space-around",
                          width: "380px",
                          color: "#464E5F",
                          fontWeight: "600",
                        }}
                      >
                        <p style={{ width: "70px" }}>{row.name}</p>
                        <p style={{ width: "70px" }}>{row.location}</p>
                      </MenuItem>
                    );
                  })}
                </Select>
                <p style={{ color: "#C9C9C9", fontSize: "14px" }}>
                  Assign to collect sample
                </p>
              </FormControl>
            </div>
          </form>
        </DialogContent>
        <div
          className={classes.formDiv4}
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <Buttons
            className={classes.cancelButton}
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Buttons>
          <Buttons onClick={assignCollector} className={classes.submitButton}>
            Submit
          </Buttons>
        </div>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AssignCollector;
