import React, { useEffect, useRef, useState, useMemo } from "react"
import 'quill/dist/quill.snow.css'
import ReactQuill, { Quill } from 'react-quill'
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { macroStyles } from "../../Styles/Macro";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "./Buttons";
import { toast } from "react-toastify";
import Api from "../../helper/api";
import { formatTime, formatedDate, formatedDateInDigit } from "./../../helper/helper";
import { useReactToPrint } from "react-to-print";

import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';
import CustomImage from "./CustomImage";
import { setValues } from "../../redux/findPatientSlice";
import { useDispatch, useSelector } from "react-redux";


Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register("formats/image", CustomImage)



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  height: "100%",
  width: "800.88px",
  padding: "0 0px",
  overflow: "scroll",
  display: "block",

  borderRadius: "6px",
  "&:focus": {
    outline: "none",
  },
};

const AddMacro = ({ handleClose, macroId, id }) => {
  const api = new Api();
  const classes = formStyles();
  const macroclasses = macroStyles();
  const [preview, setPreview] = useState(false);
  const [value, setValue] = useState("");

  const dispatch = useDispatch()
  const { reFetch } = useSelector(state => state.findPatient)


  const [open, setOpen] = React.useState(true);
  const macros = useRef(null)
  const [patience, setPatience] = useState();
  const [report, setReport] = useState();
  const [test, setTest] = useState([]);
  const reportContent = useRef();


  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: id })
    setPatience(data);

    const investigation = data.investigation.find((item) => item._id === parseInt(macroId))
    setReport(investigation)
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchTest = async () => {
      try {

        for (let i = 0; i < report.reportFormat.length; i++) {
          const id = report?.reportFormat[i]

          const { data, status } = await api.fetchReportById({}, { id });

          if (status === 200) {
            if (data.report?.reportsubcategory.length > 0) {
              for (let i = 0; i < data.report?.reportsubcategory.length; i++) {
                const testId = data.report.reportsubcategory[i]._id;

                const testExist = test.find((test) => test._id === testId)

                if (!testExist) {
                  setTest((prev) => {
                    return [...prev, data.report.reportsubcategory[i]]
                  })
                }
              }
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (report?.reportFormat.length > 0) {
      fetchTest()
    }

  }, [report?._id])



  const fetchTest = async () => {
    try {
      const { data, status } = await api.fetchTestById({}, {
        patient: id,
        investigationId: macroId
      })

      if (data.data?.macroHtml) {

        setValue(data.data.macroHtml)
      }

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (macroId) {
      fetchTest()
    }
  }, [macroId])

  // var modules = {
  //   toolbar: [
  //   ]
  // };

  const imageHandler = async () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      var file = input.files[0];

      try {
        const { data, status } = await api.uploadSlip({ image: file }, { id: "" });

        const range = macros.current.getEditorSelection();
        var res = data[0].src;

        macros.current.getEditor().insertEmbed(range.index, 'image', res);

      } catch (err) {
        console.log(err)
      }
    };
  }

  const modules = useMemo(() => (
    {
      toolbar: {
        container: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
          [{ size: ["small", false, "large", "huge"] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] }
          ],
          ['link', 'image'],
          [{ 'align': [] }],
          [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ],
        'handlers': {
          image: imageHandler
        },
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      },
      blotFormatter: {},
    }
  ), [])

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size", "width", "style"
  ];

  const handleProcedureContentChange = (content) => {
    setValue(content)
  };


  const saveTemplate = async () => {
    try {
      const { data, status } = await api.updateMacro({
        investigationId: macroId,
        patient: id,
        macroHtml: value
      })

      if (status === 200) {
        toast.success("Saved Successfully")
        dispatch(setValues({ name: "reFetch", value: !reFetch }))
        handleClose()
      }
    } catch (err) {
      toast.error("Error Saving!")
    }
  }

  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
    // onAfterPrint:alert("Pdf Downloaded")
  });
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-y-scroll px-0"
    >
      <Box sx={style} >
        <div >
          <header className="flex justify-between  px-6 py-6 bg-[#FAFAFA]">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={macroclasses.name}
            >
              Macros Template
            </Typography>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </header>

          {
            preview
              ? <div className="flex gap-4 border-b border-t border-[rgba(201, 201, 201, 1)] py-4 px-[50px]
            text-[14px] font-[Poppins]">
                <button
                  onClick={() => setPreview(false)}
                  className={`bg-white px-4 h-[32px] text-[#273142] font-medium 
        text-center rounded-[43px] border border-[#273142]`}
                >
                  Edit
                </button>
                <button
                  onClick={() => setPreview(true)}
                  className={`bg-[#273142] text-center px-4 text-white font-medium rounded-[43px]`}
                >
                  Preview
                </button>
              </div>
              : <div className="flex gap-4 border-b border-t border-[rgba(201, 201, 201, 1)] py-4 px-[50px]
            text-[14px] font-[Poppins]">
                <button
                  onClick={() => setPreview(false)}
                  className={`bg-[#273142] text-center px-4 text-white font-medium rounded-[43px]`}
                >
                  Edit
                </button>
                <button
                  onClick={() => setPreview(true)}
                  className={`bg-white px-4 h-[32px] text-[#273142] font-medium 
          text-center rounded-[43px] border border-[#273142]`}
                >
                  Preview
                </button>
              </div>
          }
          {
            !preview
              ? <div style={{ display: "grid", border: 'none !important', }}>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  ref={macros}
                  placeholder="write your content ...."
                  onChange={handleProcedureContentChange}
                  onChangeSelection={(value) => console.log("values,", value)}
                  value={value}
                  style={{
                    height: "380px",
                    backgroundColor: 'rgba(227, 241, 254, 0.4)',
                    '&.ql-editor': {
                      border: 'none !important',
                      backgroundColor: 'rgba(227, 241, 254, 0.4)',
                    },
                    '&.ql-toolbar.ql-snow': {
                      border: 'none',
                    },
                  }}
                >
                </ReactQuill>
              </div>
              : <Box>
                <div className="ql-editor">
                  <div id="report-content" ref={reportContent}>
                    <main className=" px-6 py-6 ">
                      <div className="flex items-center px-6">
                        <div className="bg-[#B82C3A] w-fit p-3 text-white font-semibold">
                          LOGO
                        </div>
                        <div className="flex-1 grid justify-center">
                          <h1 className="text-[#B82C3A] text-lg font-medium">
                            Naglikar diagnostic labouratory
                          </h1>
                          <p className="text-xs text-[#B5B5C3]">
                            Nariguddenahalli, kuvempu road, Bangalore -560064
                          </p>
                        </div>
                      </div>
                      <div className="bg-[#B82C3A] h-1 w-full my-6"></div>
                      <ul className="flex justify-between max-w-2xl mx-auto text-[#464E5F] font-medium">
                        <div className="flex-1">
                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Patient No</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?._id}</div>
                          </li>
                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Patient name</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.firstName} {patience?.lastName}</div>
                          </li>

                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Age/Sex</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{`${patience?.age}yrs`} / {patience?.gender}</div>
                          </li>

                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Reffered by</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.refferedBy?.name}</div>
                          </li>
                        </div>
                        <div className="flex-1">
                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Bill No</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{`${patience?._id}${report?._id}`}</div>
                          </li>
                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Bill Date</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">
                              {formatedDateInDigit(report?.date)}{" "}
                              {formatTime(report?.date)}
                            </div>
                          </li>

                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Phone Number</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.phone}</div>
                          </li>

                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Bill Type</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.payment[0]?.mode}</div>
                          </li>
                          <li
                            className="my-2.5 flex items-center max-w-[300.65px]"
                          >
                            <div className="flex-[0.8]">Sample from</div>
                            <div className="flex-[0.2]">:</div>
                            <div className="flex-1">{patience?.sampleFrom}</div>
                          </li>

                        </div>
                      </ul>
                      <div>
                        <h1 className=" font-semibold my-10 text-center text-[rgb(70,78,95)] underline underline-offset-2">
                          {/* {report?.map((repo) => repo?.reportcategory?.name)}{" "} */}
                        </h1>
                        {
                          patience?.patients?.testInfo?.map((subCategores) => {
                            return <>
                              {
                                subCategores?.subcategories?.map((subcat) => {
                                  console.log("patience subcat", subcat);
                                  return (
                                    <>
                                      <div className="grid grid-cols-[1fr_100px_100px_1fr]  my-2  font-semibold text-sm max-w-[650px] mx-auto ">
                                        <div>{subcat?.reportFormat?.map((repItem) => {
                                          return <span>{repItem?.reportName}</span>
                                        })}</div>

                                      </div>

                                      <div className="grid grid-cols-[1fr_100px_100px_1fr] text-[#464E5F]/90  my-1  text-sm max-w-[650px] mx-auto">
                                        <div>{subcat?.name}</div>
                                        <div>{subcat?.patienceresult}</div>
                                        <div>{subcat?.units}</div>
                                        <div className="text-center">
                                          {subcat?.rangeForMale?.map((ran) => {
                                            return (
                                              <>
                                                <p>
                                                  High: {ran.high}-
                                                  {subcat?.units}
                                                </p>
                                                <p>
                                                  Low: {ran.low}-
                                                  {subcat?.units}
                                                </p>
                                              </>
                                            );
                                          })}
                                        </div>
                                      </div>

                                    </>
                                  );
                                })}
                            </>
                          })
                        }

                      </div>

                      {
                        value &&
                        <div dangerouslySetInnerHTML={{ __html: value }}>
                        </div>
                      }

                      <div className="text-center text-sm my-10">
                        <h1>***End of report***</h1>
                      </div>
                      <div className="my-10">
                        <div className="flex flex-col items-end">
                          <button
                            onClick={generatePDF}
                            className="bg-[#B82C3A] py-2 px-4 text-white font-semibold"
                          >
                            Signature
                          </button>
                          <p className="text-sm my-2">Lab Technologist</p>
                        </div>

                        <div className="bg-[#B82C3A] h-1 w-full mt-6"></div>
                        <div className="flex items-center text-sm justify-between">
                          <p className="text-[#B5B5C3]">Thanks for refferal</p>
                          <p className="text-[#464E5F]">Phone number: 9876543210</p>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </Box>
          }
        </div>
        {
          !preview &&
          <div
            style={{
              marginTop: '70px',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'right',
              marginRight: '4%',
              marginBottom: "20px"
            }}>
            <Buttons className={classes.cancelButton} onClick={handleClose}>
              Cancel
            </Buttons>
            <Buttons
              className={classes.submitButton}
              onClick={saveTemplate}
            >
              Save
            </Buttons>
          </div>
        }
      </Box>
    </Modal>
  );

}

export default AddMacro;