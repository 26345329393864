import { Box, Typography } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import { formatTime, formatedDateInDigit } from "../../helper/helper";
import MaterialTableWithInput from "../../Components/MaterialTableWithInput";
import Api from "../../helper/api";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "../../Components/Shared/Buttons";


const VerifyReportEntry = () => {
  const tableclasses = tableStyles();
  const api = new Api();
  const classes = formStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [patience, setPatience] = useState();
  const [report, setReport] = useState();
  const [test, setTest] = useState([]);


  const enteredValue = patience?.subcategories?.map((pat) => pat.patienceresult)

  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: params.id })
    setPatience(data);

    const investigation = data.investigation.find((item) => item._id === parseInt(params.reportId))
    setReport(investigation)
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);

  useEffect(() => {
    const fetchTest = async () => {
      try {

        for (let i = 0; i < report.reportFormat.length; i++) {
          const id = report?.reportFormat[i]

          const { data, status } = await api.fetchReportById({}, { id });

          if (status === 200) {
            if (data.report?.reportsubcategory.length > 0) {
              for (let i = 0; i < data.report?.reportsubcategory.length; i++) {
                const testId = data.report.reportsubcategory[i]._id;

                const testExist = test.find((test) => test._id === testId)

                if (!testExist) {
                  setTest((prev) => {
                    return [...prev, data.report.reportsubcategory[i]]
                  })
                }

              }
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (report?.reportFormat.length > 0) {
      fetchTest()
    }

  }, [report?._id])

  return (
    <div className={tableclasses.root}>
      <div className={tableclasses.body}>
        <div className={tableclasses.header}>
          <div className={tableclasses.name}>
            <div className={tableclasses.specification}>
              <Box className={tableclasses.entryMain}>
                <Box className={tableclasses.entryHeader} style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
                  <Typography className={tableclasses.entryHeaderTypo}>
                    Verify report entry
                  </Typography>
                  <Buttons
                    className={classes.backToButton}
                    style={{ width: "120px" }}
                    onClick={() => navigate("/list-patient")}
                  >
                    Back to Table
                  </Buttons>
                </Box>

                <Box className={tableclasses.patienceDetailsMain}>
                  <Box>
                    <Typography className={tableclasses.patienceFont}>
                      Lab Name: {patience?.labPrefix?.name}
                    </Typography>
                    <Typography className={tableclasses.patienceFont}>
                      Patient name: {patience?.firstName} {patience?.lastName}
                    </Typography>
                    <Typography className={tableclasses.patienceFont}>
                      Age/Sex: {patience?.age}/{patience?.gender}
                    </Typography>
                  </Box>
                  <Box className={tableclasses.patienceDetailsReport}>
                    <Typography className={tableclasses.patienceFont}>
                      Patient ID: {patience?._id}
                    </Typography>
                    <Typography className={tableclasses.patienceFont}>
                      Sample no: {params?.reportId}
                    </Typography>
                    <Typography className={tableclasses.patienceFont}>
                      Report/Test:{" "}
                      {report?.category?.name}
                    </Typography>
                  </Box>
                  <Box className={tableclasses.patienceDetailstime}>
                    <Typography className={tableclasses.patienceFont}>
                      Sample From: {patience?.sampleFrom}
                    </Typography>
                    <Typography className={tableclasses.patienceFont}>
                      Entry Date:{formatedDateInDigit(patience?.createdAt)}{" "}
                      {formatTime(patience?.createdAt)}
                    </Typography>
                  </Box>
                </Box>

                <Box style={{ margingTop: "230px !important" }}>
                  <MaterialTableWithInput
                    refRange={test}
                    params={params}
                    patience={patience}
                    report={report}
                    test={test}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyReportEntry;
