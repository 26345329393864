import React, { useEffect, useRef, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import PopoverMenu from "../../Components/Shared/Popover";
import { useReactToPrint } from "react-to-print";
import { FilterSvg, ResetFilterSvg } from "../../Components/Shared/UserSvg";
import { FiSearch } from "react-icons/fi";
import { toast } from "react-toastify";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { formStyles } from "../../Styles/Formstyle";
import { useParams } from "react-router-dom";
import Api from "../../helper/api";


const ViewReport = () => {
    const api = new Api();
    const params = useParams();
    const classes = formStyles();
    const [selectedReport, setSelectedReport] = useState(0)
    const navigate = useNavigate();
    const [test, setTest] = useState([]);

    const [report, setReport] = useState(null);

    // pricelist id
    const id = params.id;

    const fetchData = async () => {

        try {
            const { data, status } = await api.fetchReportById({}, { id })

            if (status === 200) {
                setReport(data?.report);
                setTest(data?.report?.reportsubcategory)
            }
        } catch (err) {
            toast.error("Error fetching data")
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.collectorForm}>
                <div className={classes.formheader}>
                    <div className={classes.formname}>
                        <div className={classes.formh2}>{report?.reportName} </div>
                        <div className={classes.viewHead}>
                            <div className={classes.formspecification}>Report ID: {report?.reportId}</div>
                            <div className={classes.formspecification}>
                                Category: {report?.reportcategory[0]?.name}
                            </div>
                            <div className={classes.formspecification}>TAT: {report?.reportTat}</div>
                            <div className={classes.formspecification}>
                                Sample: {report?.sample}
                            </div>
                        </div>
                        <Buttons
                            className={classes.backToButton}
                            style={{ width: "120px" }}
                            onClick={() => navigate("/add-report-format")}
                        >
                            Back to Table
                        </Buttons>
                    </div>
                </div>
                <br />
                <div className={classes.veiwcard}>

                    <div className={classes.pateintcard} style={{ width: "57%" }}>
                        <div className={`${classes.viewheading}`}>
                            <div className={`${classes.halfArea} ${classes.fontStyle}`}>
                                Test Name
                            </div>
                            <div
                                className={`${classes.halfArea} ${classes.viewHead} ${classes.fontStyle}`}
                            >
                                <p className={classes.halfhead}>Test ID</p>
                                <p>farm</p>
                            </div>
                        </div>

                        <hr className={classes.headline} />

                        {
                            test?.map((test) => (
                                <div className={classes.viewheading}>
                                    <div className={`${classes.halfArea} ${classes.insideItem}`}>
                                        {test?.name ? test?.name : "NA"}
                                    </div>
                                    <div
                                        className={`${classes.halfArea} ${classes.viewHead} ${classes.insideItem}`}
                                    >
                                        <p className={classes.halfhead}>{test?.id ? test?.id : "NA"}</p>
                                        <p>{"dummy"}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewReport;
