import React, { useEffect, useRef, useState } from 'react'
import Api from '../../helper/api'
import { useNavigate } from 'react-router-dom';
import { formStyles } from '../../Styles/Formstyleclone';
import styles from "../../Styles/Payment.module.css";
import FormControl from "@material-ui/core/FormControl";
import Selects, { components, MenuListProps } from 'react-select';
import { useFormik } from 'formik';
import { labSuffix, paymentMode } from '../../helper/data';
import Buttons from '../../Components/Shared/Buttons';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { tableStyles } from '../../Styles/AddNewDocStyle';
import { formatTime, formatedDate } from '../../helper/helper';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';


const header = [
    "SL No",
    "Paid Time",
    "Lab Ref No",
    "Patient Name",
    "Reffered by",
    "Bill Total",
    "Discount",
    "Paid Amount",
    "Due",
    "Receipt no",
    "Operator ID",
    "Pay mode"
];


const Payment = () => {
    const api = new Api();
    const navigate = useNavigate();
    const classes = formStyles();
    const tableclasses = tableStyles();
    const paymentRef = useRef();

    const printPayment = useReactToPrint({
        content: () => paymentRef.current,
        documentTitle: "Daily Payment"
    })

    const [account, setAccount] = useState([]); // lab accounts
    const [doctors, setDoctors] = useState([]);
    const [operators, setOperator] = useState([])
    const [paymentInfo, setPaymentInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1)
    const [rows, setRows] = useState([]);
    const [tab, setTab] = useState(1)
    const reportContent = useRef()
    const [totalDue, setTotalDue] = useState(0)
    const [totalBill, setTotalBill] = useState(0)

    const [newPage, setNewPage] = useState(true);
    const [paginatedData, setPaginatedData] = useState([])

    const [fromDate, setFromDate] = useState(new Date());
    const [fromTime, setFromTime] = useState("10:30")


    const [toDate, setToDate] = useState(new Date());
    const [toTime, setToTime] = useState("23:00")


    const formik = useFormik({
        initialValues: {
            labPrefix: "",
            operator: "",
            labSuffix: "",
            mode: "",
            account: "",
            doctor: ""
        },
        onSubmit: () => {
            fetchPayment()
        }
    })

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue } = formik;

    useEffect(() => {
        fetchAccount();
        fetchUser();
        fetchDoctor();
    }, []);

    // fetch lab accounts -----------------------------
    // ------------------------------------------------
    const fetchAccount = async () => {
        const data = await api.fetchAccount();
        setAccount(data?.data?.accountDetails);
    };

    // fetch user/operators ---------------------------
    // ------------------------------------------------
    const fetchUser = async () => {
        try {
            const data = await api.fetchUser();

            setOperator(data.data.users)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchDoctor = async () => {
        try {
            const { data, status } = await api.fetchDoctors()
            if (status === 200) {
                setDoctors(data.doctors)
            }
        } catch (err) {
            console.log(err)
        }
    }

    // fetch payment
    const fetchPayment = async () => {
        try {
            const { data, status } = await api.fetchPayment({
                ...values,
                fromDate,
                fromTime,
                toDate,
                toTime
            })

            if (status === 200) {
                let totalBill = 0
                let totalDue = 0

                if (newPage) {
                    let newData = [];
                    for (let i = 0; i < data.data.length; i++) {
                        newData = [...newData, ...data.data[i].data];
                    }

                    for (let i = 0; i < newData.length; i++) {
                        if (newData[i].payment?.deposits.totalBill) {
                            totalBill = totalBill + newData[i].payment?.deposits.totalBill
                        }
                        if (newData[i].payment?.deposits.totalDue) {
                            totalDue = totalDue + newData[i].payment?.deposits.totalDue
                        }

                    }

                    setRows(newData)
                    setTotalPages(Math.ceil(newData?.length / 10))
                    setTotalBill(totalBill)
                    setTotalDue(totalDue);
                } else {
                    setRows(data.data)
                    setTotalPages(Math.floor(data.data?.length / 10))
                }
                setTab(2);
            }
        } catch (err) {
            toast.error(err.response.data.message || "Internal server error")
        }
    }

    const setNextPage = () => {
        if (page > 0) {
            if (page === totalPages) return;
            setPage(page + 1);
        }
    };

    const setPrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    // // Define page size (number of items per page)
    // const pageSize = 10;

    // useEffect(() => {
    //     if (rows.length > 0) {
    //         const paginatedData = paginateData(rows, pageSize);
    //         setPaginatedData(paginatedData);
    //     }
    // }, [rows.length])

    // Function to paginate the data
    // function paginateData(data, pageSize) {
    //     const paginatedData = [];
    //     let currentPage = 1;

    //     for (let i = 0; i < data.length; i++) {
    //         const pageContent = [];
    //         const content = data[i].data;
    //         console.log("content", content[0])

    //         for (let j = 0; j < content.length; j++) {
    //             pageContent.push(content[j]);

    //             if (pageContent.length === pageSize || j === content.length - 1) {
    //                 paginatedData.push({ page: currentPage, content: pageContent });
    //                 pageContent.length = 0; // Clear the page content
    //                 currentPage++;
    //             }
    //         }
    //     }

    //     return paginatedData;
    // }

    // Paginate the data

    // console.log("paginateddata", paginatedData)

    // // Output the paginated data
    // paginatedData.forEach(page => {
    //     console.log(`Page ${page.page}:`);
    //     page.content.forEach(item => {
    //         console.log(` - ${item}`);
    //     });
    //     console.log("-------------------------");
    // });

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {
                    tab === 1 ?
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", margin: "15px 0" }}>
                                <h5>Daily payment details</h5>
                            </div>


                            <div className={styles.form}>
                                <div>
                                    <div className={styles.formLable}>
                                        From Date
                                    </div>
                                    <div style={{ width: "95%" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <DatePicker
                                                    onChange={(value) => setFromDate(value.$d)}
                                                    value={dayjs(fromDate)}
                                                    sx={{ marginTop: "2px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        From Time
                                    </div>
                                    <div style={{ width: "95%" }}>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <TimePicker
                                                    onChange={(value) => setFromTime(`${value.$H}:${value.$m}`)}
                                                    value={dayjs(`2023-04-17T${fromTime}`)}
                                                    sx={{ marginTop: "2px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>

                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        To Date
                                    </div>
                                    <div style={{ width: "95%" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <DatePicker
                                                    onChange={(value) => setToDate(value.$d)}
                                                    value={dayjs(toDate)}
                                                    sx={{ marginTop: "2px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        To Time
                                    </div>
                                    <div style={{ width: "95%" }}>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <TimePicker
                                                    onChange={(value) => setToTime(`${value.$H}:${value.$m}`)}
                                                    value={dayjs(`2023-04-17T${toTime}`)}
                                                    sx={{ marginTop: "2px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div>
                                    <div className={styles.formLable}>Prefix/account ID</div>
                                    <FormControl style={{ width: "95%" }}>
                                        <Selects
                                            options={[{ value: "", label: "All" }, ...account?.map((account) => {
                                                return {
                                                    value: account?._id,
                                                    label: account?.prefix
                                                }
                                            })]}
                                            value={
                                                values.labPrefix ?
                                                    {
                                                        value: account?.find((account) => account._id === values.labPrefix)?._id,
                                                        label: account?.find((account) => account._id === values.labPrefix)?.prefix
                                                    }
                                                    : ""
                                            }
                                            placeholder="All"
                                            styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "11px" }) }}
                                            onBlur={() => setFieldTouched("labPrefix")}
                                            onChange={(value) => setFieldValue("labPrefix", value.value)}
                                        />
                                    </FormControl>{" "}
                                </div>

                                <div>
                                    <div className={styles.formLable}>Lab Suf*</div>
                                    <FormControl style={{ width: "95%" }}>

                                        <Selects
                                            options={labSuffix}
                                            value={
                                                values.labSuffix ?
                                                    {
                                                        value: labSuffix?.find((labSuffix) => labSuffix.value == values.labSuffix)?.value,
                                                        label: labSuffix?.find((labSuffix) => labSuffix.value == values.labSuffix)?.label
                                                    }
                                                    : ""
                                            }
                                            placeholder="All"
                                            styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "11px" }) }}
                                            onBlur={() => setFieldTouched("labSuffix")}
                                            onChange={(value) => setFieldValue("labSuffix", value.value)}
                                        />
                                    </FormControl>{" "}
                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        Operator
                                    </div>
                                    <FormControl style={{ width: "95%" }}>

                                        <Selects
                                            options={[{ value: "", label: "All" }, ...operators?.map((operator) => {
                                                return {
                                                    value: operator?._id,
                                                    label: operator?.name
                                                }
                                            })]}
                                            value={
                                                values.operator ?
                                                    {
                                                        value: operators?.find((operator) => operator._id === values.operator)?._id,
                                                        label: operators?.find((operator) => operator._id === values.operator)?.name
                                                    }
                                                    : ""
                                            }
                                            placeholder="All"
                                            styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "11px" }) }}
                                            onBlur={() => setFieldTouched("operator")}
                                            onChange={(value) => setFieldValue("operator", value.value)}
                                        />
                                    </FormControl>{" "}
                                    <br />
                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        Doctor
                                    </div>
                                    <FormControl style={{ width: "95%" }}>

                                        <Selects
                                            options={[{ value: "", label: "All" }, ...doctors?.map((doctor) => {
                                                return {
                                                    value: doctor?._id,
                                                    label: doctor?.name
                                                }
                                            })]}
                                            value={
                                                values.doctor ?
                                                    {
                                                        value: doctors?.find((doctor) => doctor._id === values.doctor)?._id,
                                                        label: doctors?.find((doctor) => doctor._id === values.doctor)?.name
                                                    }
                                                    : ""
                                            }
                                            placeholder="All"
                                            styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "11px" }) }}
                                            onBlur={() => setFieldTouched("operator")}
                                            onChange={(value) => setFieldValue("doctor", value.value)}
                                        />
                                    </FormControl>{" "}
                                    <br />
                                </div>


                                <div>
                                    <div
                                        className={styles.formLable}
                                    >
                                        Payment mode
                                    </div>
                                    <FormControl style={{ width: "95%" }}>
                                        <Selects
                                            options={paymentMode}
                                            value={
                                                values.mode ?
                                                    {
                                                        value: paymentMode?.find((paymentMode) => paymentMode.value == values.mode)?.value,
                                                        label: paymentMode?.find((paymentMode) => paymentMode.value == values.mode)?.label
                                                    }
                                                    : ""
                                            }
                                            placeholder="All"
                                            styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "11px" }) }}
                                            onChange={(value) => setFieldValue("mode", value.value)}
                                        />
                                    </FormControl>{" "}
                                </div>

                                {/* <div className={classes.formLable}>
                                    <Checkbox {...label}
                                        color="default"
                                        onChange={handleChange}
                                        name="patientInfo.urgentRequired"
                                        value={values.patientInfo.urgentRequired}
                                        style={{
                                            marginLeft: '12px',
                                        }}
                                    />
                                    
                                </div>{" "} */}
                            </div>
                            <div className={classes.formDiv4}>
                                <Buttons
                                    className={classes.cancelButton}
                                    onClick={() => navigate("/list-patience")}
                                >
                                    Cancel
                                </Buttons>
                                <Buttons
                                    className={classes.submitButton}
                                    onClick={handleSubmit}
                                >
                                    Preview
                                </Buttons>
                            </div>
                        </>
                        : tab === 2
                            ? <>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", margin: "15px 0" }}>
                                    <h5>Daily payment details</h5>
                                    <Buttons
                                        className={classes.cancelButton}
                                        style={{ marginTop: "0 !important", marginRight: "0 !important" }}
                                        onClick={() => setTab(1)}
                                    >
                                        Cancel
                                    </Buttons>

                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0px" }}>
                                    <Buttons className={tableclasses.numButton} onClick={printPayment} >
                                        Print
                                    </Buttons>
                                </div>
                                <div ref={paymentRef} style={{ padding: "5px 20px" }}>
                                    <h4 className={styles.heading}>Nagalikar diagnostic Scanning  center</h4>
                                    <h6 className={styles.sub_heading}>Daily Payment Register</h6>
                                    <div className={styles.sub_Title}>
                                        <h5>Date: {formatedDate(fromDate)} - {formatedDate(toDate)}</h5>
                                        <h5>Prefix: {values.labPrefix ? account?.find((account) => account._id === values.labPrefix)?.prefix : "[All]"} </h5>
                                        <h5>Suffix: {values.labSuffix ? account?.find((account) => account._id === values.labPrefix)?.prefix : "[All]"}</h5>
                                        <h5>Operator: {values.operator ? operators?.find((operator) => operator._id === values.operator)?.name : "[All]"}</h5>
                                        <h5>Account: [All]</h5>
                                    </div>
                                    {/* <div className={styles.sub_Title2}>
                                    <h5>20/09/2023 (Wednesday)</h5>
                                </div> */}
                                    <Table className={tableclasses.table} ref={reportContent} style={{ width: "100%" }}>
                                        <TableHead className={tableclasses.tableHead} style={{ background: "transparent", borderTop: "1px solid #000", borderBottom: "1px solid #000", padding: "0 !important", height: "20px !important" }}>
                                            <TableRow style={{height: "20px !important"}}>
                                                {header.map((header) => {
                                                    return (
                                                        <TableCell className={tableclasses.customHeadName} style={{ color: "#000", lineHeight: "15px", verticalAlign: "baseline", padding: "2px" }}>
                                                            {header}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {

                                                newPage ? [...rows].slice((page - 1) * 10, page * 10).map((row, index) => {
                                                    return (
                                                        <TableRow key={row.id}>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                className={tableclasses.customTableCell}
                                                                style={{ display: "flex", color: "#000", padding: "2px" }}
                                                            >
                                                                <div className={tableclasses.name} style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    <div>{(page - 1) * 10 + index + 1}</div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                className={tableclasses.customTableCell}
                                                                style={{ padding: "2px" }}
                                                            >
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {formatTime(row.payment?.deposits?.transactionDate)}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {row?.account?.prefix ? row?.account?.prefix : "NA"}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {row?.firstName} {row?.lastName}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {row?.refferedBy?.length > 0 ? row.refferedBy[0].name : "NA"}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {
                                                                        row?.payment.deposits?.totalBill ? row.payment.deposits.totalBill : 0
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    0
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {row?.payment?.deposits?.paidAmount}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {
                                                                        row?.payment.deposits?.totalDue ? row.payment.deposits.totalDue : 0
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {row?.payment?.deposits?.paymentNo}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {row?.assignToUser?.length > 0 ? row.assignToUser[0].name : "NA"}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell} style={{ padding: "2px" }}>
                                                                <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                                    {row?.payment?.deposits?.mode}
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                                    :
                                                    [...rows].slice((page - 1) * 10, page * 10).map((row, index) => {
                                                        return (
                                                            <></>
                                                            // <TableRow key={row.id}>
                                                            //     <TableCell
                                                            //         component="th"
                                                            //         scope="row"
                                                            //         className={tableclasses.customTableCell}
                                                            //         style={{ display: "flex" }}
                                                            //     >
                                                            //         <div className={tableclasses.name}>
                                                            //             <div>{(page - 1) * 10 + index + 1}</div>
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell
                                                            //         component="th"
                                                            //         scope="row"
                                                            //         className={tableclasses.customTableCell}
                                                            //     >
                                                            //         <div>
                                                            //             10:30 AM
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             {row?.account?.prefix ? row?.account?.prefix : "NA"}
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             {row?.firstName} {row?.lastName}
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             {row?.refferedBy?.length > 0 ? row.refferedBy[0].name : "NA"}
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             100
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             0
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             {row?.payment?.deposits?.paidAmount}
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             20
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             {row?.payment?.deposits?.paymentNo}
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             {row?.assignToUser?.length > 0 ? row.assignToUser[0].name : "NA"}
                                                            //         </div>
                                                            //     </TableCell>
                                                            //     <TableCell className={tableclasses.customTableCell}>
                                                            //         <div>
                                                            //             {row?.payment?.deposits?.mode}
                                                            //         </div>
                                                            //     </TableCell>
                                                            // </TableRow>
                                                        )
                                                    })
                                            }

                                            {
                                                page === totalPages &&
                                                <TableRow style={{ borderBottom: "1px solid black", borderTop: "1px solid black" }}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className={tableclasses.customTableCell}
                                                        style={{ display: "flex", padding: "5px" }}
                                                    >
                                                        <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                            Total
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className={tableclasses.customTableCell}
                                                        style={{ padding: "5px" }}
                                                    >
                                                        <div>

                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div>

                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div>

                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div>

                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                            {totalBill}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                            0
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                            {totalBill - totalDue}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div style={{ color: "#000", fontWeight: 400, fontSize: "10px" }}>
                                                            {totalDue}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div>

                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div>

                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tableclasses.customTableCell} style={{ padding: "5px" }}>
                                                        <div>

                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                                <div className={tableclasses.pagination}>
                                    <div className={tableclasses.name}>
                                        {/* Showing {rows?.length} of {pageInfo?.totalItems} entries */}
                                    </div>
                                    <div>
                                        <Buttons
                                            onClick={setPrevPage}
                                            className={tableclasses.pageButton}
                                        >
                                            Previous
                                        </Buttons>
                                        <Buttons className={tableclasses.numButton}>
                                            {page}
                                        </Buttons>
                                        <Buttons
                                            onClick={setNextPage}
                                            className={tableclasses.pageButton}
                                        >
                                            Next
                                        </Buttons>
                                    </div>
                                </div>
                            </>
                            : <></>
                }
            </div>
        </div>
    )
}

export default Payment