import React, { useEffect, useState, useRef } from "react"
import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { formStyles } from "../Styles/Formstyle";
import Buttons from "../Components/Shared/Buttons";
import Input from "../Components/Shared/Input";
import axios from "axios";
import { API } from "../config";
import { AddFemaleFieldSvg, AddFieldSvg, MinusButtonSvg } from "../Components/Shared/UserSvg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddNewTestForm = () => {
  const classes = formStyles();
  const [value, setValue] = useState("1");
  const [rows, setRows] = useState();
  const [category, setCategory] = useState();
  const [gender, setGender] = useState("");
  const [device, setDevice] = useState("");
  const [selectedGender, setSelectedGender] = useState([]);
  const bottom = useRef(null)
  const [testCodes, setTestCodes] = useState([]);

  const [formFields, setFormFields] = useState([]);

  const [optionFields, setOptionFields] = useState([{ code: "", option: "" }]);

  const [FemaleformFields, setFemaleFormFields] = useState([]);

  const [transgenderFormFields, setTransGender] = useState([])

  const navigate = useNavigate();

  const [testname, setTestname] = useState();
  const [testid, setTestid] = useState();
  const [unit, setUnit] = useState();
  const [deltalimit, setDeltaLimit] = useState();
  const [tat, setTat] = useState();
  const [normal, setNormal] = useState();
  const [deltime, setDeltime] = useState();

  const testdata = {
    category,
    testname,
    testid,
    unit,
    deltalimit,
    tat,
    normal,
    deltime, gender
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (action, gender) => {
    if (action === "add") {
      if (gender && !selectedGender.includes(gender)) {
        setSelectedGender((prev) => {
          return [...prev, gender]
        });

        if (gender === "male") {
          setFormFields([{ ageUpto: "", low: "", high: "", refRange: "" }])
        }
        else if (gender === "female") {
          setFemaleFormFields([{ ageUpto: "", low: "", high: "", refRange: "" }])
        }
        else if (gender === "transgender") {
          setTransGender([{ ageUpto: "", low: "", high: "", refRange: "" }])
        }

        bottom.current.scrollIntoView({ behavior: "smooth", block: "end" })
      }
    } else {

      const resetData = []
      if (gender === "male") {
        setFormFields(resetData)
      }
      else if (gender === "female") {
        setFemaleFormFields(resetData)
      }
      else if (gender === "transgender") {
        setTransGender(resetData)
      }

      const clonedGender = [...selectedGender];
      const index = clonedGender.findIndex((gen) => gen === gender);
      clonedGender.splice(index, 1);

      setSelectedGender(clonedGender);
    }
  };

  const handleDevice = (action, device) => {
    if (action === "add") {

      let deviceExist = testCodes.find((test) => test.deviceName === device)

      if (device && !deviceExist) {
        setTestCodes((prev) => {
          return [...prev, {
            deviceName: device,
            OnlineTestCode: ""
          }]
        })

        bottom.current.scrollIntoView({ behavior: "smooth", block: "end" })
      }
    } else {
      const clonedTests = [...testCodes];
      const index = clonedTests.findIndex((gen) => gen.deviceName === device);
      clonedTests.splice(index, 1);

      setTestCodes(clonedTests);
    }
  };

  const TOKEN = localStorage.getItem("logintoken");

  const fetchTest = async (e) => {
    try {
      const data = await axios.get(`${API}/gettestcategory`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setRows(data.data.testCategory);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTest();
  }, []);


  // handle change
  const handleInputChange = (fields, index, event) => {

    const { name, value } = event.target;

    if (fields === "male") {
      const fields = [...formFields];
      fields[index][name] = value;
      setFormFields(fields);
    }
    else if (fields === "female") {
      const fields = [...FemaleformFields];
      fields[index][name] = value;
      setFemaleFormFields(fields);
    }
    else if (fields === "transgender") {
      const fields = [...transgenderFormFields];
      fields[index][name] = value;
      setTransGender(fields);
    }

  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    variant: "menu",
    PaperProps: {
      style: {
        height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const handleOptionChange = (index, event) => {
    const { name, value } = event.target;
    const fields = [...optionFields];
    fields[index][name] = value;
    setOptionFields(fields);
  };

  const handleAddOptionFields = () => {
    setOptionFields([...optionFields, { code: "", option: "" }]);
  };

  const handleRemoveOptionField = (index) => {
    const updatedOptionFields = [...optionFields];
    updatedOptionFields.splice(index, 1);
    setOptionFields(updatedOptionFields);
  };

  // add fields
  const handleAddFields = (fields) => {
    if (fields === "male") {
      setFormFields([
        ...formFields,
        { ageUpto: "", low: "", high: "", refRange: "" },
      ]);
    } else if (fields === "female") {
      setFemaleFormFields([
        ...FemaleformFields,
        { ageUpto: "", low: "", high: "", refRange: "" },
      ]);
    } else if (fields === "transgender") {
      setTransGender([
        ...transgenderFormFields,
        { ageUpto: "", low: "", high: "", refRange: "" },
      ])
    }
  };

  // remove fields
  const handleRemoveFields = (fields, index) => {

    if (fields === "male") {
      const clonedFormFields = [...formFields]
      clonedFormFields.splice(index, 1)

      setFormFields(clonedFormFields)
    } else if (fields === "female") {
      const clonedFormFields = [...FemaleformFields]
      clonedFormFields.splice(index, 1)

      setFemaleFormFields(clonedFormFields)
    } else if (fields === "transgender") {
      const clonedFormFields = [...transgenderFormFields]
      clonedFormFields.splice(index, 1)

      setTransGender(clonedFormFields)
    }
  }

  const handleSubmit = async () => {
    try {
      try {
        const data = await axios.post(
          `${API}/addtestsubcategory`,
          { testdata, formFields, FemaleformFields, transgenderFormFields, optionFields, testCodes },
          {
            headers: { authtoken: `${TOKEN}` },
          }
        );
        if (data?.data?.message === "Test Subcategory saved successfully") {
          toast.success("Test Subcategory saved successfully");
          navigate('/add-test')
        }
      } catch (e) {
        console.log(e);
        toast.error("Error saving Subcategory");
      }
    } catch (e) {
      console.error(e);
    }
  };

  console.log("selectedGender", selectedGender)

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root} ref={bottom}>
      <div className={classes.collectorForm}>
        <div className={classes.formheader}>
          <div className={classes.formnameHead}>
            <div className={classes.formh2}>Add New Test</div>
            <div className={classes.littlehead}>
              {/* {rows?.length} */} you can create a new test here
            </div>
          </div>
          <div>
            <Buttons
              className={classes.formButtonwidth}
              onClick={() => navigate("/add-test")}
            >
              &nbsp; Back to test table
            </Buttons>
          </div>
        </div>

        <div>
          <Box sx={{ padding: "0 12%", borderColor: "blue" }}>
            <TabContext value={value} className={classes.tabs}>
              <Box sx={{ padding: "0 10%", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
                <TabList
                  onChange={handleChange1}
                  aria-label="lab API tabs example"
                  sx={{ "& .MuiTabs-indicator": { backgroundColor: "black" } }}
                >
                  <Tab
                    label="1 Test Information"
                    className={classes.tablist}
                    value="1"
                  />
                  <Tab
                    label="2 Reference Range"
                    className={classes.tablist}
                    value="tab2"
                  />
                  <Tab
                    label="3 Test Code"
                    className={classes.tablist}
                    value="tab3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{}}>
                <div>
                  <div className={classes.formMain}>
                    <FormControl>
                      <div className={classes.formDiv1}>
                        <div className={classes.formDiv2}>
                          <div className={classes.formHeading}>
                            New Test Information
                          </div>
                          <div className={classes.formLable}>Category</div>
                          <FormControl>
                            <Select
                              value={category}
                              onChange={(event) =>
                                setCategory(event.target.value)
                              }
                              className={classes.selectInput}
                              MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                            >
                              {rows?.map((option) => (
                                <MenuItem key={option._id} value={option._id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>{" "}
                          <br />
                          <div className={classes.formLable}>Test Name</div>
                          <Input
                            type="text"
                            placeholder="Enter Test Name"
                            className={classes.formInput}
                            value={testname}
                            onChange={(e) => setTestname(e.target.value)}
                          />{" "}
                          <br />
                          <div className={classes.formLable}>Default value</div>
                          <Input
                            type="text"
                            placeholder="Enter Normals"
                            className={classes.formInput}
                            value={normal}
                            onChange={(e) => setNormal(e.target.value)}
                          />{" "}
                          <br />
                          <div className={classes.formLable}>
                            Delta check limit
                          </div>
                          <Input
                            type="text"
                            placeholder="Enter Delta limit"
                            className={classes.formInput}
                            value={deltalimit}
                            onChange={(e) => setDeltaLimit(e.target.value)}
                          />
                          <div>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                style={{
                                  fontSize: "6px",
                                  margin: "0 30px 0 0 ",
                                }}
                                value="% value"
                                control={
                                  <Radio
                                    color="default"
                                    style={{ width: "8px", height: "8px", marginRight: '8px', }}
                                  />
                                }
                                label="% value"
                              />
                              <FormControlLabel
                                style={{ fontSize: "6px" }}
                                value="male"
                                control={
                                  <Radio
                                    color="Absolute value"
                                    style={{ width: "8px", height: "8px", marginRight: '8px', }}
                                  />
                                }
                                label="Absolute value"
                              />
                            </RadioGroup>
                            <br />
                          </div>{" "}
                          <br />
                          {/* <div className={classes.formLable}>
                            Result visble to all:
                            <Checkbox {...label} color="default" />
                          </div>
                          <div className={classes.formLable}>
                            Print in card:
                            <Checkbox {...label} color="default" />
                          </div>{" "} */}
                          <br />
                        </div>
                        <div className={classes.formDiv31}>
                          <div className={classes.formLable}>Test ID</div>
                          <Input
                            type="text"
                            placeholder="Enter Test ID"
                            className={classes.formInput}
                            value={testid}
                            onChange={(e) => setTestid(e.target.value)}
                          />{" "}
                          <br />
                          <div className={classes.formLable}>Unit</div>
                          <Input
                            type="text"
                            placeholder="Enter Unit"
                            className={classes.formInput}
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                          />{" "}
                          <br />
                          <div className={classes.formLable}>
                            Time arount test(TAT) in minutes
                          </div>
                          <Input
                            type="text"
                            placeholder="Enter TAT"
                            className={classes.formInput}
                            value={tat}
                            onChange={(e) => setTat(e.target.value)}
                          />

                          <div className={classes.formLable}>
                            Delta check time duration
                          </div>
                          <Input
                            type="text"
                            placeholder="Enter Delta duration"
                            className={classes.formInput}
                            value={deltime}
                            onChange={(e) => setDeltime(e.target.value)}
                          />{" "}
                          <br />
                        </div>
                      </div>
                      <div className={classes.genderDiv1}>
                        <div>
                          <div className={classes.genderHeading}>
                            Test Value Options
                          </div>
                          {optionFields.map((field, index) => (
                            <div
                              className={classes.genderDiv2}
                              style={{ display: "flex" }}
                            >
                              <div>
                                <div className={classes.formLable}>Code</div>
                                <Input
                                  type="number"
                                  placeholder="Enter Code"
                                  className={classes.genformInput}
                                  name="code"
                                  value={field.code}
                                  onChange={(event) =>
                                    handleOptionChange(index, event)
                                  }
                                />
                                <br />
                              </div>

                              <div style={{ width: "100%" }}>
                                <div className={classes.formLable}>
                                  Options
                                </div>
                                <Input
                                  type="text"
                                  placeholder="Enter Option"
                                  className={classes.genformInput}
                                  name="option"
                                  value={field.option}
                                  onChange={(event) =>
                                    handleOptionChange(index, event)
                                  }
                                />
                                <br />
                              </div>

                              <div>
                                {index === 0 ? (
                                  <div
                                    style={{
                                      marginTop: "28px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <AddFieldSvg
                                      handleAddFields={handleAddOptionFields}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      marginTop: "28px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleRemoveOptionField(index)
                                    }
                                  >
                                    <MinusButtonSvg />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={classes.formDiv4}>
                        <Buttons
                          onClick={() => navigate('/add-test')}
                          className={classes.cancelButton}
                        >
                          Cancel
                        </Buttons>
                        <Buttons
                          className={classes.submitButton}
                          onClick={() => setValue("tab2")}
                        >
                          Next
                        </Buttons>
                      </div>
                    </FormControl>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="tab2" sx={{}}>
                <div>
                  <div className={classes.formHeading}>
                    Reference range
                  </div>
                  <div className={classes.formLable}>Gender</div>
                  <div>
                    <Select
                      className={classes.selectInput}
                      placeholder="Select"
                      label="Select"
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <MenuItem
                        value="male"
                        style={{ backgroundColor: "transparent" }}
                      >
                        Male
                      </MenuItem>

                      <MenuItem value="female" className={classes.menuInput}>
                        Female
                      </MenuItem>
                      <MenuItem
                        value="transgender"
                        style={{ backgroundColor: "transparent" }}
                      >
                        Transgender
                      </MenuItem>
                    </Select>{" "}
                    <Buttons className={classes.addButtonadd} onClick={() => handleChange("add", gender)} >Add</Buttons>
                    <br />
                  </div>

                  {
                    selectedGender.includes("male") && (
                      <div className={classes.genderDiv}>
                        <div className={classes.genderDiv1}>
                          <div>
                            <div className={classes.genderHeading}>
                              For Male
                              <img src="/cross.svg" alt="cross" onClick={() => handleChange("remove", "male")} style={{ cursor: "pointer" }} />
                            </div>

                            {formFields.map((field, index) => (
                              <div
                                className={classes.genderDiv2}
                                style={{ display: "flex" }}
                              >
                                <div>
                                  <div className={classes.formLable}>
                                    Age upto
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter Age Range"
                                    className={classes.genformInput}
                                    name="ageUpto"
                                    value={field.ageUpto}
                                    onChange={(event) =>
                                      handleInputChange("male", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>

                                <div>
                                  <div className={classes.formLable}>
                                    High value
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter high value"
                                    className={classes.genformInput}
                                    name="high"
                                    value={field.high}
                                    onChange={(event) =>
                                      handleInputChange("male", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div>
                                  <div className={classes.formLable}>
                                    Low value
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter low value"
                                    className={classes.genformInput}
                                    name="low"
                                    value={field.low}
                                    onChange={(event) =>
                                      handleInputChange("male", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div>
                                  <div className={classes.formLable}>
                                    Reference range
                                  </div>
                                  <Input
                                    type="text"
                                    placeholder="Enter reference value"
                                    className={classes.genformInput}
                                    name="refRange"
                                    value={`${field.low}${field.low ? "-" : ""}${field.high}`}
                                    onChange={(event) =>
                                      handleInputChange("male", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div
                                  style={{ marginTop: "28px", cursor: "pointer" }}
                                >
                                  {index === 0 ? (
                                    <AddFieldSvg
                                      handleAddFields={() => handleAddFields("male")}
                                    />
                                  ) : (
                                    <div onClick={() => handleRemoveFields("male", index)}>
                                      <MinusButtonSvg />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                    selectedGender.includes("female") && (
                      <div className={classes.genderDiv}>
                        <div className={classes.genderDiv1}>
                          <div>
                            <div className={classes.genderHeading}>
                              For FeMale
                              <img src="/cross.svg" alt="cross" onClick={() => handleChange("remove", "female")} style={{ cursor: "pointer" }} />
                            </div>
                            {FemaleformFields.map((field, index) => (
                              <div
                                className={classes.genderDiv2}
                                style={{ display: "flex" }}
                              >
                                <div>
                                  <div className={classes.formLable}>
                                    Age upto
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter Age Range"
                                    className={classes.genformInput}
                                    name="ageUpto"
                                    value={field.ageUpto}
                                    onChange={(event) =>
                                      handleInputChange("female", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>

                                <div>
                                  <div className={classes.formLable}>
                                    High value
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter high value"
                                    className={classes.genformInput}
                                    name="high"
                                    value={field.high}
                                    onChange={(event) =>
                                      handleInputChange("female", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div>
                                  <div className={classes.formLable}>
                                    Low value
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter low value"
                                    className={classes.genformInput}
                                    name="low"
                                    value={field.low}
                                    onChange={(event) =>
                                      handleInputChange("female", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div>
                                  <div className={classes.formLable}>
                                    Reference range
                                  </div>
                                  <Input
                                    type="text"
                                    placeholder="Enter Reference value"
                                    className={classes.genformInput}
                                    name="refRange"
                                    value={`${field.low}${field.low ? "-" : ""}${field.high}`}
                                    onChange={(event) =>
                                      handleInputChange("female", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div
                                  style={{ marginTop: "28px", cursor: "pointer" }}
                                >
                                  {index === 0 ? (
                                    <AddFieldSvg
                                      handleAddFields={() => handleAddFields("female")}
                                    />
                                  ) : (
                                    <div onClick={() => handleRemoveFields("female", index)}>
                                      <MinusButtonSvg />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                    selectedGender.includes("transgender") && (
                      <div className={classes.genderDiv}>
                        <div className={classes.genderDiv1}>
                          <div>
                            <div className={classes.genderHeading}>
                              For Transgender
                              <img src="/cross.svg" alt="cross" onClick={() => handleChange("remove", "transgender")} style={{ cursor: "pointer" }} />
                            </div>
                            {transgenderFormFields.map((field, index) => (
                              <div
                                className={classes.genderDiv2}
                                style={{ display: "flex" }}
                              >
                                <div>
                                  <div className={classes.formLable}>
                                    Age upto
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter Age Range"
                                    className={classes.genformInput}
                                    name="ageUpto"
                                    value={field.ageUpto}
                                    onChange={(event) =>
                                      handleInputChange("transgender", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>

                                <div>
                                  <div className={classes.formLable}>
                                    High value
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter high value"
                                    className={classes.genformInput}
                                    name="high"
                                    value={field.high}
                                    onChange={(event) =>
                                      handleInputChange("transgender", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div>
                                  <div className={classes.formLable}>
                                    Low value
                                  </div>
                                  <Input
                                    type="number"
                                    placeholder="Enter low value"
                                    className={classes.genformInput}
                                    name="low"
                                    value={field.low}
                                    onChange={(event) =>
                                      handleInputChange("transgender", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div>
                                  <div className={classes.formLable}>
                                    Reference range
                                  </div>
                                  <Input
                                    type="text"
                                    placeholder="Enter Reference value"
                                    className={classes.genformInput}
                                    name="refRange"
                                    value={`${field.low}${field.low ? "-" : ""}${field.high}`}
                                    onChange={(event) =>
                                      handleInputChange("transgender", index, event)
                                    }
                                  />{" "}
                                  <br />
                                </div>
                                <div
                                  style={{ marginTop: "28px", cursor: "pointer" }}
                                >
                                  {index === 0 ? (
                                    <AddFieldSvg
                                      handleAddFields={() => handleAddFields("transgender")}
                                    />
                                  ) : (
                                    <div onClick={() => handleRemoveFields("transgender", index)}>
                                      <MinusButtonSvg />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  }


                  <div className={classes.formDiv4}>
                    <Buttons className={classes.cancelButton}
                      onClick={() => navigate('/add-test')}>Cancel</Buttons>
                    <Buttons
                      className={classes.submitButton}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Buttons>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="tab3" sx={{}}>
                <div>
                  <div className={classes.formHeading}>
                    Test Code
                  </div>
                  <div className={classes.formLable}>Device</div>
                  <div>
                    <Select
                      className={classes.selectInput}
                      placeholder="Select"
                      label="Select"
                      onChange={(e) => setDevice(e.target.value)}
                    >
                      <MenuItem
                        value="YUMIZEN_H550"
                        style={{ backgroundColor: "transparent" }}
                      >
                        YUMIZEN_H550
                      </MenuItem>
                      <MenuItem
                        value="ACCESS2"
                        style={{ backgroundColor: "transparent" }}
                      >
                        ACCESS2
                      </MenuItem>
                    </Select>{" "}
                    <Buttons className={classes.addButtonadd} onClick={() => handleDevice("add", device)} >Add</Buttons>
                    <br />
                  </div>

                  {
                    testCodes.map((test, i) => (
                      <div className={classes.genderDiv}>
                        <div className={classes.genderDiv1}>
                          <div>
                            <div className={classes.genderHeading}>
                              {test.deviceName}
                              <img src="/cross.svg" alt="cross" onClick={() => handleDevice("remove", test.deviceName)} style={{ cursor: "pointer" }} />
                            </div>
                            <div>
                              <div className={classes.formLable}>
                                Test Code
                              </div>
                              <Input
                                type="text"
                                placeholder="Enter Test code"
                                className={classes.genformInput}
                                name="OnlineTestCode"
                                value={test.OnlineTestCode}
                                onChange={(event) => {
                                  let clonedTest = [...testCodes]
                                  clonedTest.splice(i, 1, {
                                    deviceName: test.deviceName,
                                    OnlineTestCode: event.target.value
                                  })
                                  setTestCodes(clonedTest)
                                }
                                }
                              />{" "}
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }

                  <div className={classes.formDiv4}>
                    <Buttons className={classes.cancelButton}
                      onClick={() => navigate('/add-test')}>Cancel</Buttons>
                    <Buttons
                      className={classes.submitButton}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Buttons>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AddNewTestForm;
