import React, { useEffect, useMemo, useRef, useState } from "react"
import 'quill/dist/quill.snow.css'
import ReactQuill, { Quill } from 'react-quill'
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { macroStyles } from "../../Styles/Macro";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "./Buttons";
import { toast } from "react-toastify";
import Api from "../../helper/api";
import { formatTime, formatedDate, formatedDateInDigit } from "./../../helper/helper";
import { useReactToPrint } from "react-to-print";
import { stringify } from 'flatted'
import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';
import CustomImage from "./CustomImage";
import { useDispatch, useSelector } from "react-redux";
import { setValues } from "../../redux/findPatientSlice";


Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register("formats/image", CustomImage)

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  height: "100%",
  width: "800.88px",
  padding: "0 0px",
  overflow: "scroll",
  display: "block",

  borderRadius: "6px",
  "&:focus": {
    outline: "none",
  },
};

const ReportLayout = ({ handleClose, layoutId, id }) => {
  const api = new Api();
  const classes = formStyles();
  const macroclasses = macroStyles();
  const dispatch = useDispatch()
  const { reFetch } = useSelector(state => state.findPatient)

  const [open, setOpen] = React.useState(true);
  const macros = useRef(null)
  const [patience, setPatience] = useState();
  const [report, setReport] = useState();
  const [macro, setMacro] = useState(null);
  const [test, setTest] = useState([]);
  const [value, setValue] = useState()

  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: id })
    setPatience(data);

    const investigation = data.investigation.find((item) => item._id === parseInt(layoutId))
    const macro = data?.testResult.find((item) => item.investigationId === parseInt(layoutId))
    setReport(investigation)

    if (macro) {
      setMacro(macro)
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchTest = async () => {
      try {

        let test = [];

        for (let i = 0; i < report.reportFormat.length; i++) {
          const id = report?.reportFormat[i]

          const { data, status } = await api.fetchReportById({}, { id });

          if (status === 200) {
            if (data.report?.reportsubcategory.length > 0) {
              for (let i = 0; i < data.report?.reportsubcategory.length; i++) {
                const testId = data.report.reportsubcategory[i]._id;

                const testExist = test.find((test) => test._id === testId)

                if (!testExist) {

                  test = [
                    ...test,
                    {
                      ...data.report.reportsubcategory[i],
                      reportName: data.report?.reportName,
                      reportId: data.report?._id
                    }
                  ]
                }
              }
            }
          }
        }

        let testClone = test.reduce((acc, curr) => {

          const id = curr.reportId;

          if (acc[id]) {
            acc[id] = [...acc[id], curr]
          } else {
            acc[id] = [curr]
          }

          return acc
        }, {})

        setTest(testClone)

      } catch (err) {
        console.log(err)
      }
    }

    if (report?.reportFormat.length > 0) {
      fetchTest()
    }
  }, [report?._id])


  const imageHandler = async () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      var file = input.files[0];

      try {
        const { data, status } = await api.uploadSlip({ image: file }, { id: "" });

        const range = macros.current.getEditorSelection();
        var res = data[0].src;

        macros.current.getEditor().insertEmbed(range.index, 'image', res);

      } catch (err) {
        console.log(err)
      }
    };
  }

  const modules = useMemo(() => (
    {
      toolbar: {
        container: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
          [{ size: ["small", false, "large", "huge"] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] }
          ],
          ['link', 'image'],
          [{ 'align': [] }],
          [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ],
        'handlers': {
          image: imageHandler
        },
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      },
      blotFormatter: {},
    }
  ), [])

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size", "width", "style"
  ];

  const handleProcedureContentChange = (content) => {
    setValue(content)
  };

  const getRange = (patient, range, unit) => {
    let age = patient?.age;

    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)

    let ran = {};

    for (let i = 0; i < rangeClone.length; i++) {
      let curr = rangeClone[i].ageUpto
      if (Object.keys(ran).length === 0) {
        if (curr >= age) {
          ran = rangeClone[i];
        }
      }
    }

    return `${ran?.low || ""}-${ran?.high || ""} ${unit || ""}`
  }

  const newTest =
    Object.keys(test).map((id) => (
      `
      <h3>${test[id][0]?.reportName}</h3>
      ${test[id].map((subcat) => {
        const result = macro?.testResult?.find((item) => item.testId === subcat._id)
        return (
          `
          <p class="ql-indent-1"> 
          <strong>${subcat?.name} </strong> 
          <strong>${result?.result ? result?.result : ""} </strong>  
          <strong>${subcat?.units ? subcat?.units : ""} </strong>  
          <strong>
          ${patience?.gender === "male"
            ? getRange(patience, subcat?.rangeForMale, subcat?.units)
            : getRange(patience, subcat?.rangeForFemale, subcat?.units)
          }
           </strong> 
        </p>
        ${subcat?.extraText ? `<p class="ql-indent-1"> <strong>${subcat?.extraText} </strong> </p>` : ""}
          `
        )
      })
      }
      `
    ))

  // ${test[id].map((subcat) => {
  // <div className="grid grid-cols-[1fr_100px_100px_1fr]  my-2  font-semibold text-sm max-w-[650px] mx-auto ">
  //   <div><span>${test[id][0]?.reportName}</span></div>
  // </div>
  //   const result = macro?.testResult?.find((item) => item.testId === subcat._id)
  //   return (
  //     `
  //       <div className="grid grid-cols-[1fr_100px_100px_1fr] text-[#464E5F]/90  my-1  text-sm max-w-[650px] mx-auto">
  //         <div>${subcat?.name}</div>
  //         <div>${result?.result}</div>
  //         <div>${subcat?.units}</div>
  //         <div className="text-center">
  //           ${patience?.gender === "male" ? subcat?.rangeForMale?.map((ran) => {
  //       return (
  //         `
  //        <p>
  //          High: ${ran.high}-
  //         ${subcat?.units}
  //        </p>
  //        <p>
  //          Low: ${ran.low}-
  //          ${subcat?.units}
  //        </p>
  //      `
  //       );
  //     })
  //       : subcat?.rangeForFemale?.map((ran) => {
  //         return (
  //           `
  //           <p>
  //             High: ${ran.high}-
  //             ${subcat?.units}
  //           </p>
  //           <p>
  //             Low: ${ran.low}-
  //             ${subcat?.units}
  //           </p>
  //         </>`
  //         );
  //       })
  //     }
  //         </div>
  //       </div>

  //     `
  //   );
  // })
  // }

  // put this snippet into the html if required
  // <h1 class="ql-align-center">
  // ${report?.category?.name ? report?.category?.name : ""}${""}
  // </h1>

  const html = `
  <p class="ql-indent-8"> 
  <strong>Test Name </strong> 
  <strong>Results </strong>  
  <strong>Units </strong>  
  <strong>Normal Range </strong> 
</p>

  ${newTest[0]?.split(",")?.join("")}
`

  function htmlToDelta(html) {
    const div = document.createElement('div');
    div.setAttribute('id', 'htmlToDelta');
    div.innerHTML = `<div id="quillEditor">${html}</div>`;
    document.body.appendChild(div);
    const quill = new Quill('#quillEditor', {
      theme: 'snow',
    });
    const delta = quill.getContents();
    document.getElementById('htmlToDelta').remove();
    return delta;
  }

  useEffect(() => {
    if (macro?.layout) {
      setValue(macro?.layout)
    } else {
      setValue(htmlToDelta(html))
    }

  }, [test.length])

  const saveLayout = async () => {
    try {
      const { data, status } = await api.updateLayout({
        investigationId: layoutId,
        patient: id,
        layout: value
      })

      if (status === 200) {
        toast.success("Saved Successfully")
        dispatch(setValues({name: "reFetch", value: !reFetch}))
        handleClose()
      }
    } catch (err) {
      toast.error("Error Saving!")
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-y-scroll px-0"
    >
      <Box sx={style} >
        <div >
          <header className="flex justify-between  px-6 py-6 bg-[#FAFAFA]">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={macroclasses.name}
            >
              Report Layout
            </Typography>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </header>
          <div style={{ display: "grid", border: 'none !important', }} className="reportLayout">
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              // readOnly
              ref={macros}
              placeholder="write your content ...."
              onChange={handleProcedureContentChange}
              value={value}
              style={{
                backgroundColor: 'rgba(227, 241, 254, 0.4)',
                '&.ql-editor': {
                  border: 'none !important',
                  backgroundColor: 'rgba(227, 241, 254, 0.4)',
                },
                '&.ql-toolbar.ql-snow': {
                  border: 'none',
                },
              }}
            >
            </ReactQuill>
          </div>
          <div
            style={{
              marginTop: '70px',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'right',
              marginRight: '4%',
              marginBottom: "20px"
            }}>
            <Buttons className={classes.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </Buttons>
            <Buttons
              className={classes.submitButton}
              onClick={saveLayout}
            >
              Save
            </Buttons>
          </div>
        </div>
      </Box>
    </Modal>
  );

}

export default ReportLayout;