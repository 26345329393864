import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "../../Components/Shared/Buttons";
import Api from '../../helper/api';
import { DoctorSvg, DropdownSvg, FilterSvg, PendingSvg, ResetFilterSvg } from "../../Components/Shared/UserSvg";
import PopoverMenu from "../../Components/Shared/Popover";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AssignCollector from "../../Components/Shared/CollectorAssign";
import { formatTime, formatedDate } from '../../helper/helper';


const SearchedPatience = ({ removeComponents, setPaitence, value }) => {
  const navigate = useNavigate();
  const api = new Api();
  const [id, setID] = useState();
  const tableclasses = tableStyles();
  const [rows, setRows] = useState();
  const [assign, setAssign] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [newData, setNewData] = useState(false);
  const classes = formStyles();
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState();
  const removeCompo = () => {
    removeComponents();
  }

  const header = [
    "SL No",
    "Patient Name",
    "Date & Time",
    "Lab No",
    "Reffered by",
    "Sample From",
    "Price",
    "Status",
    "Action",
  ];


  const fetchData = async () => {
    try {
      const response = await api.fetchPatient({ ...value }, {
        page: page,
        quantity: 10
      })
      setRows(response.data.patients);
      setPageInfo(response.data);
    } catch (error) {
      console.error("Fetching Data Error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [newData, page, rowsPerPage, fetch]);

  const handleAssign = (data) => {
    setAssign(true);
  };

  const handleView = async (phone) => {
    navigate(`/appointment-view/${phone}`)
  }

  const viewReport = async (id) => {
    navigate(`/patient-report/${id}`)
  }

  const handleEdit = async (id) => {
    navigate(`/edit-patience/${id}`)
  }

  const handleDelete = async (id) => {
    try {
      const response = await api.deletePatient({}, {
        id
      })
      if (response.data) {
        setNewData(true);
        toast.success("Patience deleted successfully");
        setNewData(false);
      }
    } catch (error) {
      console.error("Fetching Data Error", error);
    }
  };

  const setNextPage = () => {
    if (pageInfo?.currentPage > 0) {
      if (page === pageInfo?.totalPages) return;
      setPage(page + 1);
    }
  };

  const setPrevPage = () => {
    if (pageInfo.currentPage > 1) {
      setPage(page - 1);
    }
  };
  return (
    <>
      <div className={tableclasses.paper}>
        <div className={tableclasses.findHeader}>
          <div className={tableclasses.h2}>Patients</div>
          <div className={tableclasses.h2} style={{ cursor: 'pointer' }} onClick={removeCompo}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M1 17L17 1M1 1L17 17" stroke="black" stroke-opacity="0.7" stroke-width="1.88067" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div style={{ justifyContent: 'flex-end', display: 'flex', padding: '18px 34px' }}>
          <Buttons className={classes.backButton} onClick={() => setPaitence(false)}>Back</Buttons>
        </div>


        <Table className={tableclasses.table} style={{ marginLeft: '5%' }}>
          <TableHead className={tableclasses.tableHead}>
            <TableRow>
              {header.map((header) => {
                return (
                  <TableCell className={tableclasses.customHeadName}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={tableclasses.customTableCell}
                  style={{ display: "flex" }}
                >
                  <div className={tableclasses.name}>
                    <div>{index + 1}</div>
                  </div>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={tableclasses.customTableCell}
                >
                  <div className={tableclasses.name}>
                    <div>
                      {row.firstName} {row.lastName}
                    </div>
                    <div className={tableclasses.specification}>
                      {row._id}
                    </div>
                  </div>
                </TableCell>
                {/* <TableCell className={tableclasses.customTableCell}>
                <div>{formatedDate(row.updatedAt)}</div>
                <div className={tableclasses.specification}>
                {formatTime(row.updatedAt)}
                </div>
              </TableCell> */}
                <TableCell className={tableclasses.customTableCell}>
                  <div>{formatedDate(row.updatedAt)}</div>
                  <div className={tableclasses.specification}>
                    {formatTime(row.updatedAt)}
                  </div>                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.labNumber || 'NA'}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row?.refferedBy?.name || 'Doctor'}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.sampleFrom}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row?.payment[0]?.totalAmount}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div
                    style={{
                      text: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <PendingSvg />
                    </div>
                    <div>
                      <span
                        style={{
                          color: "#D48A48",
                          marginLeft: 6,
                          text: "center",
                        }}
                      >
                        {row?.sampleCollection[0]?.sampleStatus}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>
                    <PopoverMenu
                      data={rows}
                      handleEdit={() => handleEdit(row._id)}
                      handleDelete={() => handleDelete(row._id)}
                      handleView={() => handleView(row._id)}
                      viewReport={() => viewReport(row._id)}
                      handleAssign={() => {
                        handleAssign(row._id);
                        setID(row._id);
                      }}
                    />
                  </div>
                </TableCell>
                {assign && (
                  <AssignCollector
                    assign={assign}
                    setAssign={setAssign}
                    patienceId={id}
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={tableclasses.pagination} style={{ margin: "auto" }}>
          <div className={tableclasses.name}>
            Showing {rows?.length} of {pageInfo?.totalItems} entries
          </div>
          <div>
            <Buttons onClick={setPrevPage} className={tableclasses.pageButton}>
              Previous
            </Buttons>
            <Buttons className={tableclasses.numButton}>
              {pageInfo?.currentPage}
            </Buttons>
            <Buttons onClick={setNextPage} className={tableclasses.pageButton}>
              Next
            </Buttons>
          </div>
          {/* <div></div> */}
        </div>

      </div>
    </>
  )
}

export default SearchedPatience
