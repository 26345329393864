import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import { UserSvg } from "../../Components/Shared/UserSvg";
import PopoverMenu from "../../Components/Shared/Popover";
import { useReactToPrint } from "react-to-print";
import { FiSearch } from "react-icons/fi";


const GetAccountList = () => {
  const tableclasses = tableStyles();
  const navigate = useNavigate();
  const reportContent = useRef();
  const [search, setSearch] = useState('')

  const [rows, setRows] = useState();
  const [newData, setNewData] = useState(false);
  const [pageInfo, setPageInfo] = useState();
  const [page, setPage] = useState(1);

  const [name, SetName] = useState("");
  const TOKEN = localStorage.getItem("logintoken");

  const fetchData = async () => {
    const data = await axios.get(`${API}/getaccountdetails?search=${search}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setRows(data?.data?.accountDetails);
  };
  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
    // onAfterPrint:alert("Pdf Downloaded")
  })


  useEffect(() => {
    fetchData();
  }, [newData, search]);

  useEffect(() => {
    if (!TOKEN) {
      navigate("/");
    }
  }, [TOKEN]);

  const handleEdit = (id) => {
    navigate(`/edit-account-list/${id}`);
  };

  const handleDelete = async (id) => {

    // return;
    const data = await axios.delete(`${API}/deleteaccount/${id}`, {
      headers: { authtoken: `${TOKEN}` },
    });

    if (data?.data?.message === "Account  removed successfully") {
      setNewData(true);
      toast.success("Account  removed successfully");
      setNewData(false);
    }
  };

  const header = [
    "SL NO",
    "Account Name",
    "Account Code",
    "Place",
    "Phone No",
    "Key Person",
    "Price",
    "Default",
    "Options"
  ];

  const setNextPage = () => {
    if (pageInfo?.currentPage > 0) {
      if (page === pageInfo?.totalPages) return
      setPage(page + 1)
    }
  }

  const setPrevPage = () => {
    if (pageInfo?.currentPage > 1) {
      setPage(page - 1)
    }
  }

  const handleSearch = (e) => {
    e.persist();

    setSearch(e.target.value)
  }

  return (
    <div className={tableclasses.root}>
      <div className={tableclasses.body}>
        <div className={tableclasses.header}>
          <div className={tableclasses.name}>
            <div className={tableclasses.h2}>Account List</div>
            <div className={tableclasses.specification}>
              {rows?.length} available cards
            </div>
          </div>
          <div>
            <Buttons
              className={tableclasses.addButton2}
              onClick={() => navigate("/add-new-account")}
            >
              <UserSvg /> &nbsp; Add New Account
            </Buttons>
          </div>
        </div>

        <div className={tableclasses.filterSearch} >
          <div>
            <Buttons
              style={{
                marginLeft: '0px !important',
              }}
              className={tableclasses.printButton2}
              onClick={generatePDF}>Print</Buttons>
          </div>

          <div className={tableclasses.searchContainer}>
            <FiSearch className={tableclasses.searchIcon} />
            <TextField
              className={tableclasses.searchField}
              placeholder="Search"
              variant="standard"
              size="small"
              value={search}
              onChange={handleSearch}
            />
          </div>
        </div>

        <Table className={tableclasses.table} ref={reportContent}>
          <TableHead className={tableclasses.tableHead}>
            <TableRow>
              {header.map((header, i) => {
                return (
                  <TableCell className={tableclasses.customTableCell}>
                    {header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={tableclasses.customTableCell}
                >
                  <div className={tableclasses.profile}>
                    <div>
                      {index + 1}
                    </div>
                    <div className={tableclasses.name}>
                      <div></div>
                      <div className={tableclasses.specification}>
                        {row.specification}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.name}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.prefix}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.place}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.phone}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.keyperson}</div>
                </TableCell>

                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.price}</div>
                </TableCell>


                <TableCell className={tableclasses.customTableCell}>
                  <div>0</div>
                </TableCell>



                <TableCell className={tableclasses.customTableCell}>
                  <div>
                    <PopoverMenu
                      data={rows}
                      handleEdit={() => handleEdit(row._id)}
                      handleDelete={() => handleDelete(row._id)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={tableclasses.pagination}>
          <div className={tableclasses.name}>Showing {rows?.length} of {pageInfo?.totalItems} entries</div>
          <div>
            <Buttons
              onClick={setPrevPage}
              className={tableclasses.pageButton}>Previous</Buttons>
            <Buttons className={tableclasses.numButton}>{page}</Buttons>
            <Buttons
              onClick={setNextPage}
              className={tableclasses.pageButton}>Next</Buttons>
          </div>
          {/* <div></div> */}
        </div>
      </div>
    </div>
  );
};

export default GetAccountList;
