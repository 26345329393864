import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import PopoverMenu from "../../Components/Shared/Popover";
import { useReactToPrint } from "react-to-print";
import { FilterSvg, ResetFilterSvg } from "../../Components/Shared/UserSvg";
import { FiSearch } from "react-icons/fi";
import { toast } from "react-toastify";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { formStyles } from "../../Styles/Formstyle";
import { useParams } from "react-router-dom";
import Api from "../../helper/api";


const ViewPriceList = () => {
  const api = new Api();
  const params = useParams();
  const classes = formStyles();
  const [selectedReport, setSelectedReport] = useState(null)
  const navigate = useNavigate();
  const [test, setTest] = useState([]);

  const [price, setPrice] = useState(null);

  // pricelist id
  const id = params.id;

  const fetchData = async () => {

    try {
      const { data, status } = await api.fetchDetailedPrice({}, { id })

      if (status === 200) {
        setPrice(data?.data);
        setSelectedReport(0)
      }
    } catch (err) {
      toast.error("Error fetching data")
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTest(price?.reportFormat[selectedReport]?.tests)
  }, [selectedReport])

  return (
    <div className={classes.root}>
      <div className={classes.collectorForm}>
        <div className={classes.formheader}>
          <div className={classes.formname}>
            <div className={classes.formh2}>Albumin price list name </div>
            <div className={classes.viewHead}>
              <div className={classes.formspecification}>List ID: {price?._id ? price?._id : "NA"}</div>
              <div className={classes.formspecification}>
                Category: {price?.category?.name ? price?.category?.name : "NA"}
              </div>
              <div className={classes.formspecification}>Account: {price?.account?.prefix ? price?.account?.prefix : "NA"}</div>
              <div className={classes.formspecification}>Price: {price?.rate ? `₹${price?.rate}` : "NA"}</div>
              <div className={classes.formspecification}>
                Sample: {price?.reportFormat[selectedReport]?.sample ? price?.reportFormat[selectedReport]?.sample : "NA"}
              </div>
            </div>
            <Buttons
              className={classes.backToButton}
              style={{ width: "120px" }}
              onClick={() => navigate("/add-price-list")}
            >
              Back to Table
            </Buttons>
          </div>
        </div>
        <br />
        <div className={classes.veiwcard}>
          <div className={classes.viewBox}>
            <div className={classes.viewheading}>
              <div className={`${classes.halfArea} ${classes.fontStyle}`}>
                Report Format Name
              </div>
              <div
                className={`${classes.halfArea} ${classes.viewHead} ${classes.fontStyle}`}
              >
                <p className={classes.halfhead}>Report format ID</p>
                <p>Category</p>
              </div>
            </div>

            <hr className={classes.headline} />
            {
              price?.reportFormat.map((data, i) => (
                <div
                  onClick={() => setSelectedReport(i)}
                  key={i}
                  className={selectedReport === i ? `${classes.viewheading} ${classes.hoverview} ${classes.active}` : `${classes.viewheading} ${classes.hoverview}`}
                >
                  <div className={`${classes.halfArea} ${classes.insideItem}`}>
                    {data?.reportName}
                  </div>
                  <div
                    className={`${classes.halfArea} ${classes.viewHead} ${classes.insideItem}`}
                  >
                    <p className={classes.halfhead}>{data?.reportId}</p>
                    <p>{data.category[0]?.name ? data.category[0]?.name : "NA"}</p>
                  </div>
                </div>
              ))
            }
          </div>

          <div className={classes.pateintcard}>
            <div className={`${classes.viewheading}`}>
              <div className={`${classes.halfArea} ${classes.fontStyle}`}>
                Test Name
              </div>
              <div
                className={`${classes.halfArea} ${classes.viewHead} ${classes.fontStyle}`}
              >
                <p className={classes.halfhead}>Test ID</p>
                <p>farm</p>
              </div>
            </div>

            <hr className={classes.headline} />

            {
              test?.map((test) => (
                <div className={classes.viewheading}>
                  <div className={`${classes.halfArea} ${classes.insideItem}`}>
                    {test?.name ? test?.name : "NA"}
                  </div>
                  <div
                    className={`${classes.halfArea} ${classes.viewHead} ${classes.insideItem}`}
                  >
                    <p className={classes.halfhead}>{test?.id ? test?.id : "NA"}</p>
                    <p>{"dummy"}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPriceList;
