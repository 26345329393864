import React, { useEffect, useState } from "react";
import {
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@material-ui/core";
import options from "../../helper/options";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PopoverMenu = ({
  data,
  handleEdit,
  handleDelete,
  handleAssign,
  handleView,
  handlePreview,
  handleVerify,
  handleLayout,
  handleMacros,
  handlePrint,
  handleEntry,
  handleAccessRight,
  handleSignature,
  viewReport, visit,
  handlePriceList
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [role, setRole] = useState("");
  const user_role = localStorage.getItem("role");
  const path = location.pathname.split("/")[1];

  useEffect(() => {
    setRole(user_role)
  }, [user_role])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}
        style={{
          height: '32px',
          width: '32px',
          textAlign: 'center',
          borderRadius: '6px',
          backgroundColor: '#FAFAFA',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{
          fontSize: '22px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: '13px',
          '&.MuiIconButton-label': {
            paddingBottom: '13px',
            marginTop: '-12px',
          }
        }}>
          ...</span>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#273142',
            color: '#FFFFFF',
            borderRadius: '16px',
            width: '114px',
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {
          options.view.includes(path) && (
            <>
              <MenuItem
                onClick={() => {
                  handleView(data);
                  handleClose();
                }}
              >
                <ListItemText primary="View"
                  style={{
                    borderRadius: '43px',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    textAlign: 'center',
                    padding: '8px 0px',
                  }} />
              </MenuItem>
            </>
          )
        }
        {
          options.entry.includes(path) && role === "admin" &&
          <MenuItem
            onClick={() => {
              handleEntry(data);
              handleClose();
            }}
          >
            <ListItemText primary="Entry"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.edit.includes(path) &&
          <MenuItem onClick={() => handleEdit(data)}>
            <ListItemText primary="Edit"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.layout.includes(path) && role === "admin" &&
          <MenuItem
            onClick={() => {
              handleLayout(data);
              handleClose();
            }}
          >
            <ListItemText primary="Layout"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.verify.includes(path) && role === "admin" &&
          <MenuItem
            onClick={() => {
              handleVerify(data._id);
              handleClose();
            }}
          >
            <ListItemText primary="Verify"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.priceList.includes(path) &&
          <MenuItem
            onClick={() => {
              handlePriceList();
              handleClose();
            }}
          >
            <ListItemText primary="PriceList"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.preview.includes(path) &&
          <MenuItem
            onClick={() => {
              handlePreview(data._id);
              handleClose();
            }}
          >
            <ListItemText primary="Preview"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.macros.includes(path) && role === "admin" &&
          <MenuItem
            onClick={() => {
              handleMacros();
              handleClose();
            }}
          >
            <ListItemText primary="Macros"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.delete.includes(path) && role === "admin" &&
          < MenuItem
            onClick={() => {
              handleDelete(data);
              handleClose();
            }}
          >
            <ListItemText primary="Delete"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.report.includes(path) &&
          <MenuItem
            onClick={() => {
              viewReport(data._id);
              handleClose();
            }}
          >
            <ListItemText primary="Report"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.assign.includes(path) &&
          <MenuItem
            onClick={() => {
              handleAssign(data._id);
              handleClose();
            }}
          >
            <ListItemText primary="Assign"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
        {
          options.accessRight.includes(path) && (
            <>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleAccessRight();
                }}
              >
                <ListItemText primary="Access right"
                  style={{
                    borderRadius: '43px',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    textAlign: 'center',
                    padding: '8px 0px',
                  }} />
              </MenuItem>
            </>
          )
        }
        {
          options.print.includes(path) &&
          <MenuItem
            onClick={() => {
              handlePrint();
              handleClose();
            }}
          >
            <ListItemText primary="Print"
              style={{
                borderRadius: '43px',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                textAlign: 'center',
                padding: '8px 0px',
              }} />
          </MenuItem>
        }
      </Popover>
    </div >
  );
};

export default PopoverMenu;
