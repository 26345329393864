import React, { useEffect, useRef, useState } from 'react'
import Api from '../../helper/api'
import { useNavigate } from 'react-router-dom';
import { formStyles } from '../../Styles/Formstyleclone';
import styles from "../../Styles/DoctorWisePayment.module.css";
import FormControl from "@material-ui/core/FormControl";
import Selects, { components, MenuListProps } from 'react-select';
import { useFormik } from 'formik';
import { labSuffix, paymentMode } from '../../helper/data';
import Buttons from '../../Components/Shared/Buttons';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { tableStyles } from '../../Styles/AddNewDocStyle';
import { formatTime, formatedDate, formatedDateInDigit } from '../../helper/helper';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';


const header = [
    "SL No",
    "Date",
    "Lab Ref No",
    "Patient Name",
    "Investigations",
    "Bill Total",
    "Discount",
    "Paid Amount",
    "Due",
];


const DoctorPayment = () => {
    const api = new Api();
    const navigate = useNavigate();
    const classes = formStyles();
    const tableclasses = tableStyles();

    const [account, setAccount] = useState([]); // lab accounts
    const [doctors, setDoctors] = useState([]);
    const [operators, setOperator] = useState([])
    const [paymentInfo, setPaymentInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1)
    const [rows, setRows] = useState([]);
    const [tab, setTab] = useState(1)
    const reportContent = useRef()
    const [totalDue, setTotalDue] = useState(0)
    const [totalBill, setTotalBill] = useState(0)

    const [newPage, setNewPage] = useState(true);
    const [paginatedData, setPaginatedData] = useState([])

    const [fromDate, setFromDate] = useState(new Date());
    const [fromTime, setFromTime] = useState("10:30")


    const [toDate, setToDate] = useState(new Date());
    const [toTime, setToTime] = useState("23:00")


    const formik = useFormik({
        initialValues: {
            labPrefix: "",
            operator: "",
            labSuffix: "",
            mode: "",
            account: "",
            doctor: ""
        },
        onSubmit: () => {
            fetchPayment()
        }
    })

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue } = formik;

    useEffect(() => {
        fetchAccount();
        fetchUser();
        fetchDoctor();
    }, []);

    // fetch lab accounts -----------------------------
    // ------------------------------------------------
    const fetchAccount = async () => {
        const data = await api.fetchAccount();
        setAccount(data?.data?.accountDetails);
    };

    // fetch user/operators ---------------------------
    // ------------------------------------------------
    const fetchUser = async () => {
        try {
            const data = await api.fetchUser();

            setOperator(data.data.users)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchDoctor = async () => {
        try {
            const { data, status } = await api.fetchDoctors()
            if (status === 200) {
                setDoctors(data.doctors)
            }
        } catch (err) {
            console.log(err)
        }
    }

    // fetch payment
    const fetchPayment = async () => {
        try {
            const { data, status } = await api.fetchDoctorWisePayment({
                ...values,
                fromDate,
                fromTime,
                toDate,
                toTime
            })

            if (status === 200) {
                setRows(data.data)
                setTotalPages(Math.floor(data.data?.length / 10))

                setTab(2);
            }
        } catch (err) {
            toast.error(err.response.data.message || "Internal server error")
        }
    }

    const setNextPage = () => {
        if (page > 0) {
            if (page === totalPages) return;
            setPage(page + 1);
        }
    };

    const setPrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {
                    tab === 1 ?
                        <>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", margin: "15px 0" }}>
                                <h5>Daily payment details</h5>
                            </div>


                            <div className={styles.form}>
                                <div>
                                    <div className={styles.formLable}>
                                        From Date
                                    </div>
                                    <div style={{ width: "95%" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <DatePicker
                                                    onChange={(value) => setFromDate(value.$d)}
                                                    value={dayjs(fromDate)}
                                                    sx={{ marginTop: "2px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        From Time
                                    </div>
                                    <div style={{ width: "95%" }}>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <TimePicker
                                                    onChange={(value) => setFromTime(`${value.$H}:${value.$m}`)}
                                                    value={dayjs(`2023-04-17T${fromTime}`)}
                                                    sx={{ marginTop: "2px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>

                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        To Date
                                    </div>
                                    <div style={{ width: "95%" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <DatePicker
                                                    onChange={(value) => setToDate(value.$d)}
                                                    value={dayjs(toDate)}
                                                    sx={{ marginTop: "2px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        To Time
                                    </div>
                                    <div style={{ width: "95%" }}>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <TimePicker
                                                    onChange={(value) => setToTime(`${value.$H}:${value.$m}`)}
                                                    value={dayjs(`2023-04-17T${toTime}`)}
                                                    sx={{ marginTop: "2px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                <div>
                                    <div className={styles.formLable}>
                                        Doctor
                                    </div>
                                    <FormControl style={{ width: "95%" }}>

                                        <Selects
                                            options={[{ value: "", label: "All" }, ...doctors?.map((doctor) => {
                                                return {
                                                    value: doctor?._id,
                                                    label: doctor?.name
                                                }
                                            })]}
                                            value={
                                                values.doctor ?
                                                    {
                                                        value: doctors?.find((doctor) => doctor._id === values.doctor)?._id,
                                                        label: doctors?.find((doctor) => doctor._id === values.doctor)?.name
                                                    }
                                                    : ""
                                            }
                                            placeholder="All"
                                            styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "11px" }) }}
                                            onBlur={() => setFieldTouched("operator")}
                                            onChange={(value) => setFieldValue("doctor", value.value)}
                                        />
                                    </FormControl>{" "}
                                    <br />
                                </div>
                            </div>
                            <div className={classes.formDiv4}>
                                <Buttons
                                    className={classes.cancelButton}
                                    onClick={() => navigate("/list-patience")}
                                >
                                    Cancel
                                </Buttons>
                                <Buttons
                                    className={classes.submitButton}
                                    onClick={handleSubmit}
                                >
                                    Preview
                                </Buttons>
                            </div>
                        </>
                        : tab === 2
                            ? <>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", margin: "15px 0" }}>
                                    <h5>Daily payment details</h5>
                                    <Buttons
                                        className={classes.cancelButton}
                                        style={{ marginTop: "0 !important", marginRight: "0 !important" }}
                                        onClick={() => setTab(1)}
                                    >
                                        Cancel
                                    </Buttons>

                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0px" }}>
                                    <Buttons className={tableclasses.numButton}>
                                        Print
                                    </Buttons>
                                </div>
                                <h4 className={styles.heading}>Naglikar diagnostic Scanning  center</h4>
                                <h6 className={styles.sub_heading}>Daily Payment Register</h6>
                                <div className={styles.sub_Title}>
                                    <h5>Date: {formatedDate(fromDate)} - {formatedDate(toDate)}</h5>
                                    <h5>Ref. Dr.: {values.doctor ? doctors?.find((doctor) => doctor._id === values.doctor)?.prefix : "All"} </h5>
                                </div>
                                {/* <div className={styles.sub_Title2}>
                                    <h5>20/09/2023 (Wednesday)</h5>
                                </div> */}
                                <Table className={tableclasses.table} ref={reportContent} style={{ width: "100%" }}>
                                    <TableHead className={tableclasses.paymentTableHead} style={{height: "30px"}}>
                                        <TableRow>
                                            {header.map((header) => {
                                                return (
                                                    <TableCell className={tableclasses.customHeadName}>
                                                        {header}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {
                                            [...rows].slice((page - 1) * 10, page * 10).map((row, index) => {
                                                let totalBill = 0;
                                                let totalDeposits = 0;
                                                for (let i = 0; i < row.data.length; i++) {
                                                    totalBill += row.data[i]?.totalBill
                                                    totalDeposits += row.data[i]?.totalDeposits
                                                }
                                                return (
                                                    <>
                                                        <TableRow>
                                                            <TableCell className={tableclasses.customTableCell}>
                                                                {row.name}
                                                            </TableCell>
                                                        </TableRow>
                                                        {
                                                            row.data.map((data, index) => (
                                                                <TableRow key={row.id}>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className={tableclasses.customTableCell}
                                                                        style={{ display: "flex" }}
                                                                    >
                                                                        <div className={tableclasses.name}>
                                                                            <div>{(page - 1) * 10 + index + 1}</div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        className={tableclasses.customTableCell}
                                                                    >
                                                                        <div className={tableclasses.name}>
                                                                            <div>

                                                                                {formatedDateInDigit(data.createdAt)}
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className={tableclasses.customTableCell}>
                                                                        <div>
                                                                            {row?.account?.prefix ? row?.account?.prefix : "NA"}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className={tableclasses.customTableCell}>
                                                                        <div>
                                                                            {data?.firstName} {data?.lastName}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className={tableclasses.customTableCell}>
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            {
                                                                                data?.investigation?.map((investigation, index) => (
                                                                                    <>
                                                                                        <p>
                                                                                            {investigation?.shortName}
                                                                                        </p>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className={tableclasses.customTableCell}>
                                                                        <div>
                                                                            {data.totalBill}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className={tableclasses.customTableCell}>
                                                                        0
                                                                    </TableCell>
                                                                    <TableCell className={tableclasses.customTableCell}>
                                                                        {data.totalDeposits}
                                                                    </TableCell>
                                                                    <TableCell className={tableclasses.customTableCell}>
                                                                        {data.totalBill - data.totalDeposits}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                        <TableRow style={{ borderBottom: "1px solid black", borderTop: "1px solid black" }}>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                className={tableclasses.customTableCell}
                                                                style={{ display: "flex" }}
                                                            >
                                                                <div className={tableclasses.name}>

                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                className={tableclasses.customTableCell}
                                                            >
                                                                <div>

                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell}>
                                                                <div>

                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell}>
                                                                <div>

                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell}>
                                                                <div>
                                                                    Total For {row.name}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell}>
                                                                <div>
                                                                    {totalBill}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell}>
                                                                <div>
                                                                    0
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell}>
                                                                <div>
                                                                    {totalDeposits}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={tableclasses.customTableCell}>
                                                                <div>
                                                                    {totalBill - totalDeposits}
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                <div className={tableclasses.pagination}>
                                    <div className={tableclasses.name}>
                                        {/* Showing {rows?.length} of {pageInfo?.totalItems} entries */}
                                    </div>
                                    <div>
                                        <Buttons
                                            onClick={setPrevPage}
                                            className={tableclasses.pageButton}
                                        >
                                            Previous
                                        </Buttons>
                                        <Buttons className={tableclasses.numButton}>
                                            {page}
                                        </Buttons>
                                        <Buttons
                                            onClick={setNextPage}
                                            className={tableclasses.pageButton}
                                        >
                                            Next
                                        </Buttons>
                                    </div>
                                </div>
                            </>
                            : <></>
                }
            </div>
        </div>
    )
}

export default DoctorPayment