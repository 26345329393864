import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import PopoverMenu from "../../Components/Shared/Popover";
import Buttons from "../../Components/Shared/Buttons";
import { useReactToPrint } from "react-to-print";
import ReportGroup from './ReportGroup';
import { FiSearch } from "react-icons/fi";
import TextField from "@material-ui/core/TextField";
import { DropdownSvg, FilterSvg, ResetFilterSvg, UserSvg } from "../../Components/Shared/UserSvg";
import FilterCategory from "../MasterData/FilterCategory";

const ReportFormat = () => {
  const tableclasses = tableStyles();

  const TOKEN = localStorage.getItem("logintoken");
  const navigate = useNavigate();

  const [rows, setRows] = useState();
  const [name, SetName] = useState("");
  const [newData, setNewData] = useState(false);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState();
  const [sortByDateAsc, setSortByDateAsc] = useState(true);
  const reportContent = useRef();

  const [filter, setFilter] = useState({
    category: null,
    alphabets: null,
    sortBy: null
  })

  const [filterData, setFilterData] = useState({
    category: [],
    alphabets: [],
    sortBy: null,
    search: ""
  })

  const fetchData = async () => {
    const data = await axios.get(`${API}/getreportformat/${page}/10?category=${JSON.stringify(filterData.category)}&alphabets=${JSON.stringify(filterData.alphabets)}&sortBy=${filterData.sortBy}&search=${filterData.search}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setRows(data.data.reportFormat);
    setPageInfo(data.data);
  };

  useEffect(() => {
    fetchData();
  }, [newData, page, filterData.category, filterData.alphabets, filterData.sortBy, filterData.search]);

  const handleDelete = async (id) => {
    const data = await axios.delete(`${API}/deletereportformat/${id}`, {
      headers: { authtoken: `${TOKEN}` },
    });
    if (data?.data?.message === "ReportFormat removed successfully") {
      setNewData(true);
      toast.success("ReportFormat removed successfully");
      setNewData(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-reports-format/${id}`);
  };

  const handleView = (id) => {
    navigate(`/view-reports-format/${id}`);
  };
  const setNextPage = () => {
    if (pageInfo?.currentPage > 0) {
      if (page === pageInfo?.totalPages) return;
      setPage(page + 1);
    }
  };

  const setPrevPage = () => {
    if (pageInfo.currentPage > 1) {
      setPage(page - 1);
    }
  };

  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
    // onAfterPrint:alert("Pdf Downloaded")
  });

  const handleReset = () => {
    setFilterData({
      category: [],
      alphabets: [],
      sortBy: null,
      search: ""
    })
  }

  const handleSearch = (e) => {
    e.persist();

    setFilterData((prev) => {
      return { ...prev, search: e.target.value }
    })
  }

  return (
    <div className={tableclasses.root}>
      <FilterCategory
        filter={filter}
        filterData={filterData}
        setFilterData={setFilterData}
        setFilter={setFilter} />
      <div className={tableclasses.body}>
        <div className={tableclasses.header}>
          <div className={tableclasses.name}>
            <div className={tableclasses.h2}>Report Group Format </div>
            <div className={tableclasses.specification}>
              {rows?.length} available report group
            </div>
          </div>

          <div>
            <Buttons
              className={tableclasses.addButton}
              onClick={() => navigate("/register-report-format")}
            >
              <UserSvg /> &nbsp; Add new Format
            </Buttons>
          </div>
        </div>

        <div className={tableclasses.filterSearch}>
          <div>
            <Buttons className={tableclasses.filterButton1}>
              <FilterSvg />
            </Buttons>
            <Buttons className={tableclasses.filterButton2}>Filter By</Buttons>
            <Buttons
              onClick={(e) => setFilter((prev) => { return { ...prev, category: e.currentTarget } })}
              className={tableclasses.filterButton2}
            >
              Report Group <DropdownSvg />
            </Buttons>
            <Buttons
              onClick={(e) => setFilter((prev) => { return { ...prev, alphabets: e.currentTarget } })}
              className={tableclasses.filterButton2}
            >
              Albhabets <DropdownSvg />
            </Buttons>
            <Buttons
              onClick={(e) => setFilter((prev) => { return { ...prev, sortBy: e.currentTarget } })}
              className={tableclasses.filterButton2}>
              Sort By
              <DropdownSvg />  </Buttons>
            <Buttons
              onClick={handleReset}
              className={tableclasses.filterButton3}>
              <ResetFilterSvg />
              <span style={{ color: "#FF8743", marginLeft: 6, text: "center" }}>
                Reset FIlter
              </span>{" "}
            </Buttons>
            <Buttons className={tableclasses.printButton} onClick={generatePDF}>
              Print
            </Buttons>
          </div>

          <div className={tableclasses.searchContainer}>
            <FiSearch className={tableclasses.searchIcon} />
            <TextField
              className={tableclasses.searchField}
              placeholder="Search"
              variant="standard"
              size="small"
              value={filterData.search}
              onChange={handleSearch}
            />
          </div>
        </div>
        <Table className={tableclasses.table} ref={reportContent}>
          <TableHead className={tableclasses.tableHead}>
            <TableRow>
              <TableCell className={tableclasses.customHeadName}>
                Report ID
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Report name
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Short Name
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                ReportGroup
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Sample Name
              </TableCell>
              <TableCell className={tableclasses.customHeadName}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={tableclasses.customTableCell}
                >
                  <div className={tableclasses.name}>
                    <div>{row.reportId}</div>
                  </div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.reportName}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.reportshortName}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row?.reportcategory?.length > 0 ? row?.reportcategory?.map((m) => m.name) : "NA"}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>{row.sample}</div>
                </TableCell>
                <TableCell className={tableclasses.customTableCell}>
                  <div>
                    <PopoverMenu
                      data={rows}
                      handleEdit={() => handleEdit(row._id)}
                      handleDelete={() => handleDelete(row._id)}
                      handleView={() => handleView(row._id)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={tableclasses.pagination}>
          <div className={tableclasses.name}>
            Showing {rows?.length} of {pageInfo?.totalItems} entries
          </div>
          <div>
            <Buttons onClick={setPrevPage} className={tableclasses.pageButton}>
              Previous
            </Buttons>
            <Buttons className={tableclasses.numButton}>
              {pageInfo?.currentPage}
            </Buttons>
            <Buttons onClick={setNextPage} className={tableclasses.pageButton}>
              Next
            </Buttons>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportFormat;
