import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import axios from "axios";
import JSZip from 'jszip';

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import PopoverMenu from "../../Components/Shared/Popover";
import { EnteredSvg, PendingSvg, UserSvg, VerifiedSvg } from "../../Components/Shared/UserSvg";
import Api from "../../helper/api";
import AddMacro from "../../Components/Shared/AddMacro";
import ReportLayout from "../../Components/Shared/ReportLayout";
import { useReactToPrint } from "react-to-print";


const PatientReport = () => {
  const tableclasses = tableStyles();
  const navigate = useNavigate();
  const api = new Api();
  const reportContent = useRef(null)

  // --------------------------------------------

  const [macro, setMacro] = useState(false)
  const [layout, setLayout] = useState(false)
  const [layoutId, setLayoutId] = useState(null)
  const [macroId, setMacroId] = useState(null)

  const handleMacros = (status, id) => {
    setMacro(status)
    setMacroId(id)
  }

  const handleLayout = (status, id) => {
    setLayout(status)
    setLayoutId(id)
  }
  // --------------------------------------------

  const [rows, setRows] = useState();
  const [newData, setNewData] = useState(false);
  const [investigation, setInvestigation] = useState([]);
  const params = useParams();

  const fetchData = async () => {
    const data = await api.fetchPatientById({}, { id: params.id })
    setRows(data.data);
    setInvestigation(data.data?.investigation)
  };

  const generateZip = async () => {
    try {
      const links = [];
      const reports = [];

      //  store the verified reports
      for (let i = 0; i < investigation.length; i++) {
        let report = investigation[i];

        if (report?.status.toUpperCase() === "VERIFIED" && report.report) {
          reports.push(report)
        }
      }

      for (let i = 0; i < reports.length; i++) {
        try {
          const { data, status } = await api.downloadReport({}, { id: params.id, reportId: reports[i]._id })

          links.push(data)
        } catch (err) {
          console.log(err)
        }
      }

      const zip = new JSZip();

      // Download and add each image to the zip
      for (const [index, imageUrl] of links.entries()) {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const fileName = `report_${index + 1}.pdf`;

        // Add the image to the zip
        zip.file(fileName, blob, { binary: true });
      }

      // Generate the zip file
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        // Create a link element and trigger a click event to download the zip file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `reports${params.id}.zip`;
        link.click();
      });
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    fetchData();
  }, [newData]);

  const handleEdit = (id) => {
    return;
    navigate(`/edit-patience-report/${id}/${params.phone}`);
  };

  const handlePreview = (id, reportId) => {
    navigate(`/patience-report-preview/${id}/${reportId}`);
  };

  const handlePrint = async (status, id, reportId) => {
    const report = investigation.find((item) => item._id === reportId)
    if (status.toUpperCase() === "VERIFIED" && report.report) {

      const { data, status } = await api.downloadReport({}, { id, reportId })

      const link = document.createElement('a');
      link.href = data;
      link.download = true;

      // Append the link to the document, trigger the click, and remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } else {
      toast.error("Report not available!")
    }
  }

  const handleEntry = (id, reportId) => {
    navigate(`/new-report-entry/${id}/${reportId}`);
  };

  const handleVerify = (id, reportId) => {
    navigate(`/verify-report/${id}/${reportId}`);
  };

  // const handleDelete = async (id) => {
  //   const data = await axios.delete(`${API}/delete-user/${id}`, {
  //     headers: { authtoken: `${TOKEN}` },
  //   });
  //   if (data.data.message === "User removed successfully") {
  //     setNewData(true);
  //     toast.success("User removed successfully");
  //     setNewData(false);
  //   }
  // };

  const formatedDate = (newDate) => {
    const date = new Date(newDate);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const formatTime = (newTime) => {
    const time = new Date(newTime);
    return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const header = [
    "SL No",
    "Reports",
    "Operator",
    "Date & time",
    "Rep No",
    "Email/SMS",
    "Status",
    "Options",
  ];

  return (
    <>
      {
        macro &&
        <AddMacro
          handleClose={() => handleMacros(false, null)}
          macroId={macroId}
          id={params.id}
        />
      }
      {
        layout &&
        <ReportLayout
          handleClose={() => handleLayout(false, null)}
          layoutId={layoutId}
          id={params.id}
        />
      }
      <div className={tableclasses.root}>
        <div className={tableclasses.body}>
          <div className={tableclasses.header}>
            <div className={tableclasses.name}>
              <div className={tableclasses.h2}>
                {rows?.firstName} {rows?.lastName}
              </div>
              <div className={tableclasses.specification}>
                {investigation?.length} available Reports
              </div>
              <Buttons
                className={tableclasses.printButton}
                onClick={generateZip}
                style={{ marginLeft: 0, marginTop: "13px", width: "90px" }}
              >Print</Buttons>
            </div>
            <div>
              <Buttons
                className={tableclasses.addButton}
                onClick={() => navigate(`/appointment-view/${params.id}`)}
              >
                <UserSvg /> &nbsp; Add new report
              </Buttons>
            </div>
          </div>

          <Table className={tableclasses.table} ref={reportContent}>
            <TableHead className={tableclasses.tableHead}>
              <TableRow>
                {header.map((header, i) => {
                  return (
                    <TableCell className={tableclasses.customTableCell}>
                      {header}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {investigation?.map((row, index) => {
                return (
                  <>
                    <TableRow key={row._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={tableclasses.customTableCell}
                      >
                        <div className={tableclasses.profile}>
                          <div>{index + 1}</div>
                        </div>
                      </TableCell>
                      <TableCell className={tableclasses.customTableCell}>
                        <div>{row?.priceListName}</div>
                      </TableCell>
                      <TableCell className={tableclasses.customTableCell}>
                        <div>{rows?.assignToUser?.name}</div>
                      </TableCell>
                      <TableCell className={tableclasses.customTableCell}>
                        {
                          row?.date &&
                          <div>{formatedDate(row.date)}</div>
                        }
                        <div className={tableclasses.specification}>
                          {
                            row?.date ? formatTime(row.date) : "NA"
                          }
                        </div>
                      </TableCell>
                      <TableCell className={tableclasses.customTableCell}>
                        <div>{rows._id}</div>
                      </TableCell>
                      <TableCell className={tableclasses.customTableCell}>
                        <Buttons className={tableclasses.customActive}>
                          <div>Yes</div>
                        </Buttons>
                      </TableCell>

                      <TableCell className={tableclasses.customTableCell}>
                        {/* <Buttons className={tableclasses.customActive}>
                          <div>{row?.status}</div>
                        </Buttons> */}
                        <div
                          style={{
                            text: "center",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {
                              row?.status.toUpperCase() === "PENDING"
                                ? <PendingSvg />
                                : row?.status.toUpperCase() === "ENTERED" ? <EnteredSvg />
                                  : <VerifiedSvg />
                            }
                            <span
                              style={{
                                color: row?.status.toUpperCase() === "PENDING"
                                  ? "#B82C3A"
                                  : row?.status.toUpperCase() === "ENTERED" ? "#D48A48"
                                    : "#60BB47",
                                marginLeft: 6,
                                text: "center",
                              }}
                            >
                              {row?.status}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={tableclasses.customTableCell}>
                        <div className={tableclasses.customArrow}>
                          <PopoverMenu
                            data={row}
                            handleLayout={() => handleLayout(true, row._id)}
                            handleEdit={() => handleEdit(rows._id)}
                            handlePreview={() => handlePreview(rows._id, row._id)}
                            handleVerify={() => handleVerify(rows._id, row._id)}
                            handleEntry={() => handleEntry(rows._id, row._id)}
                            handleMacros={() => handleMacros(true, row._id)}
                            handlePrint={() => handlePrint(row?.status, rows._id, row._id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default PatientReport;
