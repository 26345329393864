import React from "react";
import { makeStyles } from '@material-ui/core/styles';

export const UserSvg = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.49984 9.88346C5.65889 9.88346 4.1665 8.39108 4.1665 6.55013C4.1665 4.70918 5.65889 3.2168 7.49984 3.2168C9.34079 3.2168 10.8332 4.70918 10.8332 6.55013C10.8332 8.39108 9.34079 9.88346 7.49984 9.88346ZM15.8332 9.88347C15.3729 9.88347 14.9998 9.51038 14.9998 9.05014V7.38347H13.3332C12.8729 7.38347 12.4998 7.01038 12.4998 6.55014C12.4998 6.0899 12.8729 5.7168 13.3332 5.7168H14.9998V4.05014C14.9998 3.5899 15.3729 3.2168 15.8332 3.2168C16.2934 3.2168 16.6665 3.5899 16.6665 4.05014V5.7168H18.3332C18.7934 5.7168 19.1665 6.0899 19.1665 6.55014C19.1665 7.01038 18.7934 7.38347 18.3332 7.38347H16.6665V9.05014C16.6665 9.51038 16.2934 9.88347 15.8332 9.88347Z"
        fill="white"
      />
      <path
        d="M0.00054307 17.5494C0.323549 13.5722 3.55159 11.5501 7.48612 11.5501C11.476 11.5501 14.7541 13.4611 14.9983 17.5501C15.008 17.713 14.9983 18.2168 14.3722 18.2168C11.2843 18.2168 6.6956 18.2168 0.606252 18.2168C0.39726 18.2168 -0.0170515 17.7661 0.00054307 17.5494Z"
        fill="white"
      />
    </svg>
  );
};

export const DoctorSvg = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.49984 9.88346C5.65889 9.88346 4.1665 8.39108 4.1665 6.55013C4.1665 4.70918 5.65889 3.2168 7.49984 3.2168C9.34079 3.2168 10.8332 4.70918 10.8332 6.55013C10.8332 8.39108 9.34079 9.88346 7.49984 9.88346ZM15.8332 9.88347C15.3729 9.88347 14.9998 9.51038 14.9998 9.05014V7.38347H13.3332C12.8729 7.38347 12.4998 7.01038 12.4998 6.55014C12.4998 6.0899 12.8729 5.7168 13.3332 5.7168H14.9998V4.05014C14.9998 3.5899 15.3729 3.2168 15.8332 3.2168C16.2934 3.2168 16.6665 3.5899 16.6665 4.05014V5.7168H18.3332C18.7934 5.7168 19.1665 6.0899 19.1665 6.55014C19.1665 7.01038 18.7934 7.38347 18.3332 7.38347H16.6665V9.05014C16.6665 9.51038 16.2934 9.88347 15.8332 9.88347Z"
        fill="white"
      />
      <path
        d="M0.00054307 17.5494C0.323549 13.5722 3.55159 11.5501 7.48612 11.5501C11.476 11.5501 14.7541 13.4611 14.9983 17.5501C15.008 17.713 14.9983 18.2168 14.3722 18.2168C11.2843 18.2168 6.6956 18.2168 0.606252 18.2168C0.39726 18.2168 -0.0170515 17.7661 0.00054307 17.5494Z"
        fill="white"
      />
    </svg>
  );
};

export const CollectorSvg = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.49984 9.88346C5.65889 9.88346 4.1665 8.39108 4.1665 6.55013C4.1665 4.70918 5.65889 3.2168 7.49984 3.2168C9.34079 3.2168 10.8332 4.70918 10.8332 6.55013C10.8332 8.39108 9.34079 9.88346 7.49984 9.88346ZM15.8332 9.88347C15.3729 9.88347 14.9998 9.51038 14.9998 9.05014V7.38347H13.3332C12.8729 7.38347 12.4998 7.01038 12.4998 6.55014C12.4998 6.0899 12.8729 5.7168 13.3332 5.7168H14.9998V4.05014C14.9998 3.5899 15.3729 3.2168 15.8332 3.2168C16.2934 3.2168 16.6665 3.5899 16.6665 4.05014V5.7168H18.3332C18.7934 5.7168 19.1665 6.0899 19.1665 6.55014C19.1665 7.01038 18.7934 7.38347 18.3332 7.38347H16.6665V9.05014C16.6665 9.51038 16.2934 9.88347 15.8332 9.88347Z"
        fill="white"
      />
      <path
        d="M0.00054307 17.5494C0.323549 13.5722 3.55159 11.5501 7.48612 11.5501C11.476 11.5501 14.7541 13.4611 14.9983 17.5501C15.008 17.713 14.9983 18.2168 14.3722 18.2168C11.2843 18.2168 6.6956 18.2168 0.606252 18.2168C0.39726 18.2168 -0.0170515 17.7661 0.00054307 17.5494Z"
        fill="white"
      />
    </svg>
  );
};

export const EnteredSvg = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8.61523L4.2 5.76523C4.13826 5.71833 4.08811 5.65787 4.05344 5.58852C4.01876 5.51916 4.00048 5.44277 4 5.36523V3.11523C4 2.98263 4.05268 2.85545 4.14645 2.76168C4.24021 2.66791 4.36739 2.61523 4.5 2.61523H11.5C11.6326 2.61523 11.7598 2.66791 11.8536 2.76168C11.9473 2.85545 12 2.98263 12 3.11523V5.34023C11.9995 5.41777 11.9812 5.49416 11.9466 5.56352C11.9119 5.63287 11.8617 5.69333 11.8 5.74023L8 8.61523Z"
        stroke="#D48A48"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 8.61523L4.2 11.4652C4.13826 11.5121 4.08811 11.5726 4.05344 11.642C4.01876 11.7113 4.00048 11.7877 4 11.8652V14.1152C4 14.2478 4.05268 14.375 4.14645 14.4688C4.24021 14.5626 4.36739 14.6152 4.5 14.6152H11.5C11.6326 14.6152 11.7598 14.5626 11.8536 14.4688C11.9473 14.375 12 14.2478 12 14.1152V11.8902C11.9995 11.8127 11.9812 11.7363 11.9466 11.667C11.9119 11.5976 11.8617 11.5371 11.8 11.4902L8 8.61523Z"
        stroke="#D48A48"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PendingSvg = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8.61523L4.2 5.76523C4.13826 5.71833 4.08811 5.65787 4.05344 5.58852C4.01876 5.51916 4.00048 5.44277 4 5.36523V3.11523C4 2.98263 4.05268 2.85545 4.14645 2.76168C4.24021 2.66791 4.36739 2.61523 4.5 2.61523H11.5C11.6326 2.61523 11.7598 2.66791 11.8536 2.76168C11.9473 2.85545 12 2.98263 12 3.11523V5.34023C11.9995 5.41777 11.9812 5.49416 11.9466 5.56352C11.9119 5.63287 11.8617 5.69333 11.8 5.74023L8 8.61523Z"
        stroke="#B82C3A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 8.61523L4.2 11.4652C4.13826 11.5121 4.08811 11.5726 4.05344 11.642C4.01876 11.7113 4.00048 11.7877 4 11.8652V14.1152C4 14.2478 4.05268 14.375 4.14645 14.4688C4.24021 14.5626 4.36739 14.6152 4.5 14.6152H11.5C11.6326 14.6152 11.7598 14.5626 11.8536 14.4688C11.9473 14.375 12 14.2478 12 14.1152V11.8902C11.9995 11.8127 11.9812 11.7363 11.9466 11.667C11.9119 11.5976 11.8617 11.5371 11.8 11.4902L8 8.61523Z"
        stroke="#B82C3A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const VerifiedSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.855469 8.51324C0.855469 6.39151 1.69832 4.35668 3.19861 2.85639C4.69891 1.3561 6.73374 0.513245 8.85547 0.513245C10.9772 0.513245 13.012 1.3561 14.5123 2.85639C16.0126 4.35668 16.8555 6.39151 16.8555 8.51324C16.8555 10.635 16.0126 12.6698 14.5123 14.1701C13.012 15.6704 10.9772 16.5132 8.85547 16.5132C6.73374 16.5132 4.69891 15.6704 3.19861 14.1701C1.69832 12.6698 0.855469 10.635 0.855469 8.51324ZM8.39894 11.9372L13.0048 6.17938L12.1728 5.51378L8.24534 10.4215L5.46347 8.10365L4.7808 8.92284L8.39894 11.9383V11.9372Z" fill="#60BB47" />
    </svg>
  )
}

export const FilterSvg = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.63119 3.349H13.3687C13.4657 3.34903 13.5606 3.37727 13.6418 3.43027C13.723 3.48327 13.7871 3.55875 13.8261 3.64752C13.8652 3.73628 13.8776 3.83449 13.8619 3.93019C13.8461 4.02589 13.8029 4.11494 13.7374 4.1865L9.63119 8.70525C9.54573 8.79672 9.49873 8.91757 9.49994 9.04275V12.5802C9.5007 12.6633 9.48048 12.7453 9.44115 12.8185C9.40182 12.8917 9.34465 12.9538 9.27494 12.999L7.27494 14.3302C7.19992 14.3797 7.11297 14.408 7.02323 14.4121C6.9335 14.4163 6.84429 14.3963 6.76499 14.3541C6.68569 14.3118 6.61922 14.2491 6.57258 14.1723C6.52594 14.0955 6.50085 14.0076 6.49994 13.9177V9.04275C6.50114 8.91757 6.45415 8.79672 6.36869 8.70525L2.26244 4.1865C2.19697 4.11494 2.15373 4.02589 2.13798 3.93019C2.12224 3.83449 2.13466 3.73628 2.17374 3.64752C2.21282 3.55875 2.27688 3.48327 2.3581 3.43027C2.43932 3.37727 2.5342 3.34903 2.63119 3.349V3.349Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const useStyles = makeStyles((theme) => ({
  customIconButton: {
    padding: '8px', // Adjust the padding as needed
  },
}));

export const DropdownSvg = () => {
  const classes = useStyles();
  return (
    <div className={classes.customIconButton}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 6.83594L8 10.8359L12 6.83594"
          stroke="white"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round" />

      </svg>
    </div>
  );
};
export const ResetFilterSvg = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9873 6.58026H1.9873V3.58026"
        stroke="#FF8743"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.1123 12.2365C4.88131 13.0061 5.86131 13.5304 6.92833 13.743C7.99535 13.9556 9.10145 13.8469 10.1067 13.4308C11.112 13.0146 11.9712 12.3097 12.5757 11.4051C13.1803 10.5005 13.503 9.43699 13.503 8.349C13.503 7.26101 13.1803 6.19747 12.5757 5.2929C11.9712 4.38833 11.112 3.68338 10.1067 3.26723C9.10145 2.85108 7.99535 2.74243 6.92833 2.95501C5.86131 3.16759 4.88131 3.69186 4.1123 4.4615L1.9873 6.58025"
        stroke="#FF8743"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CrossIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_75_4396)">
        <path
          d="M5.92578 17.3074L17.2098 6.02344M5.92578 6.02344L17.2098 17.3074"
          stroke="#B82C3A"
          stroke-width="1.88067"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <rect
        x="0.645773"
        y="0.872336"
        width="21.146"
        height="21.146"
        rx="3.84321"
        stroke="#B82C3A"
        stroke-width="0.854047"
      />
      <defs>
        <clipPath id="clip0_75_4396">
          <rect
            x="0.21875"
            y="0.445312"
            width="22"
            height="22"
            rx="4.27023"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AddFieldSvg = ({ handleAddFields }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleAddFields}
    >
      <rect
        x="0.469727"
        y="0.24408"
        width="35"
        height="35"
        rx="17.5"
        fill="#273142"
      />
      <path
        d="M12.0297 18.8481V16.5341H16.9437V11.9321H19.3357V16.5341H24.2497V18.8481H19.3357V23.4761H16.9437V18.8481H12.0297Z"
        fill="white"
      />
    </svg>
  );
};

export const AddFemaleFieldSvg = ({ handleFemale }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleFemale}
    >
      <rect
        x="0.469727"
        y="0.24408"
        width="35"
        height="35"
        rx="17.5"
        fill="#273142"
      />
      <path
        d="M12.0297 18.8481V16.5341H16.9437V11.9321H19.3357V16.5341H24.2497V18.8481H19.3357V23.4761H16.9437V18.8481H12.0297Z"
        fill="white"
      />
    </svg>
  );
};



export const MinusButtonSvg = ({ handleRemoveFields }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleRemoveFields}
    >
      <rect x="0.0820312"
        y="0.377197"
        width="35"
        height="35"
        rx="17.5"
        fill="#273142" />

      <path
        d="M11.564 18.9812V16.6672H22.146V18.9812H11.564Z"
        fill="white"
      />
    </svg>


  );
};
