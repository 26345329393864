import React from 'react';
import { TextField } from '@material-ui/core';

const Input = ({ label, value, style, onBlur, onChange, className, placeholder, type, name, disabled, maxLength, error }) => {
  return (
    <TextField
      label={label}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      style={style}
      className={`${className} ${error ? 'error' : ''}`}
      variant="outlined"
      margin="normal"
      type={type}
      name={name}
      onBlur={onBlur}
      disabled={disabled}
      inputProps={{ maxLength: maxLength }}
      error={error}
      helperText={error ? 'This field is required' : ''}
    />
  );
}

export default Input;
