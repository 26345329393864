import React, { useEffect, useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import {
  TableCell
} from "@material-ui/core";
import { useNavigate, useParams } from 'react-router-dom';
import Buttons from "../../Components/Shared/Buttons";
import Api from '../../helper/api';

const ViewUser = () => {
  const api = new Api();
  const params = useParams();
  const [data, setData] = useState(null);
  const tableclasses = tableStyles();
  const classes = formStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data, status } = await api.fetchUserByID({}, { id: params.id })

        if (status === 200) {
          setData(data.singleuser)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchUser();
  }, [])
  return (
    <div className={tableclasses.root} style={{ margin: "25px" }}>
      <div className={tableclasses.appointmentbody}>
        <div className={tableclasses.headertwo}>
          <div className={classes.formname}>
            <div className={classes.formh2}>{data?.name}</div>
            <div className={classes.viewHead}>
              <div className={`${classes.formspecification}`}
                style={{ marginLeft: '50px', marginRight: '50px' }}
              >
                User ID: {params?.id}
              </div>
              <div className={classes.formspecification} style={{ display: 'flex', alignItems: 'center' }}>
                Status:
                <TableCell className={tableclasses.customTableCell}>
                  {
                    data?.status === "active" || data?.status === "Active" ?
                      <Buttons className={tableclasses.customActive}>
                        <div style={{ color: 'green' }}>
                          Active
                        </div>
                      </Buttons>
                      :
                      <Buttons className={tableclasses.customInActive}>
                        <div style={{ color: 'red' }}>
                          InActive
                        </div>
                      </Buttons>

                  }
                </TableCell>
              </div>
            </div>
            <Buttons
              className={classes.backToButton}
              style={{ width: "120px" }}
              onClick={() => navigate("/register-user")}
            >
              Back to Table
            </Buttons>
          </div>
        </div>
        <div className={classes.biodata}>
          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Name</p>
              <p>Email</p>
              <p>Phone</p>
              <p>Role</p>
              <p>AuditLock Days</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
              <p>{data?.name ? data?.name : "NA"}</p>
              <p>{data?.email ? data?.email : "NA"}</p>
              <p>{data?.phone ? data?.phone : "NA"}</p>
              <p>{data?.role ? data?.role : "NA"}</p>
              <p>{data?.auditlockdays ? data?.auditlockdays : "NA"}</p>
            </div>
          </div>
          <div className={classes.imageside}></div>
        </div>
      </div>
    </div>
  )
}

export default ViewUser
