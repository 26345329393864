import React, { useEffect, useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, Button, InputLabel, List, ListItemText, Tab } from '@material-ui/core';
import Buttons from "../../Components/Shared/Buttons";
import { Checkbox, Select } from "@material-ui/core";
import Api from '../../helper/api';
import Selects, { components, MenuListProps } from 'react-select';
import { toast } from 'react-toastify'

const Investigation = ({ setPopupForm, reFetch, setReFetch, id }) => {
  const tableclasses = tableStyles();
  const classes = formStyles();
  const [priceList, setPriceList] = useState([]);
  const [investigation, setInvestigation] = useState([]);
  const [reportE1, setReportE1] = useState(false)

  const api = new Api();

  const ITEM_HEIGHT = 67;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    variant: "menu",
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "390px",
      },
    },
  };

  const handleInvestigation = (value) => {
    setInvestigation(value);
  }

  const removeInvestigation = (index) => {

    const clonedInvestigation = [...investigation]

    clonedInvestigation.splice(index, 1)
    setInvestigation(clonedInvestigation);
  };

  const fetchPriceList = async () => {
    try {
      const { data, status } = await api.fetchPriceList({}, {
        page: 1,
        quantity: 100000
      })

      if (status === 200) {
        setPriceList(data?.priceList);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchPriceList();
  }, [])

  const handleSubmit = async () => {
    try {
      const { data, status } = await api.addNewInvestigation(investigation, { id: id })

      if (status === 200) {
        toast.success("Investigation added successfully")
        setPopupForm(false)
        setReFetch(!reFetch)
      }
    } catch (err) {
      toast.error("Failed to add new Investigation")
    }
  }
  return (
    <div className={tableclasses.outBackground}>
      <div className={tableclasses.sampleCollection} style={{ marginTop: '48px', width: '55%' }}>
        <div
          className={tableclasses.exitbutton}
          style={{ cursor: "pointer" }}
          onClick={() => setPopupForm(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            style={{ position: 'relative', top: '23px', left: '92.5%' }}
          >
            <path
              d="M1 17L17 1M1 1L17 17"
              stroke="black"
              stroke-opacity="0.7"
              stroke-width="1.88067"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className={tableclasses.headSample}>Add New Invastigation</div>
        <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '1px #9c9c9c solid', marginBottom: '20px', paddingBottom: '25px' }}>
          <div className={classes.formLable}
            style={{ width: '700px' }}
          >Test Information</div>
          <Selects
            options={[{
              label: "Name", options: priceList?.map((price) => {
                return {
                  value: price?._id,
                  label: price?.priceListName,
                  priceListName: price?.priceListName,
                  _id: price?._id,
                  rate: price.rate,
                  shortName: price.shortName,
                }
              })
            }]}
            isMulti
            value={[...investigation?.map((price) => {
              return {
                value: price?._id,
                label: price?.priceListName,
                priceListName: price?.priceListName,
                _id: price?._id,
                rate: price?.rate,
                shortName: price?.shortName,
              }
            })]}
            components={{ Option, GroupHeading }}
            styles={{ container: (styles) => ({ ...styles, width: "90%", marginTop: '11px', marginBottom: "28px" }), control: (styles) => ({ ...styles, padding: "3px 0" }) }}
            onChange={(value) => handleInvestigation(value)}
          />
          <div style={{
            paddingLeft: '6px',
            backgroundColor: '#FAFAFA',
            borderBottom: '1px solid #c4c4c4',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            gap: '22%',
            width: "90%"
          }}>
            <List style={{ width: '90px' }} className={classes.fontStyle}>Test&nbsp;Name</List>
            <List style={{ width: '50px' }} className={classes.fontStyle}>Test&nbsp;ID</List>
            <List style={{ width: '60px' }} className={classes.fontStyle}>Rate/price</List>
          </div>

          {
            investigation.map((price, i) => (
              <div style={{
                width: '700px',
                display: 'flex',
                marginBottom: '10px',
                border: '1px solid rgba(201, 201, 201, 1)',
                borderRadius: '4px',
                backgroundColor: 'rgba(201, 201, 201, 0.15)',
              }}>
                <List style={{ width: '220px', paddingLeft: '10px' }}>{price.priceListName}</List>
                <List style={{ width: '180px' }}>{price.shortName}</List>
                <List style={{ width: '130px' }}>₹{price.rate}</List>
                <List style={{ width: '90px' }}>Plain</List>
                <List onClick={() => removeInvestigation(i)}>
                  <svg
                    width="23"
                    cursor={"pointer"}
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_75_4396)">
                      <path
                        d="M5.92578 17.3074L17.2098 6.02344M5.92578 6.02344L17.2098 17.3074"
                        stroke="#B82C3A"
                        stroke-width="1.88067"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <rect
                      x="0.645773"
                      y="0.872336"
                      width="21.146"
                      height="21.146"
                      rx="3.84321"
                      stroke="#B82C3A"
                      stroke-width="0.854047"
                    />
                    <defs>
                      <clipPath id="clip0_75_4396">
                        <rect
                          x="0.21875"
                          y="0.445312"
                          width="22"
                          height="22"
                          rx="4.27023"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </List>
              </div>
            ))
          }

        </div>
        <div className={tableclasses.sampleIn}>
          <Buttons
            className={classes.cancelButton}
            onClick={() => setPopupForm(false)}
          >
            Cancel
          </Buttons>
          <span className="px-1"></span>
          <Buttons
            onClick={handleSubmit}
            className={classes.submitButton}
          >
            Submit
          </Buttons>
        </div>

      </div>
    </div >
  )
}

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
          <p>{props.data.label}</p>
          <p>{props.data?._id}</p>
          <p>₹{props.data?.rate}</p>
        </div>
      </components.Option>
    </>
  )
}

const GroupHeading = (props) => {
  return (
    <components.GroupHeading {...props}>
      <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
        <p>Test Name</p>
        <p>Test Id</p>
        <p>Rate/Price</p>
      </div>
    </components.GroupHeading>
  )
}

export default Investigation
