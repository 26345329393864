import React, { useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import Input from "../../Components/Shared/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Buttons from "../../Components/Shared/Buttons";
import Select from "@material-ui/core/Select";
import Api from '../../helper/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify'


const DeletePriceList = ({ setDeleteForm, priceListToDelete, reFetch, setReFetch }) => {
    const tableclasses = tableStyles();
    const classes = formStyles();
    const api = new Api();
    const params = useParams();


    const handleSubmit = async () => {
        try {
            const { data, status } = await api.deletePriceList({
                doctor: params.id,
                priceList: priceListToDelete
            })

            if (status === 200) {
                toast.success("Deleted successfully")
                setDeleteForm(false)
                setReFetch(!reFetch)
            }
        } catch (err) {
            toast.error("Failed to Delete")
        }
    }
    return (
        <div className={tableclasses.outBackground}>
            <div className={tableclasses.sampleCollection} style={{ marginTop: '198px', width: '40%' }}>
                <div
                    className={tableclasses.exitbutton}
                    style={{ cursor: "pointer" }}
                    onClick={() => setDeleteForm(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        style={{ position: 'relative', top: '23px', left: '92.5%' }}
                    >
                        <path
                            d="M1 17L17 1M1 1L17 17"
                            stroke="black"
                            stroke-opacity="0.7"
                            stroke-width="1.88067"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div className={tableclasses.headSample}>Delete Confirmation</div>
                <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '0.5px #9c9c9c solid', marginBottom: '20px', paddingBottom: '30px' }}>
                    <div className={classes.formLable}
                    >Are you sure , you want to delete this?</div>
                </div>
                <div className={tableclasses.sampleIn}>
                    <Buttons
                        className={classes.cancelButton}
                        onClick={() => setDeleteForm(false)}
                    >
                        Cancel
                    </Buttons>
                    <span className="px-1"></span>
                    <Buttons
                        className={classes.submitButton}
                        onClick={handleSubmit}
                    >
                        Delete
                    </Buttons>
                </div>
            </div>

        </div>

    )
}

export default DeletePriceList;
