import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import {
  DoctorSvg,
  DropdownSvg,
  EnteredSvg,
  FilterSvg,
  PendingSvg,
  ResetFilterSvg,
  VerifiedSvg,
} from "../../Components/Shared/UserSvg";
import PopoverMenu from "../../Components/Shared/Popover";
import AssignCollector from "../../Components/Shared/CollectorAssign";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import FindPatient from "../../Components/modal/FindPatient";
import FilterCategory from "../MasterData/FilterCategory";

const Patient = () => {
  const tableclasses = tableStyles();

  const TOKEN = localStorage.getItem("logintoken");
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [newData, setNewData] = useState(false);
  const [name, SetName] = useState("");
  const [assign, setAssign] = useState(false);
  const [id, setID] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState();
  const [sortByDateAsc, setSortByDateAsc] = useState(true);
  const [popup, setPopup] = useState(false);

  const [filter, setFilter] = useState({
    date: null,
    status: null,
    category: null,
  });
  const [filterData, setFilterData] = useState({
    status: "",
    date: [new Date(), new Date()],
    category: [],
  });

  const [search, setSearch] = useState("")

  const reportContent = useRef()

  const generatePDF = useReactToPrint({
    content: () => reportContent.current,
    documentTitle: "PatientReport",
  })

  const handleReset = () => {
    setFilterData({
      status: "",
      date: "",
      category: [],
    })
  }

  const fetchData = async () => {
    try {
      const { date, status, category } = filterData
      const response = await axios.get(`${API}/getpatiencelist/${page}/10?date=${JSON.stringify(date)}&status=${status}&category=${JSON.stringify(category)}&search=${search}`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setRows(response.data.patients);
      setPageInfo(response.data);
    } catch (error) {
      console.error("Fetching Data Error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [newData, page, rowsPerPage, fetch, filterData.date, filterData.status, filterData.category, search]);

  const handleAssign = (data) => {
    setAssign(true);
  };

  const handleView = async (phone) => {
    navigate(`/appointment-view/${phone}`);
  };

  const viewReport = async (id) => {
    navigate(`/patient-report/${id}`);
  };

  const handleEdit = async (id) => {
    navigate(`/edit-patience/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${API}/delete-patience/${id}`, {
        headers: { authtoken: `${TOKEN}` },
      });
      if (response.data) {
        setNewData(true);
        toast.success("Patience deleted successfully");
        setNewData(false);
      }
    } catch (error) {
      console.error("Fetching Data Error", error);
    }
  };

  const filteredData = rows?.filter((item) =>
    item?.name?.toLowerCase()?.includes(name?.toLowerCase())
  );

  const formatedDate = (newDate) => {
    const date = new Date(newDate);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const formatTime = (newTime) => {
    const time = new Date(newTime);
    return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const header = [
    "SL No",
    "Patient Name",
    "Sample No",
    "Date & Time",
    "Lab No",
    "Reffered by",
    "Sample From",
    "Price",
    "Status",
    "Action",
  ];

  const setNextPage = () => {
    if (page > 0) {
      if (page === pageInfo?.totalPages) return;
      setPage(page + 1);
    }
  };

  const setPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const status = [
    "Pending", "Entered", "Completed"
  ]

  return (
    <>
      <FilterCategory
        status={status}
        setFilter={setFilter}
        filter={filter}
        filterData={filterData}
        setFilterData={setFilterData}
      />
      <div className={tableclasses.root}>
        {popup ? <FindPatient setPopup={setPopup} /> : ""}
        <div className={tableclasses.body}>
          <div className={tableclasses.header}>
            <div className={tableclasses.name}>
              <div className={tableclasses.h2}>Patients</div>
              <div className={tableclasses.specification}>
                {rows?.length} pending patient reports
              </div>
            </div>
            <div>
              <Buttons
                className={tableclasses.addButton}
                onClick={() => navigate("/add-patience")}
              >
                <DoctorSvg /> &nbsp; Add new Patient
              </Buttons>
            </div>
          </div>
          <div className={tableclasses.header} style={{ marginTop: "-25px" }}>
            <div
              style={{ display: "flex", gridGap: "19px", alignItems: "center" }}
            >
              <div className={tableclasses.findPatience}>
                <TextField
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Enter Patient ID/Phone no/Patient name"
                  className={tableclasses.searchField}
                />
              </div>
              <Button
                variant="contained"
                className={tableclasses.findButton}
                onClick={() => setPopup(!popup)}
              >
                Find
              </Button>
            </div>
          </div>

          <div className={tableclasses.filterSearch}>
            <div>
              <Buttons className={tableclasses.filterButton1}>
                <FilterSvg />
              </Buttons>
              <Buttons className={tableclasses.filterButton2}>
                Filter By
              </Buttons>

              <Buttons
                onClick={(e) =>
                  setFilter((prev) => {
                    return { ...prev, date: e.currentTarget };
                  })
                }
                className={tableclasses.filterButton2}
              >
                Date
                <DropdownSvg />
              </Buttons>

              <Buttons
                className={tableclasses.filterButton2}
                onClick={(e) =>
                  setFilter((prev) => {
                    return { ...prev, category: e.currentTarget };
                  })
                }
              >
                Category
                <DropdownSvg />{" "}
              </Buttons>
              <Buttons
                className={tableclasses.filterButton2}
                onClick={(e) =>
                  setFilter((prev) => {
                    return { ...prev, status: e.currentTarget };
                  })
                }
              >
                Test Status
                <DropdownSvg />
              </Buttons>
              <Buttons
                onClick={handleReset}
                className={tableclasses.filterButton3}>
                <ResetFilterSvg />
                <span
                  style={{ color: "#FF8743", marginLeft: 6, text: "center" }}
                >
                  Reset FIlter
                </span>{" "}
              </Buttons>
              <Buttons
                className={
                  tableclasses.printButton
                }
                onClick={generatePDF}
              >
                Print
              </Buttons>
            </div>

            {/* <div className={tableclasses.searchContainer}>
              <FiSearch className={tableclasses.searchIcon} />
              <TextField
                className={tableclasses.searchField}
                placeholder="Search"
                variant="standard"
                size="small"
                value={name}
                onChange={(e) => SetName(e.target.value)}
              />
            </div> */}
          </div>
          <>
            <Table className={tableclasses.table} ref={reportContent}>
              <TableHead className={tableclasses.tableHead}>
                <TableRow>
                  {header.map((header) => {
                    return (
                      <TableCell className={tableclasses.customHeadName}>
                        {header}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  rows.length !== 0
                    ? rows?.map((row, index) => {
                      let status = "pending";

                      let pending = 0;
                      let verified = 0;
                      let entered = 0;
                      if (row.investigation?.length > 0) {
                        for (let i = 0; i < row?.investigation?.length; i++) {
                          if (row.investigation[i]?.status.toUpperCase() === "PENDING") {
                            pending++
                          } else if (row.investigation[i]?.status.toUpperCase() === "VERIFIED") {
                            verified++
                          } else if (row.investigation[i]?.status.toUpperCase() === "ENTERED") {
                            entered++
                          }
                        }

                        if (row.investigation?.length === verified) {
                          status = "Completed"
                        } else if (entered > 0 || verified > 0) {
                          status = "Entered"
                        } else {
                          status = "Pending"
                        }
                      }

                      return (
                        <TableRow key={row.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            className={tableclasses.customTableCell}
                            style={{ display: "flex" }}
                          >
                            <div className={tableclasses.name}>
                              <div>{(page - 1) * 10 + index + 1}</div>
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={tableclasses.customTableCell}
                          >
                            <div className={tableclasses.name}>
                              <div>
                                {row.firstName} {row.lastName}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={tableclasses.customTableCell}
                          >
                            <div className={tableclasses.name}>
                              <div>
                                {row.sampleCollection[0]?.sampleNo}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={tableclasses.customTableCell}>
                            <div>{formatedDate(row.createdAt)}</div>
                            <div className={tableclasses.specification}>
                              {formatTime(row.createdAt)}
                            </div>
                          </TableCell>
                          <TableCell className={tableclasses.customTableCell}>
                            <div>{row.labNumber || "NA"}</div>
                          </TableCell>
                          <TableCell className={tableclasses.customTableCell}>
                            <div>{row?.refferedBy?.name || "Doctor"}</div>
                          </TableCell>
                          <TableCell className={tableclasses.customTableCell}>
                            <div>{row.sampleFrom}</div>
                          </TableCell>
                          <TableCell className={tableclasses.customTableCell}>
                            <div>{row?.payment[0]?.totalAmount}</div>
                          </TableCell>
                          <TableCell className={tableclasses.customTableCell}>
                            <div
                              style={{
                                text: "center",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                {
                                  status === "Pending"
                                    ? <PendingSvg />
                                    : status === "Entered" ? <EnteredSvg />
                                      : <VerifiedSvg />
                                }
                                <span
                                  style={{
                                    color: status === "Pending"
                                      ? "#B82C3A"
                                      : status === "Entered" ? "#D48A48"
                                        : "#60BB47",
                                    marginLeft: 6,
                                    text: "center",
                                  }}
                                >
                                  {status}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={tableclasses.customTableCell}>
                            <div>
                              <PopoverMenu
                                data={rows}
                                handleEdit={() => handleEdit(row._id)}
                                handleDelete={() => handleDelete(row._id)}
                                handleView={() => handleView(row._id)}
                                viewReport={() => viewReport(row._id)}
                                handleAssign={() => {
                                  handleAssign(row._id);
                                  setID(row._id);
                                }}
                              />
                            </div>
                          </TableCell>
                          {assign && id === row._id && (
                            <AssignCollector
                              assign={assign}
                              setAssign={setAssign}
                              patienceId={id}
                            />
                          )}
                        </TableRow>
                      )
                    })
                    : <h1 style={{textAlign: "center", fontSize: "16px", color: "#000", fontWeight: 500}}>No Data to show</h1>
                }
              </TableBody>
            </Table>
          </>
          <div className={tableclasses.pagination}>
            <div className={tableclasses.name}>
              Showing {rows?.length} of {pageInfo?.totalItems} entries
            </div>
            <div>
              <Buttons
                onClick={setPrevPage}
                className={tableclasses.pageButton}
              >
                Previous
              </Buttons>
              <Buttons className={tableclasses.numButton}>
                {pageInfo?.currentPage}
              </Buttons>
              <Buttons
                onClick={setNextPage}
                className={tableclasses.pageButton}
              >
                Next
              </Buttons>
            </div>
            {/* <div></div> */}
          </div>
        </div>
      </div >
    </>
  );
};

export default Patient;
