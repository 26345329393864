import React from 'react'

const RenderRange = ({ patient, range, unit }) => {
    let age = patient?.age;

    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)

    let ran = {};

    for (let i = 0; i < rangeClone.length; i++) {
        let curr = rangeClone[i].ageUpto
        if (Object.keys(ran).length === 0) {
            if (curr >= age) {
                ran = rangeClone[i];
            }
        }
    }
    return (
        <p>
            {ran?.low}-{ran?.high} {unit}
        </p>
    )
}

export default RenderRange