import React, { useState, useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon, ListItemText, Popover } from "@material-ui/core";
import Api from "../../helper/api";
import { tableStyles } from "../../Styles/AddNewDocStyle";
import DateRangePicker from 'rsuite/DateRangePicker';

const FilterCategory = ({ filter, setFilter, filterData, setFilterData, status }) => {
  const api = new Api();
  const tableclasses = tableStyles()
  const [category, setCategory] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [city, setCity] = useState([])

  // get report group
  const fetchCategory = async (e) => {
    try {
      const data = await api.fetchReportGroup();
      setCategory(data.data.testCategory);
    } catch (e) {
      console.log(e);
    }
  };

  // get accounts
  const fetchAccount = async (e) => {
    try {
      const { data, status } = await api.fetchAccount({}, { page: 1, quantity: 200 });

      if (status === 200) {
        setAccounts(data.accountDetails);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // fetch city list
  const fetchCity = async (e) => {
    try {
      const { data, status } = await api.fetchCity();

      if (status === 200) {
        setCity(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchAccount();
    fetchCity();
  }, []);

  const handleClose = (name) => {
    setFilter((prev) => {
      return { ...prev, [name]: null };
    });
  };

  const handleCategory = (id) => {
    const exist = filterData.category.findIndex((ele) => ele === id);

    // if id found
    if (exist > -1) {
      // remove the id from array
      const clonedData = [...filterData.category];
      clonedData.splice(exist, 1);

      setFilterData((prev) => {
        return { ...prev, category: clonedData };
      });
    }
    // if id not found
    else {
      // add id in array
      setFilterData((prev) => {
        return { ...prev, category: [...prev.category, id] };
      });
    }
  };
  const handleAlpha = (id) => {
    const items = filterData.alphabets.findIndex((ele) => ele === id);
    if (items > -1) {
      const inData = [...filterData.alphabets];
      inData.splice(items, 1);
      setFilterData((prev) => {
        return { ...prev, alphabets: inData };
      });
    } else {
      setFilterData((prev) => {
        return { ...prev, alphabets: [...prev.alphabets, id] };
      });
    }
  };
  const handleLocation = (id) => {
    const city = filterData.location.findIndex((ele) => ele === id);
    if (city > -1) {
      const locations = [...filterData.location];
      locations.splice(city, 1);
      setFilterData((prev) => {
        return { ...prev, location: locations };
      });
    } else {
      setFilterData((prev) => {
        return { ...prev, location: [...prev.location, id] };
      });
    }
  };
  const alphabets = [
    {
      name: "A",
    },
    {
      name: "B",
    },
    {
      name: "C",
    },
    {
      name: "D",
    },
    {
      name: "E",
    },
    {
      name: "F",
    },
    {
      name: "G",
    },
    {
      name: "H",
    },
    {
      name: "I",
    },
    {
      name: "J",
    },
    {
      name: "K",
    },
    {
      name: "L",
    },
    {
      name: "M",
    },
    {
      name: "N",
    },
    {
      name: "O",
    },
    {
      name: "P",
    },
    {
      name: "Q",
    },
    {
      name: "R",
    },
    {
      name: "S",
    },
    {
      name: "T",
    },
    {
      name: "U",
    },
    {
      name: "V",
    },
    {
      name: "W",
    },
    {
      name: "X",
    },
    {
      name: "Y",
    },
    {
      name: "Z",
    },
  ];

  const handleStatus = (id) => {
    setFilterData((prev) => {
      return { ...prev, status: id };
    });
  };

  const handleDate = (date) => {
    console.log("date", date)
    setFilterData((prev) => { return { ...prev, date } })
    handleClose("date")
  }
  return (
    <div>
      {filter?.category ? (
        <Popover
          open={Boolean(filter?.category)}
          anchorEl={filter?.category}
          onClose={() => handleClose("category")}
          PaperProps={{
            style: {
              width: "220px",
              maxHeight: "350px",
              borderRadius: "10px",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {category.map((cat) => (
            <MenuItem
              key={cat._id}
              onClick={() => handleCategory(cat._id)}
              style={{
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "poppins",
                fontSize: "14px",
                color: "#2F2F2F",
                height: "48px",
                borderBottom: "0.5px #CCD1D2 solid",
                backgroundColor: filterData.category.find(
                  (ele) => ele === cat._id
                )
                  ? "#EFEEF1"
                  : "white",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  visibility: filterData.category.find((ele) => ele === cat._id)
                    ? "visible"
                    : "hidden",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7L19.5902 5.59L9.00016 16.17Z"
                    fill="#273142"
                  />
                </svg>
              </div>
              {cat.name}
            </MenuItem>
          ))}
        </Popover>
      ) : filter?.alphabets ? (
        <Popover
          open={Boolean(filter?.alphabets)}
          anchorEl={filter?.alphabets}
          onClose={() => handleClose("alphabets")}
          PaperProps={{
            style: {
              width: "141px",
              maxHeight: "350px",
              borderRadius: "10px",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {alphabets.map((alpha) => (
            <MenuItem
              key={alpha.name}
              onClick={() => handleAlpha(alpha.name)}
              style={{
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "poppins",
                fontSize: "14px",
                color: "#2F2F2F",
                height: "48px",
                borderBottom: "0.5px #CCD1D2 solid",
                backgroundColor: filterData.alphabets.find(
                  (ele) => ele === alpha.name
                )
                  ? "#EFEEF1"
                  : "white",
              }}
            >
              <div
                style={{
                  marginRight: "12px",
                  visibility: filterData.alphabets.find(
                    (ele) => ele === alpha.name
                  )
                    ? "visible"
                    : "hidden",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7L19.5902 5.59L9.00016 16.17Z"
                    fill="#273142"
                  />
                </svg>
              </div>
              {alpha.name}
            </MenuItem>
          ))}
        </Popover>
      ) : filter?.accounts ? (
        <Popover
          open={Boolean(filter?.accounts)}
          anchorEl={filter?.accounts}
          onClose={() => handleClose("accounts")}
          PaperProps={{
            style: {
              width: "180px",
              borderRadius: "10px",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {accounts?.map((acc) => (
            <MenuItem
              key={acc._id}
              onClick={() => setFilterData((prev) => { return { ...prev, accounts: acc._id } })}
              style={{
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "poppins",
                fontSize: "14px",
                color: "#2F2F2F",
                height: "48px",
                borderBottom: "0.5px #CCD1D2 solid",
                backgroundColor: filterData.accounts === acc.id ? "#EFEEF1" : "white",
              }}
            >
              <div
                style={{
                  marginRight: "12px",
                }}
              >
                {filterData.accounts === acc._id ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 7.00003C9.2 7.00003 7 9.20003 7 12C7 14.8 9.2 17 12 17C14.8 17 17 14.8 17 12C17 9.20003 14.8 7.00003 12 7.00003ZM12 2.00003C6.5 2.00003 2 6.50003 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.50003 17.5 2.00003 12 2.00003ZM12 20C7.6 20 4 16.4 4 12C4 7.60003 7.6 4.00003 12 4.00003C16.4 4.00003 20 7.60003 20 12C20 16.4 16.4 20 12 20Z"
                      fill="#273142"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_3923_1872)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.3047 2C6.80469 2 2.30469 6.5 2.30469 12C2.30469 17.5 6.80469 22 12.3047 22C17.8047 22 22.3047 17.5 22.3047 12C22.3047 6.5 17.8047 2 12.3047 2ZM12.3047 20C7.90469 20 4.30469 16.4 4.30469 12C4.30469 7.6 7.90469 4 12.3047 4C16.7047 4 20.3047 7.6 20.3047 12C20.3047 16.4 16.7047 20 12.3047 20Z"
                        fill="#273142"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3923_1872">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
              {acc?.prefix}
            </MenuItem>
          ))}
        </Popover>
      ) : filter?.sortBy ? (
        <Popover
          open={Boolean(filter?.sortBy)}
          anchorEl={filter?.sortBy}
          onClose={() => handleClose("sortBy")}
          PaperProps={{
            style: {
              width: "180px",
              borderRadius: "10px",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() =>
              setFilterData((prev) => {
                return { ...prev, sortBy: "ascending" };
              })
            }
            style={{
              fontWeight: "400",
              lineHeight: "24px",
              fontFamily: "poppins",
              fontSize: "14px",
              color: "#2F2F2F",
              height: "48px",
              borderBottom: "0.5px #CCD1D2 solid",
              backgroundColor:
                filterData.sortBy === "ascending" ? "#EFEEF1" : "white",
            }}
          >
            <div
              style={{
                marginRight: "12px",
                visibility:
                  filterData.sortBy === "ascending" ? "visible" : "hidden",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7L19.5902 5.59L9.00016 16.17Z"
                  fill="#273142"
                />
              </svg>
            </div>
            Ascending
          </MenuItem>
          <MenuItem
            onClick={() =>
              setFilterData((prev) => {
                return { ...prev, sortBy: "descending" };
              })
            }
            style={{
              fontWeight: "400",
              lineHeight: "24px",
              fontFamily: "poppins",
              fontSize: "14px",
              color: "#2F2F2F",
              height: "48px",
              borderBottom: "0.5px #CCD1D2 solid",
              backgroundColor:
                filterData.sortBy === "descending" ? "#EFEEF1" : "white",
            }}
          >
            <div
              style={{
                marginRight: "12px",
                visibility:
                  filterData.sortBy === "descending" ? "visible" : "hidden",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7L19.5902 5.59L9.00016 16.17Z"
                  fill="#273142"
                />
              </svg>
            </div>
            Descending
          </MenuItem>
        </Popover>
      ) : filter?.status ? (
        <Popover
          open={Boolean(filter?.status)}
          anchorEl={filter?.status}
          onClose={() => handleClose("status")}
          PaperProps={{
            style: {
              width: "220px",
              maxHeight: "350px",
              borderRadius: "10px",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {status.map((st) => (
            <MenuItem
              key={st}
              onClick={() => handleStatus(st)}
              style={{
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "poppins",
                fontSize: "14px",
                color: "#2F2F2F",
                height: "48px",
                borderBottom: "0.5px #CCD1D2 solid",
                backgroundColor:
                  filterData.status === st ? "#EFEEF1" : "white",
              }}
            >
              <div
                style={{
                  marginRight: "12px",
                  visibility:
                    filterData.status === st ? "visible" : "hidden",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7L19.5902 5.59L9.00016 16.17Z"
                    fill="#273142"
                  />
                </svg>
              </div>
              {st}
            </MenuItem>
          ))}
        </Popover>
      ) : filter?.location ? (
        <Popover
          open={Boolean(filter?.location)}
          anchorEl={filter?.location}
          onClose={() => handleClose("location")}
          PaperProps={{
            style: {
              width: "220px",
              maxHeight: "350px",
              borderRadius: "10px",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {city?.map((place) => (
            <MenuItem
              key={place._id}
              onClick={() => handleLocation(place._id)}
              style={{
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "poppins",
                fontSize: "14px",
                color: "#2F2F2F",
                height: "48px",
                borderBottom: "0.5px #CCD1D2 solid",
                backgroundColor: filterData.location.find(
                  (ele) => ele === place._id
                )
                  ? "#EFEEF1"
                  : "white",
              }}
            >
              <div
                style={{
                  marginRight: "12px",
                  visibility: filterData.location.find(
                    (ele) => ele === place._id
                  )
                    ? "visible"
                    : "hidden",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 7L19.5902 5.59L9.00016 16.17Z"
                    fill="#273142"
                  />
                </svg>
              </div>
              {place._id}
            </MenuItem>
          ))}
        </Popover>
      ) : filter?.date ? (
        <Popover
          open={Boolean(filter?.date)}
          anchorEl={filter?.date}
          onClose={() => handleClose("date")}
          PaperProps={{
            style: {
              // width: "100px",
              maxHeight: "350px",
              borderRadius: "10px",
              position: "relative",
              visibility: "hidden"
              // height: '1px'
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <DateRangePicker
            open={Boolean(filter?.date)}
            onChange={handleDate}
            appearance="default"
            value={filterData.date}
            placeholder="Default"
            style={{ width: 230, zIndex: 1000 }}
          />
        </Popover>
      ) : (
        ""
      )}
    </div>
  );
};

export default FilterCategory;
