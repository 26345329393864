import { makeStyles   } from '@material-ui/core/styles';

export const macroStyles = makeStyles({

    name: {
        fontFamily: "Poppins",
        fontWeight: 500,
        color: "#212121",
        fontSize: "18px",
        paddingLeft: '30px',
    },
  
});